import { gql } from '@apollo/client';

export default class CatalogQuery {
    static GET_ENUM_VALUES = gql`
    query getEnumCatalogValues($enumDescription: String, $lotName: String, $sortDirection: String) {
        getEnumCatalogValues(enumDescription: $enumDescription, lotName: $lotName, sortDirection: $sortDirection){
            enumValuesId
            description
            lotName
        }
    }
    `;

    static ENUM_VALUES = gql`
        query getEnumValues($descriptions: [String!]!, $onlyActive: Boolean) {
            getEnumValues(descriptions: $descriptions, onlyActive: $onlyActive){
                enumValuesId
                enumId
                description
                lotName
                codeValue
                type
            }
        }
    `;

    static SAVE_ENUM = gql`
    mutation saveEnumCatalog($enumValues: [enumValues]) {
        saveEnumCatalog(enumValues: $enumValues)
    }
    `;

    static DELETE_ENUM = gql`
        mutation deleteEnumCatalog($enumValuesId: [Int!]) {
            deleteEnumCatalog(enumValuesId: $enumValuesId)
        }
    `;
}
