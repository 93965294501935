import React, {
    useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button, Dialog, DialogContent, Slide, AppBar, Toolbar, DialogActions, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from 'components/widgets/Table';
import Container from 'components/widgets/Container';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputControl from 'components/widgets/editorControls/InputControl';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { SaveIcon } from 'components/icons';
import { DataSort } from 'utils/enum/Core';
import useCatalogListActions from 'components/widgets/catalogs/useCatalogListActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const initialState = {
    isModalDeleteOpen: false,
    idToDelete: 0,
    couldLostData: false,
};

const CatalogList = (props) => {
    const {
        catalogEnum, sortDirection, lotName,
        openDialog, onClose, catalogTitle,
    } = props;
    const classes = useStyles();

    const [currentState, setCurrentState] = useState(initialState);

    const {
        onSave,
        isSaving,
        isDeleting,
        loadData,
        loading,
        list,
        isDirty,
        onAddNewLine,
        onDeleteConfirm,
        onCloseDeleteConfirm,
        handleEditorKeyDown,
        handleEditorChange,
        setIdToDelete,
        idToDelete,
        resultSave,
    } = useCatalogListActions({
        catalogEnum,
    });

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            setCurrentState({
                ...currentState,
                couldLostData: true,
            });
            return;
        }

        onClose();
    };

    const onCloseDirtyChanges = () => {
        setCurrentState({
            ...currentState,
            couldLostData: false,
        });
    };

    useEffect(() => {
        loadData({
            variables: {
                enumDescription: catalogEnum,
                sortDirection,
                lotName,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultSave) onPopupClose(null, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSave]);

    const columns = [
        {
            Header: 'Line',
            minWidth: 50,
            width: 50,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            accessor: 'enumValuesId',
        },
        {
            Header: 'Description',
            minWidth: 400,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            accessor: 'description',
            Cell: (cell) => {
                const { column: { id }, original: { description } } = cell;
                return (
                    <InputControl
                        name={id}
                        value={description}
                        editorCellObject={cell}
                        rows={1}
                        onChange={handleEditorChange}
                        onKeyDown={handleEditorKeyDown}
                    />
                );
            },
        },
        {
            Header: 'Actions',
            minWidth: 60,
            headerClassName: classes.columnHeaderStyle,
            width: 70,
            Cell: (cell) => (
                <IconButton onClick={() => { setIdToDelete(cell.original.enumValuesId); }}>
                    <DeleteIcon className={classes.buttonDelete} />
                </IconButton>
            ),
        }];

    return (
        <Dialog
            open={openDialog}
            onClose={onPopupClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        {catalogTitle}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPaddingSides}>
                <Container className={classes.containerSplit}>
                    <Table
                        className={clsx('-highlight', classes.table)}
                        data={list}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={list?.length}
                        rowSelected
                    />
                    <ConfirmDialog
                        title="Confirm remove line"
                        description={isDeleting ? 'Deleting Record...' : 'Are you sure you want to remove this line?'}
                        open={idToDelete !== 0}
                        variant="outlined"
                        titlePrimary="Yes"
                        titleSecondary="Cancel"
                        onClose={(onCloseDeleteConfirm)}
                        onClickSecondary={onCloseDeleteConfirm}
                        onClickPrimary={onDeleteConfirm}
                        disablePrimaryButton={isDeleting}
                        disableSecondaryButton={isDeleting}
                    />
                    <ConfirmDialog
                        title="Attention - You have unsaved changes!"
                        description="Do you want to close without isSaving?"
                        open={currentState.couldLostData}
                        variant="outlined"
                        titlePrimary="Close"
                        titleSecondary="Cancel"
                        onClose={onCloseDirtyChanges}
                        onClickSecondary={() => onCloseDirtyChanges()}
                        onClickPrimary={(e) => onPopupClose(e, true)}
                    />
                </Container>
            </DialogContent>
            <DialogActions>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                            size="small"
                            className={classes.buttonNew}
                            onClick={onAddNewLine}
                            disabled={loading}
                        >
                            New Item
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            size="small"
                            className={classes.buttonSave}
                            disabled={loading || isSaving || !isDirty}
                            onClick={onSave}
                        >
                            Save
                        </Button>
                    </div>
                </Toolbar>
            </DialogActions>
        </Dialog>
    );
};

CatalogList.propTypes = {
    catalogEnum: PropTypes.string.isRequired,
    sortDirection: PropTypes.string,
    lotName: PropTypes.string,
    openDialog: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    catalogTitle: PropTypes.string.isRequired,
};

CatalogList.defaultProps = {
    sortDirection: DataSort.ASC,
    lotName: null,
};

export default CatalogList;
