/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import DateUtils from 'lib/DateUtils';
import ServiceJobsItem from 'components/modules/service/serviceInvoice/list/ServiceJobsItem';
import ArrayUtils from 'lib/ArrayUtils';
import {
    ExpandMoreIcon, BorderColorOutlined,
    ReceiptOutlinedIcon, BlockOutlinedIcon, SpeakerNotesOutlinedIcon, HistoryOutlinedIcon, SyncAltOutlinedIcon,
} from 'components/icons';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';
import {
    Accordion, AccordionSummary, AccordionDetails,
    Button, makeStyles, Tooltip,
} from '@material-ui/core';
import { ServiceActions, ServiceInvoiceStatus, ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import BadgeStyle from 'styles/theme/Badge';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import CommonJournalPopup from 'components/modules/accounting/journal/list/CommonJournalPopup';
import useSharedMethods from 'components/modules/service/serviceInvoice/hooks/useSharedMethods';
import { v1 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import { JournalTypes } from 'utils/enum/AccountingEnum';

const useBadgeStyles = makeStyles((theme) => BadgeStyle.getStyle(theme));

const keyStore = new KeyStore();

const ServiceInvoiceItem = ({
    invoiceItem, classes, minimumGPList, onPrint,
    open, onChangeStatus, onChangeJobStatus,
    expandOrCollapse, onHistory, isHistory, onNotes,
    identifier, setCopyText, copyText,
}) => {
    const [postingJournal, setPostingJournal] = useState(false);
    const history = useHistory();
    const badgeClasses = useBadgeStyles();
    const { getBadgeStatus, getServiceClassStatus } = useSharedMethods(badgeClasses);

    const SERVICE_EDIT = keyStore.hasPermission(Permission.SERVICE_EDIT);
    const SERVICE_VOID = keyStore.hasPermission(Permission.SERVICE_VOID);
    const SERVICE_TRANSFER = keyStore.hasPermission(Permission.SERVICE_TRANSFER);

    const {
        invoiceNumber, customer,
        status, advisor, roClosed, roOpen,
        vehicleStock, vehicleYear, vehicleMake,
        vehicleModel, vehicleTrim, tax, fees,
        totalParts, jobs, warranty, deposit,
        totalLabor, discount, shopSupplies, total,
        inHouse, lotName, postedDate, hatNumber,
        dealNumber, totalSubletPrice, totalSubletCost,
    } = invoiceItem;

    const {
        rowItem, innerItem, actionButton,
        customColumns, innerTable,
        largeCell, mainLabel, mediumCell,
        ageCell, jobTableCls, onlyDesktop, minCell,
        inventoryLink,
    } = classes;

    const onOpenJournalModal = () => setPostingJournal(true);
    const onCloseJournalModal = () => setPostingJournal(false);

    const onEdit = () => {
        history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/${inHouse ? 'internal' : 'customer'}/edit/${invoiceNumber}`);
    };

    const statusFlow = {
        [ServiceInvoiceStatus.WRITE_UP]: [
            {
                label: ServiceInvoiceStatus.APPROVAL_REQUEST,
                status: ServiceInvoiceStatus.APPROVAL_REQUEST,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.APPROVAL_REQUEST)} />,
                access: SERVICE_TRANSFER,
            },
            {
                label: ServiceInvoiceStatus.ESTIMATE,
                status: ServiceInvoiceStatus.ESTIMATE,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.ESTIMATE)} />,
                access: SERVICE_TRANSFER,
            },
            {
                label: ServiceInvoiceStatus.VOID,
                status: ServiceInvoiceStatus.VOID,
                icon: <BlockOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.VOID)} />,
                access: SERVICE_VOID,
            },
        ],
        [ServiceInvoiceStatus.ESTIMATE]: [
            {
                label: ServiceInvoiceStatus.APPROVAL_REQUEST,
                status: ServiceInvoiceStatus.APPROVAL_REQUEST,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.APPROVAL_REQUEST)} />,
                access: SERVICE_TRANSFER,
            },
            {
                label: ServiceInvoiceStatus.VOID,
                status: ServiceInvoiceStatus.VOID,
                icon: <BlockOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.VOID)} />,
                access: SERVICE_VOID,
            },
        ],
        [ServiceInvoiceStatus.APPROVAL_REQUEST]: [
            {
                label: 'In Progress',
                status: ServiceInvoiceStatus.IN_PROGRESS,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.IN_PROGRESS)} />,
                access: SERVICE_TRANSFER,
            },
            {
                label: ServiceInvoiceStatus.VOID,
                status: ServiceInvoiceStatus.VOID,
                icon: <BlockOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.VOID)} />,
                access: SERVICE_VOID,
            },
        ],
        [ServiceInvoiceStatus.IN_PROGRESS]: [
            {
                label: 'Close',
                status: ServiceInvoiceStatus.CLOSED,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.CLOSED)} />,
                access: SERVICE_TRANSFER,
            },
        ],
        [ServiceInvoiceStatus.CLOSED]: [
            {
                label: 'In Progress',
                status: ServiceInvoiceStatus.IN_PROGRESS,
                icon: <SyncAltOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.IN_PROGRESS)} />,
                access: SERVICE_TRANSFER,
            },
        ],
    };

    const renderButtonNextStatus = () => {
        const totalInvoice = totalParts + totalLabor + total + totalSubletPrice + totalSubletCost + warranty;

        let currentFlow = statusFlow[status]?.filter((c) => c.access);
        const haveVoidButton = currentFlow?.find((f) => f.label === ServiceInvoiceStatus.VOID);
        const haveCloseButton = currentFlow?.find((f) => f.label === 'Close');
        if (totalInvoice === 0 && !haveVoidButton && currentFlow) {
            currentFlow.push({
                label: ServiceInvoiceStatus.VOID,
                status: ServiceInvoiceStatus.VOID,
                icon: <BlockOutlinedIcon className={getServiceClassStatus(ServiceInvoiceStatus.VOID)} />,
                access: SERVICE_VOID,
            });
        }

        if (totalInvoice === 0 && haveCloseButton) {
            currentFlow = currentFlow?.filter((f) => f.label !== 'Close');
        }

        return currentFlow?.map((item) => (
            <Button
                key={uuid()}
                size="small"
                variant="outlined"
                startIcon={item.icon}
                className={actionButton}
                onClick={() => onChangeStatus(invoiceNumber, item.status)}
            >
                {item.label}
            </Button>
        ));
    };

    const minmumGPValue = minimumGPList
        ?.find((c) => c.lotName.toLowerCase() === lotName?.toLowerCase())
        ?.minimumGP || 0;

    const handleCopy = (text) => {
        setCopyText(`RO: ${text} copied`);
        return navigator?.clipboard?.writeText(text);
    };

    return (
        <Accordion
            expanded={open || false}
            className={rowItem}
            onChange={(_, val) => expandOrCollapse({ invoiceNumber, open: val })}
            onDoubleClick={() => (isHistory ? null : onEdit())}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                elevation={3}
            >
                <div
                    className={innerItem}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                >
                    <div className={customColumns}>
                        <table
                            className={innerTable}
                        >
                            <tbody>
                                <tr>
                                    <td data-label="Invoice" className={clsx(mainLabel, mediumCell)} onClick={() => handleCopy(invoiceNumber)}>
                                        {inHouse ? 'I - ' : 'C - '}
                                        {' '}
                                        <Tooltip
                                            title={copyText}
                                            placement="top-start"
                                            onMouseOut={() => setCopyText('Click to copy')}
                                        >
                                            <span>{invoiceNumber}</span>
                                        </Tooltip>
                                    </td>
                                    <td className={onlyDesktop}>Customer:</td>
                                    <td data-label="Customer" className={largeCell}>{`${customer?.firstName} ${customer?.lastName}`}</td>
                                    <td className={clsx(onlyDesktop, minCell)}>Jobs:</td>
                                    <td className={minCell} data-label="Jobs">{jobs?.length || 0}</td>
                                    <td className={onlyDesktop}>Fees:</td>
                                    <td data-label="Fees">{NumberUtils.applyCurrencyFormat(fees || 0)}</td>
                                    <td className={onlyDesktop}>Tax:</td>
                                    <td data-label="Tax">{NumberUtils.applyCurrencyFormat(tax || 0)}</td>
                                    <td className={onlyDesktop}><strong>Total Parts:</strong></td>
                                    <td data-label="Total Parts">{NumberUtils.applyCurrencyFormat(totalParts || 0)}</td>
                                    <td data-label="Hat #">
                                        <strong className={onlyDesktop}>
                                            Hat #: &nbsp;
                                        </strong>
                                        {hatNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="Status">{getBadgeStatus(status)}</td>
                                    <td className={onlyDesktop}>Advisor:</td>
                                    <td data-label="Advisor" className={largeCell}>{advisor}</td>
                                    <td className={clsx(onlyDesktop, minCell)}>Hours:</td>
                                    <td className={minCell} data-label="Hours">
                                        {NumberUtils.round(ArrayUtils.getTotalByItem(jobs, 'hours', false))}
                                    </td>
                                    <td className={onlyDesktop}>Warranty:</td>
                                    <td data-label="Warranty">{NumberUtils.applyCurrencyFormat(warranty || 0)}</td>
                                    <td className={onlyDesktop}>Deposit:</td>
                                    <td data-label="Deposit">{NumberUtils.applyCurrencyFormat(deposit || 0)}</td>
                                    <td className={onlyDesktop}><strong>Total Labor:</strong></td>
                                    <td data-label="Total Labor">{NumberUtils.applyCurrencyFormat(totalLabor || 0)}</td>
                                    {postedDate && (
                                        <td data-label="Posted Date">
                                            <strong className={onlyDesktop}>
                                                Posted Date: &nbsp;
                                            </strong>
                                            {DateUtils.getOnlyDate(postedDate)}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td data-label="Age">
                                        <span className={ageCell}>
                                            {DateUtils.diff((roClosed || new Date()), roOpen)}
                                                        &nbsp;days age
                                        </span>
                                        <br />
                                        <span className={ageCell}>
                                            {lotName}
                                        </span>
                                    </td>
                                    <td className={onlyDesktop}>Vehicle:</td>
                                    <td data-label="Vehicle" className={largeCell}>
                                        {vehicleStock !== 0 && (
                                            <Link to={`/inventory/${vehicleStock}`} target="_blank" className={inventoryLink}>
                                                {`${vehicleStock} - ${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}
                                            </Link>
                                        )}
                                        {vehicleStock === 0 && `${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}
                                    </td>
                                    <td className={clsx(onlyDesktop, minCell)}>Parts:</td>
                                    <td className={minCell} data-label="Parts">
                                        {ArrayUtils.getTotalByItem(jobs?.flatMap((c) => c.parts) || [], 'quantity', false)}
                                    </td>
                                    <td className={onlyDesktop}>Discount:</td>
                                    <td data-label="Discount">{NumberUtils.applyCurrencyFormat(discount || 0)}</td>
                                    <td className={onlyDesktop}>Shop Supplies:</td>
                                    <td data-label="Shop Supplies">{NumberUtils.applyCurrencyFormat(shopSupplies || 0)}</td>
                                    <td className={onlyDesktop}><strong>Total Invoice:</strong></td>
                                    <td data-label="Total Invoice">{NumberUtils.applyCurrencyFormat(total || 0)}</td>
                                    {dealNumber && (
                                        <td data-label="Deal #">
                                            <Link to={`/deals/${dealNumber}`} target="_blank">
                                                <Button variant="outlined" color="secondary" size="small">
                                                    <strong className={onlyDesktop}>
                                                        Deal #: &nbsp;
                                                    </strong>
                                                    {dealNumber}
                                                </Button>
                                            </Link>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        {SERVICE_EDIT && !isHistory
                        && (
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<BorderColorOutlined />}
                                className={actionButton}
                                onClick={onEdit}
                            >
                                Edit
                            </Button>
                        ) }
                        { roClosed && SERVICE_EDIT && !isHistory && (
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<ReceiptOutlinedIcon />}
                                className={actionButton}
                                onClick={onOpenJournalModal}
                            >
                                {postedDate != null ? 'Revert journal' : 'Post to accounting'}
                            </Button>
                        )}
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ReceiptOutlinedIcon />}
                            className={actionButton}
                            onClick={() => onPrint({ invoiceNumber, vehicleStock }, ServiceActions.PRINT_INSPECTION_LIST)}
                        >
                            Inspection
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ReceiptOutlinedIcon />}
                            className={actionButton}
                            onClick={() => onPrint({ invoiceNumber }, ServiceActions.PRINT_CONSENT)}
                        >
                            Consent
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ReceiptOutlinedIcon />}
                            className={actionButton}
                            onClick={() => onPrint({ invoiceNumber }, ServiceActions.PRINT_INVOICE)}
                        >
                            Invoice
                        </Button>
                        {!isHistory && renderButtonNextStatus()}
                        {SERVICE_EDIT && !isHistory
                        && (
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<SpeakerNotesOutlinedIcon />}
                                className={actionButton}
                                onClick={() => onNotes(invoiceNumber)}
                            >
                                History
                            </Button>
                        ) }
                        {SERVICE_EDIT && !isHistory
                        && (
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<HistoryOutlinedIcon />}
                                className={actionButton}
                                onClick={() => onHistory(invoiceNumber)}
                            >
                                VIN History
                            </Button>
                        ) }
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={jobTableCls}>
                <ServiceJobsItem
                    serviceInvoiceId={invoiceNumber}
                    minmumGPValue={minmumGPValue}
                    jobs={jobs}
                    classes={classes}
                    changeJobStatus={(newStatus, jobNumber) => onChangeJobStatus(newStatus, jobNumber, invoiceNumber)}
                    invoiceIdentifier={identifier}
                    roStatus={status}
                />
            </AccordionDetails>
            {postingJournal && (
                <CommonJournalPopup
                    showPopup
                    postedDate={roClosed}
                    isAlreadyPosted={postedDate != null}
                    recordId={invoiceNumber}
                    onToogleModal={onCloseJournalModal}
                    journalType={JournalTypes.SERVICE}
                />
            )}
        </Accordion>
    );
};

ServiceInvoiceItem.propTypes = {
    invoiceItem: PropTypes.shape({
        invoiceNumber: PropTypes.number.isRequired,
        customer: PropTypes.oneOfType([PropTypes.object]),
        status: PropTypes.string,
        advisor: PropTypes.string,
        roClosed: PropTypes.string,
        roOpen: PropTypes.string,
        vehicleStock: PropTypes.number,
        vehicleYear: PropTypes.string,
        vehicleMake: PropTypes.string,
        vehicleModel: PropTypes.string,
        vehicleTrim: PropTypes.string,
        tax: PropTypes.number,
        fees: PropTypes.number,
        totalParts: PropTypes.number,
        jobs: PropTypes.array,
        warranty: PropTypes.number,
        deposit: PropTypes.number,
        totalLabor: PropTypes.number,
        discount: PropTypes.number,
        shopSupplies: PropTypes.number,
        total: PropTypes.number,
        inHouse: PropTypes.bool,
        lotName: PropTypes.string,
        hatNumber: PropTypes.number,
        postedDate: PropTypes.string,
        dealNumber: PropTypes.number,
        totalSubletPrice: PropTypes.number,
        totalSubletCost: PropTypes.number,
    }).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]),
    minimumGPList: PropTypes.array.isRequired,
    onPrint: PropTypes.func.isRequired,
    onChangeStatus: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onChangeJobStatus: PropTypes.func.isRequired,
    expandOrCollapse: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifier: PropTypes.string,
    onHistory: PropTypes.func,
    isHistory: PropTypes.bool,
    onNotes: PropTypes.func,
    setCopyText: PropTypes.func,
    copyText: PropTypes.string,
};

ServiceInvoiceItem.defaultProps = {
    classes: {},
    open: false,
    identifier: '',
    onHistory: null,
    isHistory: false,
    onNotes: null,
    setCopyText: null,
    copyText: '',
};

const areEqual = (prevProps, nextProps) => (prevProps.identifier === nextProps.identifier && nextProps.open === prevProps.open
    && prevProps.copyText === nextProps.copyText);

export default React.memo(ServiceInvoiceItem, areEqual);
