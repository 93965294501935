import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import {
    ActivityStatuses, ActivityResult,
} from 'utils/enum/ActivitiesEnum';
import {
    makeStyles, Divider,
} from '@material-ui/core';
import DateUtils from 'lib/DateUtils';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';

// Icon
import { ReactComponent as CallIncoming } from 'assets/call-missed.svg';
import { ReactComponent as CallOutgoing } from 'assets/call-out-going.svg';
import { ReactComponent as CallScheduled } from 'assets/call-scheduled.svg';
import NumberUtils from 'lib/NumberUtils';

const useStyles = makeStyles((theme) => ({
    blueIcon: {
        '& g': {
            stroke: theme.palette.info.main,
        },
        color: theme.palette.info.main,
    },
    redIcon: {
        '& g': {
            stroke: theme.palette.error.main,
        },
    },
    greenIcon: {
        '& g': {
            stroke: theme.palette.success.main,
        },
    },
    container: {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    body: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
    },
    item: {
        marginLeft: theme.spacing(4),
        padding: theme.spacing(0.8, 0),
        '& > span:first-child': {
            marginRight: theme.spacing(1),
        },
    },
    subject: {
        marginLeft: theme.spacing(1),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    footer: {
        padding: theme.spacing(3, 2),
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const getBasicInformation = (record, currentType = '', classes) => {
    const { status, result } = record;
    const currentStatus = StringUtils.toUpperCase(status);
    const currentResult = StringUtils.toUpperCase(result);

    if (currentStatus === ActivityStatuses.SCHEDULED) {
        return { icon: <CallScheduled className={classes.blueIcon} />, description: `${currentType} ${status}` };
    }

    // TODO: Check this type of status
    if (currentStatus === ActivityStatuses.MISSED) {
        return { icon: <CallIncoming className={classes.redIcon} />, description: `${status} ${currentType} from customer` };
    }

    if (currentStatus === ActivityStatuses.OUTGOING && currentResult === ActivityResult.LEFT_VOICEMAIL) {
        return { icon: <CallOutgoing className={classes.redIcon} />, description: 'Left Voicemail' };
    }

    if ((currentStatus === ActivityStatuses.OUTGOING && currentResult === ActivityResult.ANSWERED) || currentStatus === ActivityStatuses.COMPLETED) {
        return { icon: <CallOutgoing className={classes.greenIcon} />, description: `${currentType} completed` };
    }

    if (currentStatus === ActivityStatuses.OUTGOING && currentResult === ActivityResult.NOANSWER) {
        return { icon: <CallOutgoing className={classes.redIcon} />, description: `${currentType}ed and no answer` };
    }

    // TODO: Check this type of status
    if (currentStatus === ActivityStatuses.OUTGOING) {
        return { icon: <CallOutgoing className={classes.redIcon} />, description: `Outgoing ${currentType}` };
    }

    if (currentStatus === ActivityStatuses.INCOMING && currentResult === ActivityResult.NOANSWER) {
        return { icon: <CallIncoming className={classes.redIcon} />, description: `Incoming ${currentType} and no answer` };
    }

    if (currentStatus === ActivityStatuses.INCOMING && currentResult === ActivityResult.ANSWERED) {
        return { icon: <CallIncoming className={classes.greenIcon} />, description: `${currentType} completed` };
    }

    // TODO: Check this type of status
    if (currentStatus === ActivityStatuses.INCOMING) {
        return { icon: <CallIncoming className={classes.greenIcon} />, description: `Incoming ${currentType}` };
    }

    return { icon: '', description: '' };
};

const CallActivityItem = ({ record, onOpen }) => {
    const classes = useStyles();
    const placeholder = '--';
    const { call } = record;
    const realizationDate = (record.realizationDate != null ? DateUtils.calendarWithTime(record.realizationDate) : placeholder);
    const duration = call.duration != null ? NumberUtils.secondsToHms(call.duration) : placeholder;
    const basicInformation = getBasicInformation(call, record.type, classes);

    return (
        <div className={clsx(classes.container, 'box-shadow')} onDoubleClick={() => onOpen(record, true)}>
            <div className={classes.header}>
                <div className="d-flex-center">
                    {basicInformation.icon}
                    <span className={classes.subject}>{basicInformation.description}</span>
                </div>
                <CreatedOnWithAvatar
                    record={record}
                />
            </div>
            <Divider />
            <div className={classes.body}>
                <div className={classes.item}>
                    <span>Date:</span>
                    <span>{realizationDate}</span>
                </div>
                <div className={classes.item}>
                    <span>Duration:</span>
                    <span>{duration}</span>
                </div>
            </div>
            <Divider />
            <div className={clsx(classes.footer, 'text-pre-line')}>
                {record.note}
            </div>
        </div>
    );
};

CallActivityItem.propTypes = {
    onOpen: PropTypes.func,
    record: PropTypes.object.isRequired,
};

CallActivityItem.defaultProps = {
    onOpen: () => {},
};

export default CallActivityItem;
