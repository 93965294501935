/* eslint-disable import/prefer-default-export */
const modules = Object.freeze({
    OPPORTUNITIES: 'opportunities',
    DEALS: 'deals',
    INVENTORY: 'inventory',
    SETTINGS: 'settings',
    ACCOUNTING: 'accounting',
    CUSTOMERS: 'customers',
    CRM: 'crm',
    CALENDAR: 'calendar',
    COMMUNICATION: 'communication',
    BHPH: 'bhph',
    QUERIES: 'queries',
    PAYROLL: 'payroll',
    REPORTS: 'reports',
    RULES: 'rules',
    PARTS: 'parts',
    SERVICE: 'service',
    FLOORLOG: 'floorlog',
    PREFERENCES: 'preferences',
    DASHBOARD: 'dashboard',
    CUSTOM_REPORTS: 'custom-reports',
    TRAINING: 'training',
});

export { modules };
