import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import { modules } from 'utils/enum/modules';
import VehicleImage from 'components/widgets/VehicleImage';
import { makeStyles, Typography } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';
import LeadHelper from 'components/modules/lead/LeadHelper';

const useStyles = makeStyles((theme) => ({
    body: {
        display: 'flex',
        alignItems: 'stretch',
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Arial',
    },
    miles: {
        marginLeft: theme.spacing(1),
    },
    price: {
        color: theme.palette.text.fruitSalad,
    },
    rightContainer: {
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& div > span': {
            color: theme.palette.text.scorpion,
        },
    },
    basicInformation: {
        flex: 1,
        marginLeft: theme.spacing(1),
        '& p': {
            color: theme.palette.text.scorpion,
        },
        '& span': {
            color: theme.palette.text.scorpion,
        },
    },
    stockNumberValue: {
        marginLeft: theme.spacing(0.5),
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    vehicleYear: {
        fontWeight: 700,
        fontFamily: 'Arial',
        marginRight: theme.spacing(1),
        color: theme.palette.text.waterloo,
    },
    vehicleMeta: {
        display: 'flex',
        alignItems: 'center',
        height: 16,
    },
    vehicleImageWrapper: {
        alignItems: 'center',
        maxWidth: 115,
        minWidth: 115,
        '& > img': {
            border: `1px solid ${theme.palette.border.secondary}`,
            maxHeight: 75,
            height: 75,
            width: '100%',
            objectFit: 'cover',
        },
    },
}));

const InterestVehicleCard = ({ record }) => {
    const placeholder = '--';
    const classes = useStyles();
    const stockNumber = record.vehicle?.stockNumber || placeholder;
    const vehicleLine1 = record.vehicle ? `${record.vehicle.model ?? ''} - ${record.vehicle.trim ?? ''}`
        : `${record.vehicleModel ?? ''} - ${record.vehicleTrim ?? ''}`;
    const year = record.vehicle ? `${record.vehicle.year ?? ''}` : `${record.vehicleYear ?? ''}`;
    const make = record.vehicle ? `${record.vehicle.make ?? ''}` : `${record.vehicleMake ?? ''}`;
    let price = null;
    if (record.vehicle) {
        const chosenVehiclePrice = LeadHelper.getVehiclePriceField(record.chosenVehiclePrice);
        price = chosenVehiclePrice ? record.vehicle[chosenVehiclePrice] : record.vehicle.stickerPrice;
    } else {
        price = record.vehiclePrice;
    }
    const miles = NumberUtils.applyThousandsFormat(record.vehicle?.miles);

    const getVehicleImage = () => (
        <div className={classes.vehicleImageWrapper}>
            <VehicleImage
                image={record.vehicle?.thumbnail}
                bodyStyle={record.vehicle?.style}
            />
        </div>
    );

    return (
        <div className={clsx(classes.body)}>
            {stockNumber !== placeholder && (
                <RouteLink
                    to={{
                        pathname: `/${modules.INVENTORY}/${stockNumber}`,
                    }}
                    target="_blank"
                >
                    {getVehicleImage()}
                </RouteLink>
            )}
            {stockNumber === placeholder && getVehicleImage()}
            <div className={classes.basicInformation}>
                <div className="d-flex-center">
                    <Typography variant="body1">
                        Stock:
                    </Typography>
                    <span className={classes.stockNumberValue}>{stockNumber}</span>
                </div>
                <div className={classes.vehicleMeta}>
                    <Typography variant="body1" className={classes.vehicleYear}>
                        {year}
                    </Typography>
                    <Typography variant="body1">
                        {make}
                    </Typography>
                </div>
                <div className={classes.vehicleMeta}>
                    <Typography variant="body1" className={clsx(classes.vehicleTextColor, classes.bold)}>{vehicleLine1}</Typography>
                </div>
            </div>
            <div className={classes.rightContainer}>
                <span className={classes.price}>{NumberUtils.applyCurrencyFormat(price)}</span>
                <div>
                    <span>Miles:</span>
                    <span className={classes.miles}>{miles}</span>
                </div>
            </div>
        </div>
    );
};

InterestVehicleCard.propTypes = {
    record: PropTypes.object.isRequired,
};

export default InterestVehicleCard;
