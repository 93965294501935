/* eslint-disable import/prefer-default-export */
const initServiceState = {
    record: {
        invoiceNumber: 0,
        customerCode: 0,
        customer: '',
        stockNumer: 0,
        milesIn: 0,
        milesOut: 0,
        hatNumber: 0,
        vehicleStock: 0,
        vehicleVin: '',
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleTrim: '',
        licencePlate: '',
        lotId: 0,
        lotName: '',
        advisorId: 0,
        advisor: '',
        saveParts: false,
        ref: '',
        deposit: 0,
        warranty: 0,
        warrantyDeductable: 0,
        fees: 0,
        shopSupplies: 0,
        cancellationFee: 0,
        storageFee: 0,
        laborDiscount: false,
        discountPercentage: 0,
        discount: 0,
        quickNote: '',
        status: '',
        totalParts: 0,
        totalLabor: 0,
        totalSubletPrice: 0,
        customerPay: 0,
        total: 0,
        customerName: '',
        inHouse: false,
        date: new Date(),
        inspectionDone: false,
        tax: 0,
        estimateDelivery: '',
        hashRecord: '',
        customerWaiting: false,
        rentalCar: false,
    },
    jobs: [],
    inspectionList: [],
    isDirty: false,
    couldLostData: false,
    isDirtyFiles: false,
    showServicePopup: true,
    vendorList: [],
    opCodesList: [],
    userList: [],
    enumList: [],
    identifierJobsMainInfo: '',
    identifierJobs: '',
    identifierJobsHeader: '',
    identifierJobsMoreInfo: '',
    identifierJobsParts: '',
    serviceSettings: {
        customerPayHourlyRate: 0,
        hourlyLaborRate: 0,
        noTaxOnRoLabor: false,
        shopSuppliesLimit: 0,
        shopSuppliesPercent: 0,
        surplusTax: 0,
        technicianSettings: [],
        customerSubletPriceMarkUp: 0,
        internalSubletPriceMarkUp: 0,
        customTaxRateEnabled: false,
        customTaxRateValue: 0,
        chargeSalesTaxASR: false,
        chargeSalesTaxOnWarrantyDeductable: false,
        shopSuppliesType: 'All',
    },
    approvalInspection: false,
    reconCost: {
        maximumReconCost: 0,
        totalRepairs: 0,
    },
};

export { initServiceState };
