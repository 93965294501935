import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import DealUtils from 'utils/DealUtils';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import SalesTaxDialog from 'components/modules/deals/SalesTaxDialog';
import FeesDialog from 'components/modules/deals/create/dealTab/FeesDialog';
import LeaseDealStructure from 'components/widgets/deals/LeaseDealStructure';
import RollPaymentDialog from 'components/modules/deals/create/dealTab/RollPaymentDialog';
import RollBalanceDialog from 'components/modules/deals/create/dealTab/RollBalanceDialog';
import AnnualMileageDialog from 'components/modules/deals/create/dealTab/AnnualMileageDialog';
import LeasePaymentDialog from 'components/modules/deals/create/dealTab/LeasePaymentDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {
    DealStructureFields, DealSection, DealType, TagType, DealStatus,
} from 'utils/enum/DealEnum';
import AmountFinancedForm from 'components/modules/deals/create/dealTab/AmountFinancedForm';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InputNumberWithButton from 'components/widgets/InputNumberWithButton';
import DealStructureContainer from 'components/containers/deals/read/dealTab/DealStructureContainer';
import TitleRegistrationDialog from 'components/modules/deals/create/dealTab/TitleRegistrationDialog';
import DeferredDownPaymentDialog from 'components/modules/deals/create/dealTab/DeferredDownPaymentDialog';

import { isValidField } from 'utils/schema/utils';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    Paper, Typography, Button as MUButton, Grid,
} from '@material-ui/core';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as UnCheckIcon } from 'assets/uncheck.svg';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

// Task In progress
const styles = (theme) => DealStyles.dealStructure(theme, fade);
const labels = {
    dealType: 'Deal Type',
    leadSource: 'Lead Source',
    soldDate: 'Sold Date',
    price: 'Price',
    amountFinanced: 'Amount Financed',
    financeCharge: 'Finance Charge',
    fees: 'Fees',
    lender: 'Lender',
    vscGapProducts: 'VSC + GAP + Products',
    intRate: 'Int. Rate',
    apr: 'APR',
    trade: 'Trade',
    payoff: 'Payoff',
    buyRate: 'Buy Rate',
    reserve: 'Reserve',
    taxableAmount: 'Taxable Amount',
    term: 'Term',
    salesTax: 'Sales Tax',
    firstPaymentDue: 'First Payment Due',
    titleRegistration: 'Title & Registration',
    payment: 'Payment',
    cashDownPayment: 'Cash Down Payment',
    discount: 'Discount',
    deferredDownPayment: 'Deferred Down Payment',
    originationFee: 'Origination Fee',
    balanceDue: 'Balance Due',
    ricAssignment: 'RIC Assignment',
    registerOutOfState: 'Register out of State',
    plate: 'Plate',
    type: 'Type',
    netDue: 'Net Due',
    cashTowardsLease: 'Cash Towards Lease',
    residual: 'Residual',
    annualMileage: 'Annual Mileage',
    grossCapitalizedCost: 'Gross Capitalized Cost',
    capCostReduction: 'Cap Cost Reduction',
    adjustedCapCost: 'Adjusted Cap Cost',
    leaseAmount: 'Lease Amount',
    rentalCharge: 'Rental Charge',
    moneyFactor: 'Money Factor',
    paymentLease: 'Payment (Including Sales Tax)',
    cashDueAtSigning: 'Cash Due at Signing',
    backEndTax: 'Back End Tax',
};

// TODO: Graphql still doesn't return these 5 fields ({labels.originationFee}, Payoff, APR, Lender, Title & Registratio)
// Pending to add format to currency type number and percentage
class DealStructure extends Component {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);
    }

    onChangeFloat = (field, value) => {
        const { props: { onChangeValue } } = this;
        const currentValue = StringUtils.isEmpty(value) ? 0 : parseFloat(value);

        onChangeValue(field, currentValue);
    }

    onChangeInteger = (field, value) => {
        const { props: { onChangeValue } } = this;
        const currentValue = StringUtils.isEmpty(value) ? 0 : parseInt(value, 10);

        onChangeValue(field, currentValue);
    }

    renderDialog() {
        const {
            props: {
                onApplyAmountFinanced,
                lotData,
                dealStructure: {
                    registration,
                    title,
                    tags,
                    tagAgencyFee,
                    taxExempt,
                    salesTax,
                    salesTaxOverride,
                    inventoryTaxOverride,
                    inventoryTaxAmount,
                    state,
                    docStamp,
                    loanContractId,
                    portfolioId,
                    salesTaxRate,
                    overrideTaxRate,
                    dealType,
                    paymentOverride,
                    paymentAmount,
                    finalPaymentDue,
                    finalPaymentAmount,
                    financeAmount,
                    minimumPaymentOverride,
                    balanceDueOverride,
                    balanceDueOverrideAmount,
                    leasePaymentAmount,
                    leasePaymentTax,
                    terminationFee,
                    leaseEndingOverage,
                    plannedAnnualMileage,
                    contractAnnualMileage,
                    leaseOveragePerMile,
                },
                onApplyTitleRegistration, onChangeSalesTax, onCancelSalesTax, onSaveSalesTax, onChangeValue,
                toggleModalDealStructure, showTypeModal, openDialogDealStructure, accountNumber,
                updateFees, setDeferredDownPayment, deferredPayments, isCalculatingDeal, resetDealInformation,
            },
        } = this;
        const salesTaxData = {
            taxExempt,
            salesTaxOverride,
            salesTax,
            inventoryTaxOverride,
            inventoryTaxAmount,
            state,
            salesTaxRate,
            overrideTaxRate,
        };
        let isWholesale = false;
        let isLease = false;
        if (dealType) {
            const type = dealType.toUpperCase();
            isWholesale = type === DealType.WHOLESALE.toUpperCase();
            isLease = type === DealType.LEASE.toUpperCase()
                    || type === DealType.LHPH.toUpperCase();
        }

        switch (showTypeModal) {
        case DealStructureFields.FEES: {
            const { props: { dealStructure } } = this;
            const {
                dealerFee, inspectionFee, mbi, userField1,
                userField2, userField3, userField4, userField5, userField6, userField7,
            } = dealStructure;
            const feesData = {
                dealerFee,
                inspectionFee,
                mbi,
                tagAgencyFee,
                userField1,
                userField2,
                userField3,
                userField4,
                userField5,
                userField6,
                userField7,
            };

            return (
                <FeesDialog
                    lotData={lotData}
                    isLease={isLease}
                    feesData={feesData}
                    updateFees={updateFees}
                    open={openDialogDealStructure}
                    toggleModal={toggleModalDealStructure}
                />
            );
        }
        case DealStructureFields.TITLE_REGISTRATION:
            return (
                <TitleRegistrationDialog
                    toggleModal={toggleModalDealStructure}
                    open={openDialogDealStructure}
                    lotData={lotData}
                    registration={registration}
                    title={title}
                    tags={tags}
                    onApplyTitleRegistration={onApplyTitleRegistration}
                />
            );
        case DealStructureFields.DEFERRED_DOWN_PAYMENT:
            return (
                <DeferredDownPaymentDialog
                    open={openDialogDealStructure}
                    toggleModal={toggleModalDealStructure}
                    accountNumber={accountNumber}
                    deferredPayments={deferredPayments}
                    setDeferredDownPayment={setDeferredDownPayment}
                />
            );
        case DealStructureFields.SALES_TAX:
            return (
                <SalesTaxDialog
                    open={openDialogDealStructure}
                    salesTaxData={salesTaxData}
                    lotSetting={lotData}
                    onChangeSalesTax={onChangeSalesTax}
                    onCancelSalesTax={onCancelSalesTax}
                    onSaveSalesTax={onSaveSalesTax}
                    isCalculatingDeal={isCalculatingDeal}
                    isWholesale={isWholesale}
                />
            );
        case DealStructureFields.AMOUNT_FINANCED:
            return (
                <AmountFinancedForm
                    open={openDialogDealStructure}
                    toggleModal={toggleModalDealStructure}
                    applyAmountFinancedValue={onApplyAmountFinanced}
                    portfolioId={portfolioId}
                    contractId={loanContractId}
                    docStamp={docStamp}
                />
            );
        case DealStructureFields.ROLL_PAYMENT:
            return (
                <RollPaymentDialog
                    onChange={onChangeValue}
                    paymentAmount={paymentAmount}
                    financeAmount={financeAmount}
                    finalPaymentDue={finalPaymentDue}
                    paymentOverride={paymentOverride}
                    toggleModal={toggleModalDealStructure}
                    finalPaymentAmount={finalPaymentAmount}
                    resetDealInformation={resetDealInformation}
                    minimumPaymentOverride={minimumPaymentOverride}
                />
            );
        case DealStructureFields.ROLL_BALANCE:
            return (
                <RollBalanceDialog
                    onChange={onChangeValue}
                    toggleModal={toggleModalDealStructure}
                    balanceDueOverride={balanceDueOverride}
                    resetDealInformation={resetDealInformation}
                    balanceDueOverrideAmount={balanceDueOverrideAmount}
                />
            );
        case DealStructureFields.LEASE_MILEAGE:
            return (
                <AnnualMileageDialog
                    terminationFee={terminationFee}
                    leaseEndingOverage={leaseEndingOverage}
                    plannedAnnualMileage={plannedAnnualMileage}
                    contractAnnualMileage={contractAnnualMileage}
                    leaseOveragePerMile={leaseOveragePerMile}
                    onChangeValue={onChangeValue}
                    toggleModal={toggleModalDealStructure}
                    balanceDueOverride={balanceDueOverride}
                    resetDealInformation={resetDealInformation}
                    balanceDueOverrideAmount={balanceDueOverrideAmount}
                />
            );
        case DealStructureFields.LEASE_PAYMENT:
            return (
                <LeasePaymentDialog
                    toggleModal={toggleModalDealStructure}
                    leasePaymentAmount={leasePaymentAmount}
                    leasePaymentTax={leasePaymentTax}
                />
            );
        default:
            return null;
        }
    }

    renderDealStructure() {
        const {
            props:
            {
                dealStructure,
                isReadOnlyDealStructure,
                toggleModalDealStructure,
                vendorList,
                leadSourceList,
                formValidations,
                onChangeValue,
                onChangeSoldDate,
                onChangeDayFirstPaymentDue,
                onChangeDateFirstPaymentDue,
            },
        } = this;
        const {
            dealType,
        } = dealStructure;
        let isLease = false;
        if (dealType) {
            const type = dealType.toUpperCase();
            isLease = type === DealType.LEASE.toUpperCase()
                || type === DealType.LHPH.toUpperCase();
        }

        if (isLease) {
            return (
                <LeaseDealStructure
                    isReadOnly={isReadOnlyDealStructure}
                    labels={labels}
                    toggleModalDealStructure={toggleModalDealStructure}
                    data={dealStructure}
                    vendorList={vendorList}
                    leadSourceList={leadSourceList}
                    formValidations={formValidations}
                    onChangeValue={onChangeValue}
                    onChangeFloat={this.onChangeFloat}
                    onChangeSoldDate={onChangeSoldDate}
                    onChangeDayFirstPaymentDue={onChangeDayFirstPaymentDue}
                    onChangeDateFirstPaymentDue={onChangeDateFirstPaymentDue}
                />
            );
        }

        return isReadOnlyDealStructure ? this.renderReadOnlyMode() : this.renderForm();
    }

    // TODO: Verify in which time zone the date will be displayed for the field 'First Payment Due' and 'Sold Date'
    renderReadOnlyMode() {
        const { props: { classes, dealStructure } } = this;
        const {
            dealType, soldDate, financeAmount, totalFees, interestRate, totalTradeAllowance,
            buyRate, assigned, balanceDue, totalTradePayOff, leadSource, financeCharge,
            cashDownPayment, discount, firstPaymentDue, salesTax, salesPrice, totalProductsPrice,
            paymentAmount, totalDeferredDownPayment, taxableAmount, term, backEndTax,
            totalTitleRegistration, financeCompany, bankOriginAmount, registerOutState, netDue,
        } = dealStructure;
        const currentSoldDate = DateUtils.getOnlyDate(soldDate || '');
        const currentFirstPaymentDue = DateUtils.format(firstPaymentDue || '');

        return (
            <Grid container spacing={2}>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.dealType}:`}</Typography>
                    <Typography>{dealType || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.soldDate}:`}</Typography>
                    <Typography>{currentSoldDate}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.leadSource}:`}</Typography>
                    <Typography>{leadSource}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.amountFinanced}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(financeAmount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.price}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(salesPrice)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.financeCharge}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(financeCharge)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.fees}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalFees)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.lender}:`}</Typography>
                    <Typography noWrap>{financeCompany || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.vscGapProducts}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalProductsPrice)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.intRate}:`}</Typography>
                    <Typography>{NumberUtils.applyPercentageFormat(interestRate, '0.0000%')}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.trade}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTradeAllowance)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.buyRate}:`}</Typography>
                    <Typography>{NumberUtils.applyPercentageFormat(buyRate)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.payoff}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTradePayOff)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.term}:`}</Typography>
                    <Typography>{term || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.taxableAmount}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(taxableAmount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.firstPaymentDue}:`}</Typography>
                    <Typography>{currentFirstPaymentDue || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.salesTax}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(salesTax)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.payment}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(paymentAmount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.titleRegistration}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTitleRegistration)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.discount}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(discount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.cashDownPayment}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(cashDownPayment)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.deferredDownPayment}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalDeferredDownPayment)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.balanceDue}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(balanceDue)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.originationFee}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(bankOriginAmount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.netDue}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(netDue)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.ricAssignment}:`}</Typography>
                    <Typography>{assigned || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields} />
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.registerOutOfState}:`}</Typography>
                    {
                        registerOutState
                            ? <CheckIcon className={classes.checkIcon} />
                            : <UnCheckIcon className={classes.checkIcon} />
                    }
                </Grid>
                <Grid item xs={6} container className={classes.columnFields} />
                <Grid item xs={6} container className={classes.columnFields}>
                    { registerOutState && backEndTax > 0
                        && (
                            <>
                                <Typography color="primary" variant="h6">{`${labels.backEndTax}:`}</Typography>
                                <Typography>{NumberUtils.applyCurrencyFormat(backEndTax)}</Typography>
                            </>
                        )}
                </Grid>
            </Grid>
        );
    }

    renderForm() {
        const {
            props: {
                classes, toggleModalDealStructure, dealStructure, onChangeValue, vendorList, leadSourceList,
                onChangeDayFirstPaymentDue, onChangeDateFirstPaymentDue, onChangeSoldDate, formValidations,
            },
        } = this;
        const {
            soldDate, firstPaymentDue, dealType, salesPrice, totalFees, leadSource, financeCharge,
            totalProductsPrice, interestRate, totalTradeAllowance, buyRate, reserveAmount, reserveOverride,
            taxableAmount, term, paymentFrequency, salesTax, firstPaymentDueDays, paymentAmount,
            cashDownPayment, discount, discountOverride, totalDeferredDownPayment, balanceDue,
            assigned, registerOutState, tagPlate, tagPlateType, bankOriginAmount, regZAPR, backEndTax,
            totalTitleRegistration, financeCompany, totalTradePayOff, financeAmount, maxRate, paymentOverride, balanceDueOverride,
        } = dealStructure;

        let isCashOrWholesale = false;
        let isWholesale = false;
        if (dealType) {
            const type = dealType.toUpperCase();
            isCashOrWholesale = type === DealType.CASH.toUpperCase()
                || type === DealType.WHOLESALE.toUpperCase();
            isWholesale = type === DealType.WHOLESALE.toUpperCase();
        }

        const { errors } = formValidations;

        return (
            <Form className={clsx(classes.formW100, 'am-form ')}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.dealType}</Form.Label>
                        <Select
                            className={isValidField(errors, 'dealType') ? 'invalid-field' : ''}
                            name="dealType"
                            onChange={onChangeValue}
                            value={dealType}
                            placeholder="Select"
                            options={DealUtils.getDealType()}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col} className="full-width-date-picker">
                        <Form.Label>{labels.soldDate}</Form.Label>
                        <DatePicker
                            className={clsx('form-control', 'form-control-sm', isValidField(errors, 'soldDate') ? 'invalid-field' : '')}
                            onChange={(date) => onChangeSoldDate(date)}
                            selected={soldDate}
                            popperContainer={CalendarContainer}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.leadSource}</Form.Label>
                        <Select
                            className={isValidField(errors, 'leadSource') ? 'invalid-field' : ''}
                            name="leadSource"
                            onChange={onChangeValue}
                            value={leadSource}
                            placeholder="Select"
                            options={leadSourceList}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.amountFinanced}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'financeAmount') ? 'invalid-field' : ''}
                            value={financeAmount}
                            showCurrency
                            allowNegative
                            thousandSeparator
                            fixedDecimalScale
                            onClick={() => toggleModalDealStructure(DealStructureFields.AMOUNT_FINANCED)}
                            placeholder="$0.00"
                            disableButton={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.price}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'salesPrice') ? 'invalid-field' : ''}
                            disabled={!StringUtils.isEmpty(balanceDueOverride)}
                            showCurrency
                            thousandSeparator
                            placeholder="$0.00"
                            value={salesPrice}
                            onChange={(value) => this.onChangeFloat('salesPrice', value)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.financeCharge}</Form.Label>
                        <InputNumber
                            value={financeCharge}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            disabled
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.fees}</Form.Label>
                        <InputNumberWithButton
                            value={totalFees}
                            showCurrency
                            thousandSeparator
                            placeholder="$0.00"
                            onClick={() => toggleModalDealStructure(DealStructureFields.FEES)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.lender}</Form.Label>
                        <Select
                            className={isValidField(errors, 'financeCompany') ? 'invalid-field' : ''}
                            sorted
                            disabled={isCashOrWholesale}
                            name="financeCompany"
                            onChange={onChangeValue}
                            value={financeCompany}
                            options={vendorList}
                            placeholder="Select"
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="col-md-6">
                        <Form.Label>{labels.vscGapProducts}</Form.Label>
                        <InputNumber
                            value={totalProductsPrice}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            disabled
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.intRate}</Form.Label>
                        <InputNumber
                            className={
                                clsx(maxRate
                                    && interestRate > maxRate
                                    && !isCashOrWholesale ? 'invalid-field' : '', isValidField(errors, 'dealType') ? 'invalid-field' : '')
                            }
                            onChange={(value) => this.onChangeFloat('interestRate', value)}
                            disabled={isCashOrWholesale}
                            value={interestRate}
                            placeholder="0"
                            decimalScale={4}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.apr}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'regZAPR') ? 'invalid-field' : ''}
                            placeholder="0"
                            decimalScale={4}
                            disabled
                            value={regZAPR}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                {maxRate && !isCashOrWholesale && (
                    <Form.Row className={classes.maxRateRow}>
                        <Form.Group as={Col} className="d-flex-center justify-content-center">
                            <div className="text-danger">
                                { `MaxRate: ${maxRate}`}
                            </div>
                        </Form.Group>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.trade}</Form.Label>
                        <InputNumber
                            value={totalTradeAllowance}
                            placeholder="$0.00"
                            disabled
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.payoff}</Form.Label>
                        <InputNumber
                            value={totalTradePayOff}
                            placeholder="$0.00"
                            disabled
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.buyRate}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'buyRate') ? 'invalid-field' : ''}
                            onChange={(value) => this.onChangeFloat('buyRate', value)}
                            value={buyRate}
                            placeholder="0"
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.reserve}</Form.Label>
                        <div className={classes.flexCenter}>
                            <InputNumber
                                onChange={(value) => this.onChangeFloat('reserveAmount', value)}
                                value={reserveAmount}
                                placeholder="$0.00"
                                showCurrency
                                thousandSeparator
                                disabled={!reserveOverride}
                                size="sm"
                            />
                            {/** //TODO: Pending change border color to the checkbox */}
                            <Form.Check
                                onChange={(e) => onChangeValue('reserveOverride', e.target.checked)}
                                checked={reserveOverride}
                                className={classes.alignCheckBox}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="col-md-6">
                        <Form.Label>{labels.taxableAmount}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'taxableAmount') ? 'invalid-field' : ''}
                            value={taxableAmount}
                            placeholder="$0.00"
                            showCurrency
                            disabled
                            thousandSeparator
                            fixedDecimalScale
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.term}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'term') ? 'invalid-field' : ''}
                            onChange={(value) => this.onChangeFloat('term', value)}
                            value={term}
                            placeholder="0"
                            decimalScale={0}
                            disabled={isCashOrWholesale || paymentOverride}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="blank-label" />
                        <Select
                            className={isValidField(errors, 'paymentFrequency') ? 'invalid-field' : ''}
                            name="paymentFrequency"
                            value={paymentFrequency}
                            onChange={onChangeValue}
                            options={[
                                {
                                    value: 'Monthly',
                                    label: 'Monthly',
                                },
                                {
                                    value: 'Semi-Monthly',
                                    label: 'Semi-Monthly',
                                },
                                {
                                    value: 'Weekly',
                                    label: 'Weekly',
                                },
                                {
                                    value: 'Bi-Weekly',
                                    label: 'Bi-Weekly',
                                },
                            ]}
                            placeholder="Select"
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.salesTax}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'salesTax') ? 'invalid-field' : ''}
                            value={salesTax}
                            showCurrency
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="$0.00"
                            onClick={() => toggleModalDealStructure(DealStructureFields.SALES_TAX)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.firstPaymentDue}</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <InputNumber
                                    onChange={(value) => onChangeDayFirstPaymentDue(value)}
                                    value={firstPaymentDueDays}
                                    placeholder="0"
                                    disabled={isCashOrWholesale}
                                    decimalScale={0}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <DatePicker
                                    className={clsx('form-control', 'form-control-sm', isValidField(errors, 'firstPaymentDue') ? 'invalid-field' : '')}
                                    minDate={soldDate}
                                    popperContainer={CalendarContainer}
                                    onChange={(date) => onChangeDateFirstPaymentDue(date)}
                                    selected={firstPaymentDue}
                                    disabled={isCashOrWholesale}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.titleRegistration}</Form.Label>
                        <InputNumberWithButton
                            value={totalTitleRegistration}
                            onClick={() => toggleModalDealStructure(DealStructureFields.TITLE_REGISTRATION, 'Tag & Title')}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            disableButton={isWholesale}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.payment}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'paymentAmount') ? 'invalid-field' : ''}
                            value={paymentAmount}
                            showCurrency
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="$0.00"
                            disableButton={isCashOrWholesale}
                            size="sm"
                            onClick={() => (!isCashOrWholesale ? toggleModalDealStructure(DealStructureFields.ROLL_PAYMENT, 'Roll Payment') : null)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.cashDownPayment}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'cashDownPayment') ? 'invalid-field' : ''}
                            disabled={!StringUtils.isEmpty(balanceDueOverride)}
                            onChange={(value) => this.onChangeFloat('cashDownPayment', value)}
                            value={cashDownPayment}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.discount}</Form.Label>
                        <div className={classes.flexCenter}>
                            <InputNumber
                                onChange={(value) => this.onChangeFloat('discount', value)}
                                value={discount}
                                placeholder="$0.00"
                                showCurrency
                                thousandSeparator
                                disabled={!discountOverride}
                                size="sm"
                            />
                            {/** //TODO: Pending change border color to the checkbox */}
                            <Form.Check
                                checked={discountOverride}
                                onChange={(e) => onChangeValue('discountOverride', e.target.checked)}
                                className={classes.alignCheckBox}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.deferredDownPayment}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'totalDeferredDownPayment') ? 'invalid-field' : ''}
                            value={totalDeferredDownPayment}
                            onClick={() => toggleModalDealStructure(DealStructureFields.DEFERRED_DOWN_PAYMENT)}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.originationFee}</Form.Label>
                        <InputNumber
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            onChange={(value) => this.onChangeFloat('bankOriginAmount', value)}
                            value={bankOriginAmount}
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.balanceDue}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'balanceDue') ? 'invalid-field' : ''}
                            value={balanceDue}
                            showCurrency
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="$0.00"
                            size="sm"
                            disableButton={isCashOrWholesale}
                            onClick={() => (!isCashOrWholesale ? toggleModalDealStructure(DealStructureFields.ROLL_BALANCE, 'Roll Balance Due') : null)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.ricAssignment}</Form.Label>
                        <Select
                            name="assigned"
                            onChange={onChangeValue}
                            value={assigned}
                            options={[
                                {
                                    value: 'Assigned without recourse',
                                    label: 'Assigned without recourse',
                                },
                                {
                                    value: 'Assigned with recourse',
                                    label: 'Assigned with recourse',
                                },
                                {
                                    value: 'Assigned with limited recourse',
                                    label: 'Assigned with limited recourse',
                                },
                            ]}
                            placeholder="Select"
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className={classes.flexCenter}>
                        <Form.Label className={classes.marginBottom0}>{labels.registerOutOfState}</Form.Label>
                        {/** //TODO: Pending change border color to the checkbox */}
                        <Form.Check
                            onChange={(e) => onChangeValue('registerOutState', e.target.checked)}
                            checked={registerOutState}
                            className={classes.alignCheckBox}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{labels.plate}</Form.Label>
                                <Form.Control
                                    onChange={(e) => onChangeValue('tagPlate', e.target.value)}
                                    value={tagPlate || ''}
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{labels.type}</Form.Label>
                                <Select
                                    name="tagPlateType"
                                    onChange={onChangeValue}
                                    value={tagPlateType}
                                    options={[
                                        {
                                            value: TagType.NEW,
                                            label: TagType.NEW,
                                        },
                                        {
                                            value: TagType.TRANSFER,
                                            label: TagType.TRANSFER,
                                        },
                                    ]}
                                    placeholder="Select"
                                    size="sm"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        { registerOutState && backEndTax > 0
                        && (
                            <>
                                <Form.Label className={classes.marginBottom0}>{labels.backEndTax}</Form.Label>
                                <InputNumber
                                    value={backEndTax}
                                    placeholder="$0.00"
                                    showCurrency
                                    thousandSeparator
                                    disabled
                                    size="sm"
                                />
                            </>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} />
                </Form.Row>
            </Form>
        );
    }

    renderActions() {
        const {
            props: {
                classes, isReadOnlyDealStructure, onCancelEditDealStructure, loading, dealStructure,
                onUpdateDealStructure, onEditDealStructure, editingMode, isSavingDeal, formValidations,
                editingStatus,
            },
        } = this;
        const { isEditing } = editingStatus;
        const { status, postedDate } = dealStructure;
        const allowSave = postedDate === null && (status === DealStatus.QUOTE || status === DealStatus.FNI || status === DealStatus.DELETED);

        const { isValid } = formValidations;

        if (!isReadOnlyDealStructure) {
            return (
                <div>
                    {allowSave
                        && (
                            <MUButton
                                size="small"
                                disabled={isSavingDeal || !isValid}
                                className={classes.textSuccess}
                                onClick={onUpdateDealStructure}
                                startIcon={<SaveOutlinedIcon />}
                            >
                                Save
                            </MUButton>
                        )}
                    <MUButton
                        size="small"
                        disabled={isSavingDeal}
                        className={classes.textError}
                        startIcon={<HighlightOffIcon />}
                        onClick={onCancelEditDealStructure}
                    >
                        Cancel
                    </MUButton>
                </div>
            );
        }

        return (
            <MUButton
                color="primary"
                size="small"
                disabled={((editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.DEAL_STRUCTURE) || loading}
                onClick={onEditDealStructure}
                startIcon={<BorderColorOutlinedIcon />}
            >
                Edit
            </MUButton>
        );
    }

    render() {
        const {
            classes, openDialogDealStructure, clientId,
        } = this.props;

        return (
            <Paper square className={classes.rootPaper}>
                <div className={classes.header}>
                    <Typography variant="h5">Deal Structure</Typography>
                    {DealUtils.clientIdIsWeb(clientId) && this.SALES_DEAL_WRITE && (this.renderActions()) }
                </div>
                {this.renderDealStructure()}
                {openDialogDealStructure && this.renderDialog()}
            </Paper>
        );
    }
}

DealStructure.propTypes = {
    dealStructure: PropTypes.shape({
        reserveOverride: PropTypes.bool,
        registerOutState: PropTypes.bool,
        discountOverride: PropTypes.bool,
        term: PropTypes.number,
        buyRate: PropTypes.number,
        discount: PropTypes.number,
        salesTax: PropTypes.number,
        totalFees: PropTypes.number,
        balanceDue: PropTypes.number,
        salesPrice: PropTypes.number,
        totalTradeAllowance: PropTypes.number,
        totalTradePayOff: PropTypes.number,
        interestRate: PropTypes.number,
        financeAmount: PropTypes.number,
        paymentAmount: PropTypes.number,
        reserveAmount: PropTypes.number,
        totalProductsPrice: PropTypes.number,
        taxableAmount: PropTypes.number,
        cashDownPayment: PropTypes.number,
        firstPaymentDueDays: PropTypes.number,
        totalDeferredDownPayment: PropTypes.number,
        soldDate: PropTypes.object,
        dealType: PropTypes.string,
        tagPlate: PropTypes.string,
        assigned: PropTypes.string,
        tagPlateType: PropTypes.string,
        firstPaymentDue: PropTypes.object,
        paymentFrequency: PropTypes.string,
        portfolio: PropTypes.object,
        contract: PropTypes.object,
        docStamp: PropTypes.number,
        dealerFee: PropTypes.number,
        inspectionFee: PropTypes.number,
        mbi: PropTypes.number,
        tagAgencyFee: PropTypes.number,
        userField1: PropTypes.number,
        userField2: PropTypes.number,
        userField3: PropTypes.number,
        userField4: PropTypes.number,
        userField5: PropTypes.number,
        userField6: PropTypes.number,
        userField7: PropTypes.number,
        totalTitleRegistration: PropTypes.number,
        registration: PropTypes.number,
        title: PropTypes.number,
        tags: PropTypes.number,
        taxExempt: PropTypes.bool,
        salesTaxOverride: PropTypes.bool,
        inventoryTaxOverride: PropTypes.bool,
        inventoryTaxAmount: PropTypes.number,
        state: PropTypes.string,
        financeCompany: PropTypes.string,
        tiPayoff1: PropTypes.number,
        tiPayoff2: PropTypes.number,
        bankOriginAmount: PropTypes.number,
        regZAPR: PropTypes.number,
        maxRate: PropTypes.number,
        loanContractId: PropTypes.number,
        portfolioId: PropTypes.number,
        postedDate: PropTypes.string,
        netDue: PropTypes.number,
        salesTaxRate: PropTypes.number,
        overrideTaxRate: PropTypes.bool,
        paymentOverride: PropTypes.bool,
        finalPaymentDue: PropTypes.object,
        finalPaymentAmount: PropTypes.number,
        minimumPaymentOverride: PropTypes.number,
        paymentOverridePercent: PropTypes.number,
        leadSource: PropTypes.string,
        financeCharge: PropTypes.number,
        balanceDueOverride: PropTypes.string,
        balanceDueOverrideAmount: PropTypes.number,
        leasePaymentAmount: PropTypes.number,
        leasePaymentTax: PropTypes.number,
        terminationFee: PropTypes.number,
        leaseEndingOverage: PropTypes.number,
        plannedAnnualMileage: PropTypes.number,
        contractAnnualMileage: PropTypes.number,
        leaseOveragePerMile: PropTypes.number,
        backEndTax: PropTypes.number,
        status: PropTypes.string,
        adjustLastPayment: PropTypes.bool,
    }).isRequired,
    lotData: PropTypes.object.isRequired,
    openDialogDealStructure: PropTypes.bool.isRequired,
    onApplyAmountFinanced: PropTypes.func.isRequired,
    isReadOnlyDealStructure: PropTypes.bool.isRequired,
    toggleModalDealStructure: PropTypes.func.isRequired,
    showTypeModal: PropTypes.string,
    onChangeValue: PropTypes.func.isRequired,
    onChangeSalesTax: PropTypes.func.isRequired,
    updateFees: PropTypes.func.isRequired,
    onApplyTitleRegistration: PropTypes.func.isRequired,
    onCancelSalesTax: PropTypes.func.isRequired,
    onSaveSalesTax: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
    onEditDealStructure: PropTypes.func.isRequired,
    onUpdateDealStructure: PropTypes.func.isRequired,
    setDeferredDownPayment: PropTypes.func.isRequired,
    onCancelEditDealStructure: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    deferredPayments: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeDayFirstPaymentDue: PropTypes.func.isRequired,
    onChangeDateFirstPaymentDue: PropTypes.func.isRequired,
    onChangeSoldDate: PropTypes.func.isRequired,
    resetDealInformation: PropTypes.func.isRequired,
    // General
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    vendorList: PropTypes.arrayOf(PropTypes.shape({
        vendorId: PropTypes.number,
        vendorName: PropTypes.string,
    })).isRequired,
    leadSourceList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    isCalculatingDeal: PropTypes.bool.isRequired,
    isSavingDeal: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    formValidations: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    editingStatus: PropTypes.object,
};

DealStructure.defaultProps = {
    showTypeModal: '',
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(DealStructureContainer(DealStructure));
