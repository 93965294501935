import React, {
    useState,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Dialog, DialogContent, Slide, Toolbar, DialogActions, Button,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import {
    Row, Form, Col, InputGroup, Button as BoostrapButton,
} from 'react-bootstrap';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import PropTypes from 'prop-types';
import AddServiceVehicleSchema from 'utils/schema/service/AddServiceVehicleSchema';
import LabelMemorize from 'components/widgets/form/LabelMemorize';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';
import InputNumber from 'components/widgets/InputNumber';
import { useLazyQuery } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddNewServiceVehicle = (props) => {
    const { onAddVehicle, onPopupClose } = props;
    const initialState = {
        data: {
            vin: '',
            make: '',
            model: '',
            trim: '',
            year: '',
        },
        isDirty: false,
        couldLostData: false,
    };

    const classes = useStyle();

    const [currentState, setCurrentState] = useState(initialState);

    const {
        data: record,
        data: {
            vin,
            make,
            model,
            trim,
            year,
        },
        isDirty,
        couldLostData,
    } = currentState;

    const isValidData = isValidSchema(AddServiceVehicleSchema, record);
    const { isValid, errors } = isValidData;

    const clearForm = () => {
        setCurrentState({
            ...currentState,
            data: {
                ...currentState.data,
                make: '',
                model: '',
                trim: '',
                year: '',
            },
            isDirty: false,
        });
    };

    const [loadVinData, { loading: vinLoading }] = useLazyQuery(InventoryQuery.GET_VEHICLE_DATA, {
        onCompleted: (res) => {
            if (res.decodeVin) {
                const {
                    make: vinMake,
                    model: vinModel,
                    trim: vinTrim,
                    year: vinYear,
                } = res.decodeVin;
                setCurrentState({
                    ...currentState,
                    data: {
                        ...currentState.data,
                        make: vinMake,
                        model: vinModel,
                        trim: vinTrim?.[0],
                        year: vinYear,
                    },
                    isDirty: true,
                });
            } else {
                clearForm();
            }
        },
        onError: () => {
            clearForm();
            ModalUtils.errorMessage(null, 'There was an error trying to decoding this vin number.');
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        setCurrentState({
            ...currentState,
            data: currentRecord,
            isDirty: true,
        });
    };

    const onPreClose = () => {
        if (isDirty) {
            setCurrentState({
                ...currentState,
                couldLostData: true,
            });
            return;
        }

        onPopupClose();
    };

    const onForceClose = () => {
        setCurrentState({
            ...currentState,
            couldLostData: false,
        });
    };

    const onDecodeVin = () => {
        loadVinData({
            variables: {
                vin,
                trim: '',
            },
        });
    };

    const messageToAllowSave = [];
    // eslint-disable-next-line no-unused-expressions
    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    return (
        <>
            <Dialog
                open
                onClose={onPreClose}
                maxWidth="xs"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                TransitionComponent={Transition}
            >
                <DialogAppBar
                    title="Add new vehicle"
                    onClose={onPreClose}
                />
                <DialogContent className={classes.noPadding}>
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column="sm" xs={4} text="Vin" />
                                    <Col xs={8}>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                onChange={(e) => onChangeValue('vin', e.target.value)}
                                                className={isValidField(errors, 'vin') ? 'invalid-field' : ''}
                                                value={vin}
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                                name="vin"
                                            />
                                            <InputGroup.Append>
                                                <BoostrapButton
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={onDecodeVin}
                                                    disabled={Boolean(isValidField(errors, 'vin'))}
                                                >
                                                    Decode
                                                </BoostrapButton>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column="sm" xs={4} text="Make" />
                                    <Col xs={8}>
                                        <Form.Control
                                            onChange={(e) => onChangeValue('make', e.target.value)}
                                            className={isValidField(errors, 'make') ? 'invalid-field' : ''}
                                            value={make}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="make"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column="sm" xs={4} text="Model" />
                                    <Col xs={8}>
                                        <Form.Control
                                            onChange={(e) => onChangeValue('model', e.target.value)}
                                            className={isValidField(errors, 'model') ? 'invalid-field' : ''}
                                            value={model}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="model"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column="sm" xs={4} text="Year" />
                                    <Col xs={8}>
                                        <InputNumber
                                            onChange={(value) => onChangeValue('year', value)}
                                            className={isValidField(errors, 'year') ? 'invalid-field' : ''}
                                            value={year}
                                            placeholder=""
                                            size="sm"
                                            name="year"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column="sm" xs={4} text="Trim" />
                                    <Col xs={8}>
                                        <Form.Control
                                            onChange={(e) => onChangeValue('trim', e.target.value)}
                                            className={isValidField(errors, 'trim') ? 'invalid-field' : ''}
                                            value={trim}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="trim"
                                        />
                                    </Col>
                                </Form.Group>
                            </Grid>
                        </Grid>
                        <ConfirmDialog
                            title="Attention - You have pending changes!"
                            description="Do you want to close without adding the vehicle?"
                            open={couldLostData}
                            variant="outlined"
                            titlePrimary="Close"
                            titleSecondary="Cancel"
                            onClose={onForceClose}
                            onClickSecondary={onForceClose}
                            onClickPrimary={onPopupClose}
                        />
                    </Container>
                </DialogContent>
                <DialogActions className={classes.borderTop}>
                    <Toolbar>
                        <div className={classes.buttonSpacing}>
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={onPreClose}
                            >
                                Cancel
                            </Button>
                            <ErrorMessageIndicator
                                messages={messageToAllowSave}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    disabled={!isValid}
                                    onClick={() => onAddVehicle(record)}
                                >
                                    Ok
                                </Button>
                            </ErrorMessageIndicator>
                        </div>
                    </Toolbar>
                </DialogActions>
            </Dialog>
            <If condition={vinLoading}>
                <DialogActionMessage message="Decoding VIN" />
            </If>
        </>
    );
};

AddNewServiceVehicle.propTypes = {
    onAddVehicle: PropTypes.func.isRequired,
    onPopupClose: PropTypes.func.isRequired,
};

export default AddNewServiceVehicle;
