import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Divider,
    FormControl, FormControlLabel, Grid, IconButton, makeStyles, Radio, RadioGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import Split from 'react-split';
import CatalogEnum from 'utils/enum/CatalogEnum';
import { Col, Form } from 'react-bootstrap';
import Header from 'components/widgets/Header';
import {
    AddCircleOutlineIcon, CachedIcon, SaveOutlinedIcon,
} from 'components/icons';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import Table from 'components/widgets/Table';
import InputControl from 'components/widgets/editorControls/InputControl';
import useCatalogListActions from 'components/widgets/catalogs/useCatalogListActions';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';

const useStyles = makeStyles((theme) => AccountingStyles.dialogForm(theme));
const ownStyles = makeStyles((theme) => ({
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const initialState = {
    defaultShippingTermId: 0,
    defaultShippingMethodId: 0,
    tab: 0,
};

const PartsSettingsLayout = () => {
    const classes = {
        ...useStyles(),
        ...ownStyles,
    };

    const [currentState, setCurrentState] = useState(initialState);

    const { tab, defaultShippingMethodId, defaultShippingTermId } = currentState;

    const {
        onSave,
        isSaving,
        isDeleting,
        loadData,
        loading,
        list,
        onAddNewLine,
        onDeleteConfirm,
        onCloseDeleteConfirm,
        handleEditorKeyDown,
        handleEditorChange,
        setIdToDelete,
        idToDelete,
    } = useCatalogListActions({
        catalogEnum: tab === 0 ? CatalogEnum.SHIPPING_METHOD : CatalogEnum.SHIPPING_TERM,
    });

    useEffect(() => {
        loadData({
            variables: {
                enumDescription: tab === 0 ? CatalogEnum.SHIPPING_METHOD : CatalogEnum.SHIPPING_TERM,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const reload = () => {
        loadData({
            variables: {
                enumDescription: tab === 0 ? CatalogEnum.SHIPPING_METHOD : CatalogEnum.SHIPPING_TERM,
            },
        });
    };

    const [saveData, { loading: isSavingDefault }] = useMutation(PartsMutation.SAVE_DEFAULT_PARTS_SETTINGS, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveDefaultPartsSettings) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSaveDefaults = () => {
        saveData({
            variables: {
                record: {
                    defaultShippingMethod: defaultShippingMethodId,
                    defaultShippingTerm: defaultShippingTermId,
                },
            },
        });
    };

    const changeDefaultInput = (columnId, newValue) => {
        setCurrentState((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    const columns = [
        {
            Header: 'Line',
            minWidth: 50,
            width: 50,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            accessor: 'lineId',
        },
        {
            Header: 'Description',
            minWidth: 400,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            accessor: 'description',
            Cell: (cell) => {
                const { column: { id }, original: { description } } = cell;
                return (
                    <InputControl
                        name={id}
                        value={description}
                        editorCellObject={cell}
                        rows={1}
                        onChange={handleEditorChange}
                        onKeyDown={handleEditorKeyDown}
                    />
                );
            },
        },
        {
            Header: 'Actions',
            minWidth: 60,
            headerClassName: classes.columnHeaderStyle,
            width: 70,
            Cell: (cell) => (
                <IconButton onClick={() => { setIdToDelete(cell.original.enumValuesId); }}>
                    <DeleteIcon className={classes.buttonDelete} />
                </IconButton>
            ),
        }];

    return (
        <div className={classes.box}>
            <div>
                <Split sizes={[75, 25]} className="split">
                    <div className={classes.box}>
                        <>
                            <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                                <RadioGroup row value={tab} onChange={(e, val) => setCurrentState({ ...currentState, tab: Number(val) })}>
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio color="primary" />}
                                        label="Shipping Method List"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label="Shipping Term List"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </>
                        <>
                            <Header>
                                <div className={classes.buttonSpacing}>
                                    <>
                                        <Button
                                            variant="outlined"
                                            startIcon={<SaveOutlinedIcon />}
                                            size="small"
                                            disabled={isSaving}
                                            onClick={onSave}
                                        >
                                            {isSaving ? 'Saving...' : 'Save'}
                                            {isSaving && <CircularProgress size={10} className={classes.buttonProgress} />}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<AddCircleOutlineIcon />}
                                            size="small"
                                            onClick={onAddNewLine}
                                        >
                                            Add
                                        </Button>
                                    </>
                                    <Button
                                        variant="outlined"
                                        startIcon={<CachedIcon />}
                                        onClick={reload}
                                        disabled={loading}
                                        size="small"
                                    >
                                        Reload
                                    </Button>
                                </div>
                            </Header>
                            <Table
                                className={clsx('-highlight', classes.table)}
                                data={loading ? [] : list}
                                columns={columns}
                                cursor="default"
                                load={loading}
                                totalRecords={loading ? 0 : list?.length}
                                rowSelected
                            />
                            <ConfirmDialog
                                title="Confirm remove line"
                                description={isDeleting ? 'Deleting Record...' : 'Are you sure you want to remove this line?'}
                                open={idToDelete !== 0}
                                variant="outlined"
                                titlePrimary="Yes"
                                titleSecondary="Cancel"
                                onClose={(onCloseDeleteConfirm)}
                                onClickSecondary={onCloseDeleteConfirm}
                                onClickPrimary={onDeleteConfirm}
                                disablePrimaryButton={isDeleting}
                                disableSecondaryButton={isDeleting}
                            />
                        </>
                    </div>
                    <div>
                        <Grid item className={classes.box}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Defaults</Form.Label>
                                <Divider />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Shipping Method</Form.Label>
                                <SelectControl
                                    name="defaultShippingMethodId"
                                    value={defaultShippingMethodId}
                                    placeHolder="select an account"
                                    onChange={changeDefaultInput}
                                    className={defaultShippingMethodId > 0 ? '' : 'invalid-field'}
                                    dataSource={{
                                        query: CatalogQuery.GET_ENUM_VALUES,
                                        variables: {
                                            enumDescription: CatalogEnum.SHIPPING_METHOD,
                                        },
                                        rootData: 'getEnumCatalogValues',
                                        idField: 'enumValuesId',
                                        descriptionField: 'description',
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Shipping Term</Form.Label>
                                <SelectControl
                                    name="defaultShippingTermId"
                                    value={defaultShippingTermId}
                                    placeHolder="select an account"
                                    onChange={changeDefaultInput}
                                    className={defaultShippingTermId > 0 ? '' : 'invalid-field'}
                                    dataSource={{
                                        query: CatalogQuery.GET_ENUM_VALUES,
                                        variables: {
                                            enumDescription: CatalogEnum.SHIPPING_TERM,
                                        },
                                        rootData: 'getEnumCatalogValues',
                                        idField: 'enumValuesId',
                                        descriptionField: 'description',
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Button
                                    variant="outlined"
                                    startIcon={<SaveOutlinedIcon />}
                                    size="small"
                                    disabled={isSavingDefault}
                                    onClick={onSaveDefaults}
                                >
                                    {isSavingDefault ? 'Saving...' : 'Save'}
                                    {isSavingDefault && <CircularProgress size={10} />}
                                </Button>
                            </Form.Group>
                        </Grid>
                    </div>
                </Split>
            </div>
        </div>
    );
};
export default PartsSettingsLayout;
