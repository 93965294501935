import React, { useEffect, useState } from 'react';
import {
    Grid, makeStyles,
    DialogContent, Dialog, Switch, // useMediaQuery, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
    Button,
    Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import DropdownQuery from 'components/widgets/DropdownQuery';
import BasicStyles from 'styles/modules/basic/basicStyles';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import { isValidField, getErrorMessage } from 'utils/schema/utils';
import { EmployeeType } from 'utils/enum/UserEnum';
import clsx from 'clsx';
import FieldsetTitle from 'components/widgets/fieldsetTitle/fieldsetTitle';
import If from 'components/widgets/conditional/If';
import CreateCustomer from 'components/modules/deals/create/dealTab/CreateCustomer';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import LabelMemorize from 'components/widgets/form/LabelMemorize';
import FormControlMemorize from 'components/widgets/form/FormControlMemorize';
import VehiclesByCustomerList from 'components/modules/service/serviceInvoice/create/VehiclesByCustomerList';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import FormControlNumberMemorize from 'components/widgets/form/FormControlNumberMemorize';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { DateFormat } from 'lib/DateUtils';
import CustomerForServiceList from 'components/modules/service/serviceInvoice/create/CustomerForServiceList';
import AddNewServiceVehicle from 'components/modules/service/serviceInvoice/create/AddNewServiceVehicle';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const ownStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
    dialogContent: {
        height: 600,
        padding: theme.spacing(1, 1.8),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    minWidthMainInfo: {
        minWidth: '400px',
        [theme.breakpoints.down('md')]: {
            minWidth: 50,
        },
    },
    timePickerCls: {
        padding: 0,
        width: '100%',
        '& > input': {
            height: '100%',
            border: 'none',
            fontSize: '0.875rem',
        },
    },
    noShow: {
        display: 'none',
    },
}));

const dataDiscount = [
    { label: 'RO Discount', value: 0 },
    { label: 'Labor Discount', value: 1 },
];

const keyStore = new KeyStore();

const ServiceMainInfo = (props) => {
    const {
        record, onChangeValue, errors,
        userList, editFields, containerWidth,
    } = props;

    const [openCustomerDialog, setOpenCustomerDialog] = useState(true);
    const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false);
    const [openNewVehicleDialog, setOpenNewVehicleDialog] = useState(false);
    const [openVehiclesByCustomerDialog, setOpenVehiclesByCustomerDialog] = useState(false);
    const SERVICE_EDIT_ADVISOR = keyStore.hasPermission(Permission.SERVICE_EDIT_ADVISOR);
    const columnWidth6 = containerWidth > 560 ? 6 : 12;

    const classes = {
        ...useStyles(),
        ...basicFormStyle(),
        ...ownStyles(),
    };

    const {
        divScrollable, compactForm, paddingTop15,
        removePaddingGroup, fontSmall, dialogContent,
        minWidthMainInfo,
    } = classes;

    const {
        editCustomer, editVehicle, editServiceAdvisor, editEstimateDelivery, editRef, editRentalCar, editSaveOldParts,
        editNotes, editWarrantyDeductable, editLaborDiscountOption, editLaborDiscount, editRoDiscount,
        editFee, editCancellationFee, editStorageFee, editCustomerWaiting,
    } = editFields;

    const {
        customerCode, customerName, vehicleStock, vehicleVin, vehicleMake, vehicleModel,
        vehicleYear, vehicleTrim, milesIn, milesOut, hatNumber, licensePlate,
        advisorId, userField1, quickNote, deposit, warranty, warrantyDeductable,
        shopSupplies, laborDiscount, discountPercentage, discount, fees,
        cancellationFee, storageFee, totalLabor, totalParts, totalSubletPrice, customerPay,
        tax, total, inHouse, stockNumber, saveParts, rentalCar, estimateDelivery, invoiceNumber,
        cellPhone, email, customerWaiting, overrideShopSuppliesFee,
    } = record;

    const advisorList = userList?.filter((item) => item.employeeType === EmployeeType.SERVICE_ADVISOR);

    const onMainInfoValueChange = (field, value) => {
        onChangeValue(field, value);
        if (field === 'advisorId') {
            const advisorName = advisorList.find((c) => c.userId === value);
            onChangeValue('advisor', advisorName?.user ?? '');
        }
    };

    const clearVehicleInfo = () => {
        onChangeValue('vehicleStock', 0);
        onChangeValue('vehicleVin', '');
        onChangeValue('vehicleMake', '');
        onChangeValue('vehicleModel', '');
        onChangeValue('vehicleYear', '');
        onChangeValue('vehicleTrim', '');
    };

    const onSetCustomer = (val) => {
        const {
            firstName, lastName, customerCode: cCode, isTaxable,
        } = val;
        if (cCode !== record.customerCode) clearVehicleInfo();
        onChangeValue('customerCode', cCode);
        onChangeValue('customerName', `${firstName} ${lastName}`);
        onChangeValue('isTaxable', inHouse ? false : isTaxable);
    };

    const onSelectVehicle = (val) => {
        const {
            make,
            model,
            stockNumber: sn,
            trim,
            vin,
            year,
        } = val;

        setOpenVehiclesByCustomerDialog(false);
        onChangeValue('vehicleStock', sn);
        onChangeValue('vehicleVin', vin);
        onChangeValue('vehicleMake', make);
        onChangeValue('vehicleModel', model);
        onChangeValue('vehicleYear', year);
        onChangeValue('vehicleTrim', trim);
    };

    const onAddNewVehicle = (data) => {
        onSetCustomer(data);
        setOpenCustomerDialog(false);
        setOpenNewVehicleDialog(true);
    };

    const onClickPrimaryDialog = (val) => {
        if (val.customer) onSetCustomer(val.customer);
        if (val.vehicle) onSelectVehicle(val.vehicle);
        setOpenCustomerDialog(false);
    };

    const clearCustomerInformation = () => {
        setOpenCustomerDialog(false);
        onChangeValue('customerCode', '');
        onChangeValue('customerName', '');
        clearVehicleInfo();
    };

    const clearVehicleInformation = () => {
        setOpenVehiclesByCustomerDialog(false);
        clearVehicleInfo();
    };

    useEffect(() => {
        if (record.invoiceNumber > 0) {
            setOpenCustomerDialog(false);
        }
    }, [record.invoiceNumber]);

    return (
        <>
            <Grid container spacing={0} className={clsx(divScrollable, compactForm, paddingTop15)}>
                <Col lg={12} className={minWidthMainInfo}>
                    <FieldsetTitle title="Customer">
                        <Form.Group as={Row}>
                            <LabelMemorize column lg={3} text="Customer" />
                            <Col lg={9}>
                                <InputGroup size="sm">
                                    <FormControlMemorize
                                        className={isValidField(errors, 'customerCode') ? 'invalid-field' : ''}
                                        value={customerName || ''}
                                        type="text"
                                        placeholder="Customer name"
                                        size="sm"
                                        disabled
                                        name="customerName"
                                    />
                                    {editCustomer && (
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => setOpenCustomerDialog(true)}
                                                disabled={!editCustomer}
                                            >
                                                Select
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={clearCustomerInformation}
                                                disabled={!editCustomer}
                                            >
                                                X
                                            </Button>
                                        </InputGroup.Append>
                                    ) }
                                </InputGroup>
                            </Col>
                            <Col lg={12}><LabelMemorize text={`Email: ${email ?? ''}. Cell phone: ${cellPhone ?? ''}`} /></Col>
                        </Form.Group>
                    </FieldsetTitle>
                    <FieldsetTitle title="Vehicle Information">
                        <Form as={Row}>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="Stock" />
                                    <Col lg={9}>
                                        <InputGroup size="sm">
                                            <FormControlMemorize
                                                value={vehicleStock || 0}
                                                type="text"
                                                placeholder="Stock #"
                                                size="sm"
                                                disabled
                                                name="vehicleStock"
                                            />
                                            {editVehicle && (
                                                <InputGroup.Append>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() => setOpenVehiclesByCustomerDialog(true)}
                                                        disabled={Number(customerCode || 0) === 0}
                                                    >
                                                        Select
                                                    </Button>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={clearVehicleInformation}
                                                        disabled={Number(customerCode || 0) === 0}
                                                    >
                                                        X
                                                    </Button>
                                                </InputGroup.Append>
                                            )}
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="VIN" />
                                    <Col lg={9}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('vehicleVin', e.target.value)}
                                            value={vehicleVin || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="vehicleVin"
                                            disabled={stockNumber > 0 || !editVehicle}
                                            className={isValidField(errors, 'vehicleVin') ? 'invalid-field' : ''}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="Make" />
                                    <Col lg={9}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('vehicleMake', e.target.value)}
                                            value={vehicleMake || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="vehicleMake"
                                            disabled={stockNumber > 0 || !editVehicle}
                                            className={isValidField(errors, 'vehicleMake') ? 'invalid-field' : ''}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="Model" />
                                    <Col lg={9}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('vehicleModel', e.target.value)}
                                            value={vehicleModel || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="vehicleModel"
                                            disabled={stockNumber > 0 || !editVehicle}
                                            className={isValidField(errors, 'vehicleModel') ? 'invalid-field' : ''}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="Year" />
                                    <Col lg={9}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('vehicleYear', e.target.value)}
                                            value={vehicleYear || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="vehicleYear"
                                            disabled={stockNumber > 0 || !editVehicle}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={3} text="Trim" />
                                    <Col lg={9}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('vehicleTrim', e.target.value)}
                                            value={vehicleTrim || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="vehicleTrim"
                                            disabled={stockNumber > 0 || !editVehicle}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Miles In" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="milesIn"
                                            size="sm"
                                            showCurrency={false}
                                            allowNegative={false}
                                            value={milesIn}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('milesIn', value)}
                                            alignRight
                                            disabled={!editVehicle}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Miles Out" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="milesOut"
                                            size="sm"
                                            showCurrency={false}
                                            allowNegative={false}
                                            value={milesOut}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('milesOut', value)}
                                            alignRight
                                            disabled={!editVehicle}
                                            className={isValidField(errors, 'milesOut') ? 'invalid-field' : ''}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Hat Number" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="hatNumber"
                                            size="sm"
                                            showCurrency={false}
                                            allowNegative={false}
                                            value={hatNumber}
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('hatNumber', value)}
                                            alignRight
                                            disabled={!editVehicle}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="License Plate" />
                                    <Col lg={7}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('licensePlate', e.target.value)}
                                            value={licensePlate || ''}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="licensePlate"
                                            disabled={!editVehicle}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form>
                    </FieldsetTitle>
                    <FieldsetTitle title="">
                        <Form as={Row} className={removePaddingGroup}>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Service Advisor" />
                                    <Col lg={7}>
                                        <DropdownQuery
                                            name="advisorId"
                                            className={isValidField(errors, 'advisorId') ? 'invalid-field' : ''}
                                            value={advisorId}
                                            placeHolder="select a advisor"
                                            onChange={(name, newValue) => onMainInfoValueChange(name, newValue)}
                                            dataSource={{
                                                localData: advisorList,
                                                idField: 'userId',
                                                descriptionField: 'user',
                                            }}
                                            allowEmptyLine={false}
                                            disabled={!editServiceAdvisor || (invoiceNumber > 0 && !SERVICE_EDIT_ADVISOR)}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Promise Time" />
                                    <Col lg={7}>
                                        <DatePicker
                                            size="sm"
                                            className={clsx('form-control form-control-sm')}
                                            selected={estimateDelivery}
                                            onChange={(date) => onChangeValue('estimateDelivery', date)}
                                            showTimeSelect
                                            timeFormat={DateFormat.DEFAULT_TIME}
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            minDate={new Date()}
                                            dateFormat={
                                                DateFormat
                                                    .DEFAULT_DATETIME_WITHOUT_SECONDS
                                                    .replace('DD', 'dd')
                                                    .replace('YYYY', 'yyyy')
                                            }
                                            popperPlacement="bottom-end"
                                            popperContainer={CalendarContainer}
                                            disabled={!editEstimateDelivery}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={columnWidth6 === 12 ? 12 : 3}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Rental Car" />
                                    <Col lg={5}>
                                        <Switch
                                            checked={Boolean(rentalCar)}
                                            color={rentalCar ? 'primary' : 'secondary'}
                                            onChange={(e) => onMainInfoValueChange('rentalCar', e.target.checked)}
                                            disabled={!editRentalCar}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={columnWidth6 === 12 ? 12 : 4}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Save old parts" />
                                    <Col lg={5}>
                                        <Switch
                                            checked={Boolean(saveParts)}
                                            color={saveParts ? 'primary' : 'secondary'}
                                            onChange={(e) => onMainInfoValueChange('saveParts', e.target.checked)}
                                            disabled={!editSaveOldParts}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={columnWidth6 === 12 ? 12 : 4}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={8} text="Customer waiting" />
                                    <Col lg={4}>
                                        <Switch
                                            checked={Boolean(customerWaiting)}
                                            color={customerWaiting ? 'primary' : 'secondary'}
                                            onChange={(e) => onMainInfoValueChange('customerWaiting', e.target.checked)}
                                            disabled={!editCustomerWaiting}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={2} text="REF" />
                                    <Col lg={10}>
                                        <FormControlMemorize
                                            onChange={(e) => onMainInfoValueChange('userField1', e.target.value)}
                                            value={userField1}
                                            type="text"
                                            placeholder=""
                                            size="sm"
                                            name="userField1"
                                            disabled={!editRef}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group as={Col}>
                                    <LabelMemorize text="Notes" />
                                    <FormControlMemorize
                                        as="textarea"
                                        className={isValidField(errors, 'quickNote') ? 'invalid-field' : ''}
                                        size="sm"
                                        rows="2"
                                        value={quickNote || ''}
                                        placeholder="Notes"
                                        name="quickNote"
                                        onChange={(e) => onMainInfoValueChange('quickNote', e.target.value)}
                                        disabled={!editNotes}
                                    />
                                    <p className={fontSmall}>{getErrorMessage('quickNote', errors)}</p>
                                </Form.Group>
                            </Col>
                        </Form>
                    </FieldsetTitle>
                    <FieldsetTitle title="Amount Information">
                        <Form as={Row}>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Deposit" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="deposit"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={deposit}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('deposit', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Warranty" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="warranty"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={warranty}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('warranty', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Warranty Deductable" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="warrantyDeductable"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={warrantyDeductable}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('warrantyDeductable', value)}
                                            alignRight
                                            disabled={!editWarrantyDeductable}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Shop Supplies" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="shopSupplies"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={shopSupplies}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('shopSupplies', value)}
                                            alignRight
                                            disabled={!overrideShopSuppliesFee}
                                        />
                                        <Form.Check
                                            id="overrideShopSuppliesId"
                                            checked={overrideShopSuppliesFee}
                                            type="checkbox"
                                            label="Override"
                                            onChange={(e) => onChangeValue('overrideShopSuppliesFee', e.target.checked)}
                                            // disabled={editWarrantyDeductable}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Fee" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="fees"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={fees}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('fees', value)}
                                            alignRight
                                            disabled={!editFee}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Cancellation Fee" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="cancellationFee"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={cancellationFee}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('cancellationFee', value)}
                                            alignRight
                                            disabled={!editCancellationFee}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={7} text="Storage Fee" />
                                    <Col lg={5}>
                                        <FormControlNumberMemorize
                                            name="storageFee"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={storageFee}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('storageFee', value)}
                                            alignRight
                                            disabled={!editStorageFee}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} lg={columnWidth6}>
                                {!inHouse && (
                                    <Form.Group as={Row}>
                                        <Col lg={7}>
                                            <DropdownQuery
                                                name="laborDiscount"
                                                className={isValidField(errors, 'laborDiscount') ? 'invalid-field' : ''}
                                                value={laborDiscount ? 1 : 0}
                                                onChange={(name, newValue) => onMainInfoValueChange(name, newValue === 1)}
                                                dataSource={{
                                                    localData: dataDiscount,
                                                    idField: 'value',
                                                    descriptionField: 'label',
                                                }}
                                                allowEmptyLine={false}
                                                disabled={!editLaborDiscountOption}
                                            />
                                        </Col>
                                        <Col lg={5}>
                                            {laborDiscount && (
                                                <FormControlNumberMemorize
                                                    name="discountPercentage"
                                                    size="sm"
                                                    allowNegative
                                                    value={discountPercentage}
                                                    onChange={(value) => onMainInfoValueChange('discountPercentage', value)}
                                                    alignRight
                                                    symbol="%"
                                                    showCurrency
                                                    disabled={!editLaborDiscount}
                                                />
                                            )}
                                            {!laborDiscount && (
                                                <FormControlNumberMemorize
                                                    name="discount"
                                                    size="sm"
                                                    showCurrency
                                                    allowNegative
                                                    value={discount}
                                                    thousandSeparator
                                                    onChange={(value) => onMainInfoValueChange('discount', value)}
                                                    alignRight
                                                    disabled={!editRoDiscount}
                                                />
                                            )}
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Total Labor" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="totalLabor"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={totalLabor}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('totalLabor', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Total Parts" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="totalParts"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={totalParts}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('totalParts', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Total Sublet" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="totalSubletPrice"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={totalSubletPrice}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('totalSubletPrice', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className={classes.noShow}>
                                    <LabelMemorize column lg={5} text="Customer Pay" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="customerPay"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={customerPay}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('customerPay', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Tax" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="tax"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={tax}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('tax', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <LabelMemorize column lg={5} text="Total" />
                                    <Col lg={7}>
                                        <FormControlNumberMemorize
                                            name="total"
                                            size="sm"
                                            showCurrency
                                            allowNegative
                                            value={total}
                                            thousandSeparator
                                            placeholder="0"
                                            onChange={(value) => onMainInfoValueChange('total', value)}
                                            alignRight
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form>
                    </FieldsetTitle>
                </Col>
            </Grid>
            <If condition={openCustomerDialog}>
                <CustomerForServiceList
                    inHouse={inHouse}
                    onClose={() => setOpenCustomerDialog(false)}
                    onSelectCustomer={onClickPrimaryDialog}
                    showAddNewCustomerButton
                    onClickNewCustomerButton={() => { setOpenCustomerDialog(false); setOpenNewCustomerDialog(true); }}
                    onClickNewVehicleButton={(data) => onAddNewVehicle(data)}
                />
            </If>
            <If condition={openNewCustomerDialog}>
                <Dialog
                    open={openNewCustomerDialog}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogAppBar
                        title="Create new customer"
                        onClose={() => setOpenNewCustomerDialog(false)}
                    />
                    <DialogContent className={dialogContent}>
                        <CreateCustomer toggleCreateCustomer={() => setOpenNewCustomerDialog(false)} onSetCustomer={onSetCustomer} />
                    </DialogContent>
                </Dialog>
            </If>
            <If condition={openVehiclesByCustomerDialog}>
                <VehiclesByCustomerList
                    code={inHouse ? customerName : customerCode}
                    inHouse={inHouse}
                    onClose={() => setOpenVehiclesByCustomerDialog(false)}
                    onSelectVehicle={onSelectVehicle}
                    onClickNewVehicleButton={() => { setOpenVehiclesByCustomerDialog(false); setOpenNewVehicleDialog(true); }}
                />
            </If>
            <If condition={openNewVehicleDialog}>
                <AddNewServiceVehicle
                    onAddVehicle={(vehicleData) => {
                        onSelectVehicle({
                            ...vehicleData,
                            year: String(vehicleData.year),
                            stockNumber: 0,
                        });
                        setOpenNewVehicleDialog(false);
                    }}
                    onPopupClose={() => setOpenNewVehicleDialog(false)}
                />
            </If>
        </>
    );
};

ServiceMainInfo.propTypes = {
    record: PropTypes.object,
    onChangeValue: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifierJobsMainInfo: PropTypes.string,
    userList: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.array,
    editFields: PropTypes.object,
    containerWidth: PropTypes.number.isRequired,
};

ServiceMainInfo.defaultProps = {
    record: {},
    identifierJobsMainInfo: '',
    userList: [],
    errors: {},
    editFields: {},
};

const areEqual = (prevProps, nextProps) => prevProps.identifierJobsMainInfo === nextProps.identifierJobsMainInfo
    && prevProps.containerWidth === nextProps.containerWidth;

export default React.memo(ServiceMainInfo, areEqual);
