/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
import * as yup from 'yup';
import { DealType, FinanceDefaultCalculation } from 'utils/enum/DealEnum';

const CreateDealFinanceSchema = yup.object().shape({
    dealType: yup.string().required(),
    leadSource: yup.string().required(),
    financeCompany: yup.string().required(),
    buyRate: yup.number().moreThan(0).required(),
    buyer: yup.object().shape({
        customerId: yup.string().required(),
    }),
    vehicle: yup.object().shape({
        stockNumber: yup.number().required(),
    }),
    salesPrice: yup.number().required().test(
        'required',
        'Sales Price must be greater than Minimum price.',
        function (salesPrice) {
            const { vehicle } = this.parent;
            const { minimumSalePrice } = { ...vehicle };

            return (salesPrice >= minimumSalePrice) || (salesPrice > 0 && !minimumSalePrice);
        },
    ),
    paymentAmount: yup.number().required().test(
        'required',
        'Payment Amount must be greater than zero.',
        function (paymentAmount) {
            const { dealType, defaultFinanceCalculation } = this.parent;

            return (dealType === DealType.FINANCE || dealType === DealType.BHPH || dealType === DealType.OUTSIDE_FINANCE)
                && ((defaultFinanceCalculation !== FinanceDefaultCalculation.TERM.toLowerCase()
                && paymentAmount > 0) || defaultFinanceCalculation === FinanceDefaultCalculation.TERM.toLowerCase());
        },
    ),
    term: yup.number().required().test(
        'required',
        'Term must be greater than zero.',
        function (term) {
            const { dealType, defaultFinanceCalculation } = this.parent;

            return (dealType === DealType.FINANCE || dealType === DealType.BHPH || dealType === DealType.OUTSIDE_FINANCE)
                && ((defaultFinanceCalculation === FinanceDefaultCalculation.TERM.toLowerCase()
                && term > 0) || defaultFinanceCalculation !== FinanceDefaultCalculation.TERM.toLowerCase());
        },
    ),
});

const CreateDealCashSchema = yup.object().shape({
    dealType: yup.string().required(),
    leadSource: yup.string().required(),
    vehicle: yup.object().shape({
        stockNumber: yup.number().required(),
    }),
    buyer: yup.object().shape({
        customerId: yup.string().required(),
    }),
    salesPrice: yup.number().required().test(
        'required',
        'Sales Price must be greater than Minimum price.',
        function (salesPrice) {
            const { vehicle } = this.parent;
            const { minimumSalePrice } = { ...vehicle };

            return (salesPrice >= minimumSalePrice) || (salesPrice > 0 && !minimumSalePrice);
        },
    ),
});

const CreateDealLeaseSchema = yup.object().shape({
    dealType: yup.string().required(),
    leadSource: yup.string().required(),
    financeCompany: yup.string().required(),
    buyer: yup.object().shape({
        customerId: yup.string().required(),
    }),
    vehicle: yup.object().shape({
        stockNumber: yup.number().required(),
    }),
    salesPrice: yup.number().required().test(
        'required',
        'Sales Price must be greater than Minimum price.',
        function (salesPrice) {
            const { vehicle } = this.parent;
            const { minimumSalePrice } = { ...vehicle };

            return (salesPrice >= minimumSalePrice) || (salesPrice > 0 && !minimumSalePrice);
        },
    ),
    cashDownPayment: yup.number().moreThan(0).required(),
});

const BHPHSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().moreThan(0).required(),
    financeAmount: yup.number().moreThan(0).required(),
    financeCompany: yup.string().required(),
    interestRate: yup.number().moreThan(0).required(),
    regZAPR: yup.number().moreThan(0).required(),
    buyRate: yup.number().moreThan(0).required(),
    term: yup.number().moreThan(0).required(),
    paymentFrequency: yup.string().required(),
    firstPaymentDue: yup.date().required(),
    paymentAmount: yup.number().moreThan(0).required(),
    salesTax: yup.number().required(),
    taxableAmount: yup.number().required(),
});

const LeaseSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().moreThan(0).required(),
    financeCompany: yup.string().required(),
    interestRate: yup.number().moreThan(0).required(),
    buyRate: yup.number().moreThan(0).required(),
    term: yup.number().moreThan(0).required(),
    paymentFrequency: yup.string().required(),
    firstPaymentDue: yup.date().required(),
    paymentAmount: yup.number().moreThan(0).required(),
    salesTax: yup.number().required(),
    cashDownPayment: yup.number().moreThan(0).required(),
});

const FinanceSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().moreThan(0).required(),
    financeAmount: yup.number().moreThan(0).required(),
    financeCompany: yup.string().required(),
    interestRate: yup.number().moreThan(0).required(),
    regZAPR: yup.number().moreThan(0).required(),
    buyRate: yup.number().moreThan(0).required(),
    term: yup.number().moreThan(0).required(),
    paymentFrequency: yup.string().required(),
    firstPaymentDue: yup.date().required(),
    paymentAmount: yup.number().moreThan(0).required(),
    salesTax: yup.number().required(),
    taxableAmount: yup.number().required(),
});

const DraftSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().min(0).required(),
    financeAmount: yup.number().moreThan(0).required(),
    financeCompany: yup.string().required(),
    interestRate: yup.number().moreThan(0).required(),
    regZAPR: yup.number().moreThan(0).required(),
    buyRate: yup.number().moreThan(0).required(),
    term: yup.number().moreThan(0).required(),
    paymentFrequency: yup.string().required(),
    firstPaymentDue: yup.date().required(),
    paymentAmount: yup.number().moreThan(0).required(),
    salesTax: yup.number().required(),
    taxableAmount: yup.number().required(),
});

const CashSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().min(0).required(),
    financeCompany: yup.string().required().test(
        'required',
        'Finance Company must be Cash.',
        (financeCompany) => financeCompany === 'CASH',
    ),
});

const WholesaleSchema = yup.object().shape({
    dealType: yup.string().required(),
    soldDate: yup.date().required(),
    salesPrice: yup.number().required(),
    balanceDue: yup.number().min(0).required(),
    financeCompany: yup.string().required().test(
        'required',
        'Finance Company must be Cash.',
        (financeCompany) => financeCompany === 'CASH',
    ),
});

export {
    CreateDealFinanceSchema,
    CreateDealCashSchema,
    BHPHSchema,
    FinanceSchema,
    DraftSchema,
    CashSchema,
    WholesaleSchema,
    LeaseSchema,
    CreateDealLeaseSchema,
};
