import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Grid, Button,
} from '@material-ui/core';
import { FetchPolicy } from 'utils/enum/Core';
import { useApolloClient } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import LotsCategory from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import ButtonStyles from 'styles/theme/Button';
import UserContext from 'components/context/UserContext';
import VirtualTable from 'components/widgets/VirtualTable';
import InputNumber from 'components/widgets/InputNumber';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    title: {
        fontSize: '12px',
        marginLeft: '15px',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '300px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            height: '400px',
            '& > div': {
                minWidth: '600px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
}));

const DeskingSettings = ({ canWrite }) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation } = useContext(UserContext);
    const [state, setState] = useState({
        lots: [],
        settings: [],
    });

    const { DESKING_DEAL_FEES, DESKING_DEAL_TAX_RATE } = InventoryCategory;
    const pullDeskingSettingsPerLot = async ({ lotName }) => {
        try {
            const { data: settingsData } = await client.query({
                query: LotQuery.GET_SETTINGS,
                variables: {
                    category: LotsCategory.INVENTORY,
                    lotName,
                    key: [DESKING_DEAL_FEES, DESKING_DEAL_TAX_RATE],
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const settings = settingsData?.getSettings;
            if (settings) {
                const { lots } = state;
                const lot = lots.find((el) => el.lotName === lotName);
                if (lot) {
                    setState((prevState) => ({
                        ...prevState,
                        settings: [...prevState.settings, {
                            lotName,
                            [DESKING_DEAL_FEES]: Number(settings.find((setting) => setting.key === DESKING_DEAL_FEES)?.value || 0),
                            [DESKING_DEAL_TAX_RATE]: Number(settings.find((setting) => setting.key === DESKING_DEAL_TAX_RATE)?.value || 0),
                        }],
                    }));
                }
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    useEffect(() => {
        if (userInformation) {
            const lots = userInformation?.lots;
            if (lots && lots.length > 0) {
                const sortedLots = cloneDeep(lots).sort((a, b) => a.lotName.localeCompare(b.lotName));
                setState((prevState) => ({
                    ...prevState,
                    lots: sortedLots,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        if (state.lots.length > 0) ArrayUtils.processPromisesInSequence(state.lots, pullDeskingSettingsPerLot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lots]);

    const updateSetting = (lotName, property, value) => {
        const clone = cloneDeep(state.settings);
        const setting = clone.find((el) => el.lotName === lotName);
        if (setting) setting[property] = value;

        setState((prevState) => ({
            ...prevState,
            settings: clone,
        }));
    };

    const saveSettings = async (lotName) => {
        const { settings } = state;
        const setting = settings.find((el) => el.lotName === lotName);

        if (setting) {
            try {
                const input = [
                    {
                        value: String(setting[DESKING_DEAL_FEES]),
                        key: DESKING_DEAL_FEES,
                        lotName,
                    },
                    {
                        value: String(setting[DESKING_DEAL_TAX_RATE]),
                        key: DESKING_DEAL_TAX_RATE,
                        lotName,
                    },
                ];

                const { data } = await client.mutate({
                    mutation: InventoryMutation.SAVE_INVENTORY_SETTINGS,
                    variables: {
                        input,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                });

                if (data) ModalUtils.successMessage(null, 'Settings saved successfully');
            } catch (error) {
                ModalUtils.errorMessage(null, error.message);
            }
        }
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'lotName',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.lotName?.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Fees',
            dataKey: 'fees',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <InputNumber
                        onChange={(newValue) => updateSetting(record.lotName, DESKING_DEAL_FEES, newValue)}
                        showCurrency
                        allowNegative={false}
                        thousandSeparator
                        size="sm"
                        value={record[DESKING_DEAL_FEES]}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Tax Rate (%)',
            dataKey: 'taxRate',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <InputNumber
                        onChange={(newValue) => updateSetting(record.lotName, DESKING_DEAL_TAX_RATE, newValue)}
                        showCurrency={false}
                        allowNegative={false}
                        size="sm"
                        decimalScale={2}
                        value={record[DESKING_DEAL_TAX_RATE]}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <Button
                        disabled={!canWrite}
                        className={classes.containedSecondaryInfo}
                        size="small"
                        onClick={() => saveSettings(record.lotName)}
                    >
                        Save
                    </Button>
                );
            },
        },
    ];

    return (
        <Grid container className={classes.box}>
            <Grid item lg={11} xs={12}>
                <div className={classes.title}>
                    Lots&apos; Fees & Tax Rate
                </div>
            </Grid>
            {state.settings.length > 0 && (
                <Grid item lg={11} xs={12}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={false}
                            rowHeight={45}
                            totalRecords={state.settings.length}
                            data={state.settings}
                            columns={getColumns()}
                        />
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

DeskingSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default DeskingSettings;
