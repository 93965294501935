import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles, Button, Grid, Chip, useTheme, useMediaQuery, Tooltip,
} from '@material-ui/core';
import Container from 'components/widgets/Container';
import ModalUtils from 'utils/ModalUtils';
import { useMutation, useQuery } from '@apollo/client';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { useHistory, useParams } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import {
    AccountingSubModules, AccountReceivableTransactionType,
    AccountsReceivableEntities, CashierDirectionOperation,
    CashierTakePaymentStatus, GeneralAction,
} from 'utils/enum/AccountingEnum';
import {
    EditOutlinedIcon, LocalAtmIcon,
    BlockOutlinedIcon, PrintOutlinedIcon, RepeatOutlinedIcon,
} from 'components/icons';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import LabelValue from 'components/widgets/LabelValue';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import AccountingUtils from 'utils/AccountingUtils';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import { Form, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import printJS from 'print-js';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    SET_TABLE: 'setTableRecords',
    SET_PAGING: 'setPaging',
    SET_INITIAL_STATE: 'setInitialState',
    SET_PARAMS: 'setParams',
    LOAD_MORE: 'setLoadMore',
    SET_STATE_VALUES: 'setStateValues',
};

const cashierPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE:
        return { ...state, table: action.value };
    case ACTION_TYPES.SET_PAGING:
        return { ...state, paginate: { ...state.paginate, init: action.value } };
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    default:
        return state;
    }
};

const keyStore = new KeyStore();

const CashierPaymentList = (props) => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS);
    const ACCOUNTING_ACCOUNT_RECEIVABLE_EDIT_PAYMENTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_EDIT_PAYMENTS);
    const ACCOUNTING_ACCOUNT_RECEIVABLE_VOID_PAYMENTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_VOID_PAYMENTS);
    const ASSIGN_PAYMENT = keyStore.hasPermission(Permission.ASSIGN_PAYMENT);

    const history = useHistory();
    const { entity } = useParams();

    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { accountNumber } = props;

    const initialState = {
        params: {
            paginate: {
                init: 0,
                limit: 25,
            },
            search: null,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        dataFromSubscription: null,
        idToAction: 0,
        actionType: '',
        reasonRequired: false,
        actionMessage: null,
    };

    const [state, dispatch] = useReducer(cashierPaymentReducer, initialState);

    const {
        params, table, idToAction, dataFromSubscription, reasonRequired, actionType,
    } = state;

    const comeFromOtherCollection = entity === AccountsReceivableEntities.OTHER_COLLECTIONS;
    const comeFromPettyCash = entity === AccountsReceivableEntities.PETTY_CASH;

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: { idToAction: 0, actionType: '' },
        });
    };

    const {
        data, loading, error, refetch, subscribeToMore,
    } = useQuery(AccountReceivableQuery.GET_CASHIER_PAYMENT_BY_PROCESS_LIST,
        {
            variables: {
                paginate: params.paginate,
                filter: {
                    accountNumber: (comeFromOtherCollection || comeFromPettyCash) ? 0 : accountNumber,
                    transactionType: AccountingUtils.getTransactionType(entity),
                    search: params.search,
                },
                sort: [{ fieldName: 'transactionNumber', dir: DataSort.DESC }],
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const [printCashierReceipt, { loading: printing }] = useMutation(AccountReceivableQuery.PRINT_CASHIER_RECEIPT, {
        onCompleted: (mutationData) => {
            if (mutationData?.printCashierReceipt) {
                onCloseActionDialog();

                printJS({
                    printable: mutationData?.printCashierReceipt,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const onSearch = (text) => {
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const [voidPayment, { loading: voiding }] = useMutation(AccountReceivableMutation.VOID_CASHIER_PAYMENT, {
        onCompleted: (mutationData) => {
            if (mutationData?.voidChashierPayment) {
                ModalUtils.successMessage(null, 'Successfully void!');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onActionClick = (transactionNumber, action) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: { idToAction: transactionNumber, actionType: action },
        });
    };

    const onPrintReceipt = (receiptId) => {
        const currentDate = new Date();

        printCashierReceipt({
            variables: {
                transactionNumber: receiptId,
                printDate: DateUtils.format(`${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`, DateFormat.DATETIME_WITHOUT_SECONDS),
            },
        });
    };

    const onActionConfirm = useCallback(async () => {
        if (idToAction === 0) return;
        if (actionType === GeneralAction.VOID) {
            const reasonMemoVoid = document.getElementById('commentToVoidId').value;
            if (isEmpty(reasonMemoVoid)) {
                document.getElementById('commentToVoidId').focus();
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: { reasonRequired: true },
                });
                return;
            }

            await voidPayment({
                variables: {
                    transactionNumber: idToAction,
                    comment: reasonMemoVoid,
                },
            });
        }
    }, [actionType, idToAction, voidPayment]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getCashierPaymentList } = data;

            const mapRecords = AccountReceivablesMapper.mapCashierPayments(getCashierPaymentList.data);

            records.push(...mapRecords);
            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getCashierPaymentList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        if (accountNumber > 0 || comeFromOtherCollection || comeFromPettyCash) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records: [],
                    totalCount: 0,
                },
            });
            refetch();
        }
    }, [accountNumber, comeFromOtherCollection, comeFromPettyCash, refetch]);

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: AccountingSubscription.CASHIER_PAYMENT_CHANGED,
            updateQuery: (prev, { subscriptionData }) => {
                dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { dataFromSubscription: { ...subscriptionData.data.cashierPaymentChanged } } });
            },
        });

        return () => unsubscribe();
    }, [subscribeToMore]);

    useEffect(() => {
        if (dataFromSubscription?.id > 0) {
            const { action, data: subsData } = dataFromSubscription;
            if (action === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                if ((accountNumber && Number(accountNumber) === Number(currentData.accountNumber)
                && currentData.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase())
                    || (comeFromOtherCollection && currentData.transactionType === AccountReceivableTransactionType.OTHER
                        && currentData.direction.toLowerCase() === CashierDirectionOperation.IN.toLowerCase())
                    || (comeFromPettyCash && currentData.direction.toLowerCase() === CashierDirectionOperation.OUT.toLowerCase())
                ) {
                    const processedData = AccountReceivablesMapper.mapCashierPayments([currentData]);
                    records.unshift(...processedData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records,
                            totalCount: totalCount + 1,
                        },
                        dataFromSubscription: null,
                    });
                }
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);
                const processedData = AccountReceivablesMapper.mapCashierPayments([currentData]);
                const currentIndex = currentRecords.findIndex((item) => Number(item.transactionNumber) === Number(currentData.transactionNumber));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, ...processedData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                        },
                        dataFromSubscription: null,
                    });
                }
            } else if (action === SubscriptionActionType.DELETED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);
                const currentIndex = currentRecords.findIndex((item) => Number(item.transactionNumber) === Number(currentData.transactionNumber)
                && Number(item.accountNumber) === Number(currentData.accountNumber));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                        },
                        dataFromSubscription: null,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromSubscription]);

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Receipt #',
            minWidth: 90,
            width: 90,
            id: 'TransactionNumber',
            accessor: 'transactionNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Amount',
            minWidth: 100,
            width: 100,
            id: 'Amount',
            accessor: 'amount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Date',
            minWidth: 100,
            width: 100,
            id: 'Date',
            accessor: 'date',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.format(value),
        },
        {
            Header: 'Customer',
            minWidth: 160,
            width: 160,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Reason',
            minWidth: 150,
            width: 159,
            id: 'Reason',
            accessor: 'reason',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Memo',
            minWidth: 250,
            id: 'Memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'User',
            minWidth: 150,
            width: 150,
            id: 'UserName',
            accessor: 'userName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Lotname',
            minWidth: 150,
            width: 150,
            id: 'LotName',
            accessor: 'lotName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            minWidth: 120,
            width: 120,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status.toLowerCase() === CashierTakePaymentStatus.DEPOSITED.toLowerCase()
                || status.toLowerCase() === CashierTakePaymentStatus.PARTIALLY_DEPOSIT.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === CashierTakePaymentStatus.VOIDED.toLowerCase()) badgeStatus = classes.badgeVoid;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Actions',
            width: 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, classes.hidden, 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        transactionNumber, voided, status, transactionType,
                    },
                } = cellData;
                const module = modules.ACCOUNTING;
                const subModule = AccountingSubModules.ACCOUNTS_RECEIVABLE;
                const newEntity = AccountingUtils.getEntityByTransactionType(transactionType);
                let linkRef = `/${module}/${subModule}/${newEntity}/${accountNumber}/payment/${transactionNumber}`;
                if (comeFromOtherCollection) linkRef = `/${module}/${subModule}/${newEntity}/payment/${transactionNumber}`;
                else if (comeFromPettyCash) linkRef = `/${module}/${subModule}/${newEntity}/payout/${transactionNumber}`;

                return (
                    <div className={classes.buttonSpacing}>
                        {ACCOUNTING_ACCOUNT_RECEIVABLE_EDIT_PAYMENTS && (
                            <Button
                                onClick={() => history.push(linkRef)}
                                variant="outlined"
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                Edit
                            </Button>
                        )}
                        {status.toLowerCase() === CashierTakePaymentStatus.ACTIVE.toLowerCase()
                        && ACCOUNTING_ACCOUNT_RECEIVABLE_VOID_PAYMENTS && (
                            <Button
                                onClick={() => onActionClick(transactionNumber, GeneralAction.VOID)}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonWarning} />}
                                size="small"
                            >
                                Void
                            </Button>
                        )}
                        {status.toLowerCase() !== CashierTakePaymentStatus.VOIDED.toLowerCase()
                        && ASSIGN_PAYMENT && (
                            <Button
                                onClick={() => {
                                    history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${newEntity}/assign/${transactionNumber}`);
                                }}
                                variant="outlined"
                                startIcon={<RepeatOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                Assign
                            </Button>
                        )}
                        {!voided
                        && (
                            <Button
                                onClick={() => onPrintReceipt(transactionNumber)}
                                variant="outlined"
                                startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                                size="small"
                            >
                                Print
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {!comeFromOtherCollection && !comeFromPettyCash
                ? (
                    <Table
                        rowSelected
                        cursor="default"
                        load={loading}
                        loadMore={loadMore}
                        totalRecords={table.totalCount}
                        data={table?.records}
                        columns={columns}
                        className="-highlight actionsInLine"
                    />
                ) : (
                    <div className={classes.main}>
                        <Header>
                            <div className={classes.containerFilterSearch}>
                                <InputSearch
                                    customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                                    initialSearch={params.search || ''}
                                    onSearch={onSearch}
                                />
                                <LabelValue
                                    label="Items"
                                    value={table.totalCount}
                                />
                            </div>
                            <div className={classes.buttonSpacing}>
                                {comeFromOtherCollection && ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS
                    && (
                        <Tooltip title="Take Payment" placement="top-start">
                            <Button
                                onClick={() => {
                                    dispatch({ type: ACTION_TYPES.SET_NEW });
                                    history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${entity}/payment`);
                                }}
                                variant="outlined"
                                startIcon={<LocalAtmIcon />}
                                size="small"
                            >
                                Take Payment
                            </Button>
                        </Tooltip>
                    )}
                                {comeFromPettyCash && ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS
                    && (
                        <Tooltip title="New Payout" placement="top-start">
                            <Button
                                onClick={() => {
                                    dispatch({ type: ACTION_TYPES.SET_NEW });
                                    history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${entity}/payout`);
                                }}
                                variant="outlined"
                                startIcon={<LocalAtmIcon />}
                                size="small"
                            >
                                New Payout
                            </Button>
                        </Tooltip>
                    )}
                            </div>
                        </Header>
                        <Container className={classes.containerSplit}>
                            <Table
                                data={table?.records}
                                columns={columns}
                                cursor="default"
                                load={loading}
                                totalRecords={table?.totalCount}
                                loadMore={loadMore}
                                rowSelected
                                className="-highlight actionsInLine"
                            />
                        </Container>
                    </div>
                )}
            <ConfirmDialog
                title={`${actionType} Payment #${idToAction}`}
                description={`Are you sure you want to ${actionType} this payment?`}
                open={idToAction > 0 && !(printing || voiding)}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseActionDialog}
                onClickSecondary={onCloseActionDialog}
                onClickPrimary={onActionConfirm}
            >
                {actionType === GeneralAction.VOID && (
                    <Grid item xs={12}>
                        <Form.Group as={Col} className={classes.voidPaymentInput}>
                            <Form.Control
                                id="commentToVoidId"
                                as="textarea"
                                size="sm"
                                rows="2"
                                className={reasonRequired ? 'invalid-field' : ''}
                                placeholder="Reason to Void"
                            />
                        </Form.Group>
                    </Grid>
                )}
            </ConfirmDialog>
            {printing && <DialogActionMessage message="Printing... " />}
            {voiding && <DialogActionMessage message="Voiding payment... " />}
        </>
    );
};

CashierPaymentList.propTypes = {
    accountNumber: PropTypes.number,
};

CashierPaymentList.defaultProps = {
    accountNumber: 0,
};

export default CashierPaymentList;
