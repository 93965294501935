/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-default */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import clsx from 'clsx';
import moment from 'moment';
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {
    makeStyles, Select,
    MenuItem, Checkbox,
    ListItemText, FormControl,
    InputLabel, Button,
    Dialog, DialogContent,
    Tooltip,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { useTheme } from '@material-ui/core/styles';
import { FetchPolicy } from 'utils/enum/Core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonStyles from 'styles/theme/Button';
import ChartStyle from 'styles/modules/businessIntelligence/ChartStyle';
import TableUtils from 'utils/TableUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import ArrayUtils from 'lib/ArrayUtils';
import GeneralUtils from 'utils/GeneralUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import {
    CHART_COLUMN_DATA_TYPE,
    AGGREGATE_FUNCTIONS,
    NUMERIC_CONDITIONAL,
    PRINTABLE_TYPE,
    STYLE_COMPONENT,
} from 'utils/enum/BusinessIntelligenceEnum';
import {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    PAGE_FORMAT,
    PAGE_ORIENTATION,
} from 'utils/enum/General';
import DatePicker from 'react-datepicker';
import GeneralQuery from 'services/graphQL/query/GeneralQuery';
import InputNumber from 'components/widgets/InputNumber';
import { default as SelectWidget } from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import FormattingForm from 'components/widgets/businessIntelligence/FormattingForm';
import PivotTableWrapper from 'components/widgets/businessIntelligence/PivotTableWrapper';
import LoadedColumnsList from 'components/widgets/businessIntelligence/LoadedColumnsList';
import PivotTableInputContent from 'components/widgets/businessIntelligence/PivotTableInputContent';

// icons
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { PrintOutlinedIcon } from 'components/icons';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ChartStyle.content(theme));

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 150,
        },
    },
    getContentAnchorEl: null,
};

const chartTypes = BIHelper.getChartType();
const Chart = React.memo(({
    label,
    tableWidth,
    height,
    type,
    query,
    totalRecords,
    data,
    options,
    loadMore,
    loadingMoreData,
    defaultDateValues,
    pullQueryResultsWithFilters,
    inSettings,
    updateChartOptions,
}) => {
    const theme = useTheme();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        label,
        height,
        type,
        data: [],
        options,
        filters: [],
        localChartId: TableUtils.generateUUID(),
        pivotTableKey: null,
        isDataDialogOpen: false,
        dataPreview: [],
        runningQuery: false,
        pivotShapedData: [],
        isReorderingItems: false,
        isFormattingFormOpen: false,
        formattingData: [],
    });

    const {
        options: { input: inputInState },
        options: optionsInState,
        pivotShapedData,
        formattingData,
    } = state;

    const [printDocument, { loading: printingDocument }] = useLazyQuery(GeneralQuery.PRINT_DOCUMENT, {
        onCompleted: (response) => {
            const result = response.printDocument;
            if (result) {
                if (result?.data?.startsWith('Request failed')) {
                    ModalUtils.errorMessage(null, 'Error printing report');
                    return;
                }

                const {
                    documentType,
                    data: documentOutput,
                } = result;

                if (documentType === PRINTING_DOCUMENT_TYPE.PDF) {
                    BIHelper.printChart(PRINTABLE_TYPE.PDF, {
                        url: documentOutput,
                    });
                }

                if (documentType === PRINTING_DOCUMENT_TYPE.SPREADSHEET) {
                    const name = `${state.label}-${DateUtils.format(new Date(), DateFormat.SHORT_DATE_WITH_DASHES)}.xlsx`;
                    GeneralUtils.downloadFile(documentOutput, name);
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const applyFilters = (filters, freshData) => {
        if (state.options && state.data.length > 0 && (filters ?? state.filters).length > 0) {
            let clone = cloneDeep(state.data);

            state
                .options
                .filters
                .forEach(({ column, dataType }) => {
                    const current = (filters || state.filters).find((e) => e.column === column);
                    if (dataType === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC && current && current.value?.length > 0) {
                        clone = clone.filter((row) => {
                            const val = row.find((el) => el.name === column)?.value;
                            return current.value.some((e) => e?.toLowerCase() === val?.toLowerCase());
                        });
                    }

                    if (dataType === CHART_COLUMN_DATA_TYPE.DATE && current && Object.keys(current?.value || {}).length === 2) {
                        const { startDate, endDate } = current.value;
                        const defaultDates = (defaultDateValues ?? []).find((item) => item.column === column);

                        if (!defaultDates) {
                            clone = clone.filter((row) => {
                                const val = row.find((el) => el.name === column)?.value;
                                return (DateUtils.isSameOrAfter(val, startDate) || false) && (DateUtils.isSameOrBefore(val, endDate) || false);
                            });
                        }
                    }

                    if (dataType === CHART_COLUMN_DATA_TYPE.NUMERIC && Object.keys(current?.value || {}).includes('number')) {
                        const { conditional = NUMERIC_CONDITIONAL.EQUAL, number } = current.value;

                        clone = clone.filter((row) => {
                            const val = row.find((el) => el.name === column)?.value;

                            switch (conditional) {
                            case NUMERIC_CONDITIONAL.EQUAL:
                                return Number(val) === number;
                            case NUMERIC_CONDITIONAL.GREATER_EQUAL_THAN:
                                return Number(val) >= number;
                            case NUMERIC_CONDITIONAL.LOWER_EQUAL_THAN:
                                return Number(val) <= number;
                            default:
                                return false;
                            }
                        });
                    }
                });

            return clone;
        }

        return freshData ?? state.data;
    };

    const isTable = state.type === 'Table';
    const isPivotTable = isTable && state.options.input?.find((prop) => prop.name === 'PivotTable')?.value === true;
    useEffect(() => {
        const style = state.options?.style;
        const styleProperty = (style ?? []).find((x) => x.name === STYLE_COMPONENT.CONDITIONAL_FORMATTING)?.value;

        setState((prevState) => ({
            ...prevState,
            runningQuery: false,
            ...(isPivotTable ? {
                pivotTableKey: TableUtils.generateUUID(),
                pivotShapedData: BIHelper.shapePivotTableData(applyFilters(null, data), inputInState),
                formattingData: styleProperty ?? [],
            } : {}),
            data,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const removeFilterOptions = (column, dataType) => {
        const clone = cloneDeep(state.filters);
        const filter = clone.find((el) => el.column === column);

        if (filter && dataType === CHART_COLUMN_DATA_TYPE.NUMERIC) {
            delete filter.value?.number;
        } else if (filter) {
            filter.value = [];
        }

        setState({
            ...state,
            filters: clone,
            ...(isPivotTable ? { pivotShapedData: BIHelper.shapePivotTableData(applyFilters(clone), inputInState) } : {}),
        });
    };

    const updateFilter = (column, selection, dataType, opts) => {
        const clone = cloneDeep(state.filters);
        const filter = clone.find((el) => el.column === column);

        let toSave = null;
        switch (dataType) {
        case CHART_COLUMN_DATA_TYPE.DATE:
        case CHART_COLUMN_DATA_TYPE.NUMERIC:
            toSave = {
                ...(filter?.value || {}),
                ...(
                    !opts.property
                        ? opts.properties.reduce((a, b) => ({ ...a, [b.name]: b.value }), {})
                        : { [opts.property]: selection }
                ),
            };
            break;
        default:
            toSave = selection;
            break;
        }

        if (filter) {
            filter.value = toSave;
        } else {
            clone.push({
                column,
                value: toSave,
            });
        }

        setState({
            ...state,
            filters: clone,
            ...(isPivotTable ? { pivotShapedData: BIHelper.shapePivotTableData(applyFilters(clone), inputInState) } : {}),
        });
    };

    const filteredData = applyFilters();
    const toggleDialog = (dataPointIndex) => {
        let dataPreview = [];
        if (dataPointIndex >= 0) {
            let column = null; let val = null; let sorted = null;
            const { input } = state.options;

            switch (state.type) {
            case 'Line':
            case 'Horizontal Bar':
            case 'Vertical Bar':
                const category = input.find((el) => ['X-Axis', 'Y-Axis'].includes(el.name));
                column = category?.value;

                // eslint-disable-next-line no-use-before-define
                sorted = getAllCategoriesSorted(category);
                val = sorted[dataPointIndex];

                break;
            case 'Pie':
            case 'Donut':
                const labels = input.find((el) => el.name === 'Labels');
                column = labels?.value;

                // eslint-disable-next-line no-use-before-define
                sorted = getAllCategoriesSorted(labels);
                val = sorted[dataPointIndex];

                break;
            default:
                break;
            }

            dataPreview = cloneDeep(filteredData)
                .filter((row) => {
                    const current = row.find((el) => el.name === column);
                    return current?.value?.toLowerCase() === val?.toLowerCase();
                });
        }

        setState((prevState) => ({
            ...prevState,
            isDataDialogOpen: !state.isDataDialogOpen,
            dataPreview,
        }));
    };

    const orginizeDataBySeries = (series, input) => {
        const map = new Map();
        input.forEach((row) => {
            let serieName = row.find((e) => e.name === series.value)?.value;

            if (serieName && serieName !== 'null') {
                serieName = StringUtils.toPascalCase(serieName.toLowerCase());
                const serieRows = map.get(serieName);
                if (!serieRows) map.set(serieName, [row]);
                if (serieRows) serieRows.push(row);
            }
        });

        return new Map([...map].sort());
    };

    const getAllCategoriesSorted = (category) => {
        const categories = [
            ...new Set(
                filteredData.map((row) => StringUtils.toPascalCase((row.find((el) => el.name === category.value)?.value || '').toLowerCase())),
            ),
        ].filter((cat) => !StringUtils.isEmpty(cat) && cat?.toLowerCase() !== 'null');

        const isDate = category.dataType === CHART_COLUMN_DATA_TYPE.DATE;
        return categories.sort((a, b) => (!isDate ? a.localeCompare(b) : moment(a) - moment(b)));
    };

    const getAggregateFunctionResult = (input, cat, category, values) => {
        let output = null;
        const income = input
            .filter((row) => row.find((el) => el.name === category.value && el.value?.toLowerCase() === cat.toLowerCase()) != null)
            .map((row) => {
                const value = Number(row.find((el) => el.name === values.value)?.value);
                // eslint-disable-next-line no-restricted-globals
                return isNaN(value) ? 0 : value;
            });

        switch (values.function) {
        case AGGREGATE_FUNCTIONS.AVG:
            output = NumberUtils.round(income.reduce((a, b) => a + b, 0) / income.length);
            break;
        case AGGREGATE_FUNCTIONS.COUNT:
            output = income.length;
            break;
        case AGGREGATE_FUNCTIONS.MAX:
            output = income.length > 0 ? Math.max(...income) : 0;
            break;
        case AGGREGATE_FUNCTIONS.MIN:
            output = income.length > 0 ? Math.min(...income) : 0;
            break;
        case AGGREGATE_FUNCTIONS.SUM:
            output = income.reduce((a, b) => a + b, 0);
            break;
        default:
            output = 0;
            break;
        }

        return output;
    };

    const generateSerieData = (input, category, values) => {
        const categories = getAllCategoriesSorted(category);
        if (values.aggregate) {
            return categories.map((cat) => ({
                x: cat,
                y: getAggregateFunctionResult(input, cat, category, values),
            }));
        }

        return categories.map((cat) => {
            const current = input.find((row) => row.find((el) => el.name === category.value && el.value?.toLowerCase() === cat.toLowerCase()) != null);
            return {
                x: cat,
                y: Number(current ? current.find((el) => el.name === values.value)?.value : 0),
            };
        });
    };

    const generateSeries = (input, category, values) => {
        if (input instanceof Map) {
            const series = [];
            input.forEach((value, key) => {
                series.push({
                    name: key,
                    data: generateSerieData(value, category, values),
                });
            });

            return series;
        }

        return [{
            name: values.value,
            data: generateSerieData(input, category, values),
        }];
    };

    const getSeries = () => {
        if (state.options && filteredData.length > 0) {
            const { input } = state.options;

            try {
                switch (state.type) {
                case 'Line':
                case 'Horizontal Bar':
                case 'Vertical Bar':
                    const category = input.find((el) => ['X-Axis', 'Y-Axis'].includes(el.name));
                    const values = input.find((el) => el.name === 'Values');
                    const series = input.find((el) => el.name === 'Series');

                    if (category.value && category.dataType && values.value && values.dataType && values.dataType === CHART_COLUMN_DATA_TYPE.NUMERIC) {
                        if (series.value && series.dataType) {
                            const sorted = orginizeDataBySeries(series, filteredData);
                            const generatedSeries = generateSeries(sorted, category, values);
                            return generatedSeries;
                        }

                        const generatedSeries = generateSeries(filteredData, category, values);
                        return generatedSeries;
                    }

                    break;
                case 'Pie':
                case 'Donut':
                    const vals = input.find((el) => el.name === 'Values');
                    const labels = input.find((el) => el.name === 'Labels');

                    if (labels.value && labels.dataType && vals.value && vals.dataType && vals.dataType === CHART_COLUMN_DATA_TYPE.NUMERIC) {
                        const sorted = orginizeDataBySeries(labels, filteredData);
                        const generatedSeries = generateSeries(sorted, labels, vals);
                        return {
                            labels: generatedSeries.map((s) => s.name),
                            values: generatedSeries.map((s) => s.data.reduce((a, b) => a + b.y, 0)),
                        };
                    }

                    break;
                default:
                    break;
                }
            } catch (error) {
                return null;
            }
        }

        return null;
    };

    const numericFormattingFormat = '0,0.[00]';
    const getOptions = () => {
        if (state.options && filteredData.length > 0) {
            try {
                const { input, style } = state.options;
                const values = input.find((el) => el.name === 'Values');
                const xAxis = style.find((el) => el.name === 'X-Axis');
                const yAxis = style.find((el) => el.name === 'Y-Axis');
                const stroke = style.find((el) => el.name === 'Stroke');
                const markers = style.find((el) => el.name === 'Markers');
                const dataLabels = style.find((el) => el.name === 'Data Labels');
                const legend = style.find((el) => el.name === 'Legend');
                const tooltip = style.find((el) => el.name === 'Tooltip');
                const grid = style.find((el) => el.name === 'Grid');
                const stacked = style.find((el) => el.name === 'Stacked');
                const annotations = style.find((el) => el.name === 'Annotations');

                const xAxisTitle = xAxis?.title || input.find((e) => e.name === xAxis?.titleDefault?.propertyName)?.[xAxis?.titleDefault?.target];
                const yAxisTitle = yAxis?.title || input.find((e) => e.name === yAxis?.titleDefault?.propertyName)?.[yAxis?.titleDefault?.target];
                const commonProperties = {
                    chart: {
                        id: state.localChartId,
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            dynamicAnimation: {
                                enabled: true,
                            },
                        },
                        events: {
                            dataPointSelection: (event, chartContext, config) => {
                                if (event.button !== 0) return;

                                const { dataPointIndex } = config;
                                toggleDialog(dataPointIndex);
                            },
                        },
                    },
                    theme: {
                        mode: 'light',
                        palette: 'palette1',
                    },
                    title: {
                        text: state.label || 'Chart',
                        align: 'center',
                    },
                    dataLabels: {
                        enabled: dataLabels.enabled,
                        style: {
                            fontSize: '11px',
                        },
                        formatter: (value) => NumberUtils.applyThousandsFormat(value, numericFormattingFormat),
                    },
                    legend: {
                        show: legend.enabled,
                        position: legend.position,
                        offsetY: 5,
                    },
                    tooltip: {
                        enabled: tooltip.enabled,
                        shared: true,
                        intersect: false,
                        followCursor: false,
                        y: {
                            formatter: (value) => NumberUtils.applyThousandsFormat(value, numericFormattingFormat),
                        },
                    },
                    grid: {
                        show: grid?.enabled,
                        position: grid?.position,
                        xaxis: {
                            lines: {
                                show: grid?.['showX-Axis'],
                            },
                        },
                        yaxis: {
                            lines: {
                                show: grid?.['showY-Axis'],
                            },
                        },
                    },
                    xaxis: {
                        position: xAxis?.position,
                        title: {
                            text: xAxisTitle,
                        },
                        type: 'category',
                        labels: {
                            offsetY: 3,
                        },
                    },
                    yaxis: {
                        show: true,
                        title: {
                            text: `${yAxisTitle}${values.aggregate ? ` (${values.function.toLowerCase()})` : ''}`,
                        },
                    },
                };

                let clone = null;
                const annotationsData = annotations?.value;
                const annotationProperty = annotationsData?.length > 0 ? {
                    annotations: {
                        yaxis: annotationsData
                            .filter((el) => el.axisValue != null && el.lineColor != null)
                            .map((el) => ({
                                y: el.axisValue,
                                borderColor: el.lineColor,
                                ...(el.text ? {
                                    label: {
                                        borderWidth: 0.5,
                                        borderRadius: 0,
                                        text: el.text,
                                        style: {
                                            padding: {
                                                left: 2,
                                                right: 2,
                                                top: 0,
                                                bottom: 2,
                                            },
                                        },
                                    },
                                } : {}),
                            })),
                    },
                } : {};

                switch (state.type) {
                case 'Line':
                    return {
                        ...commonProperties,
                        tooltip: {
                            ...commonProperties.tooltip,
                            shared: false,
                            intersect: true,
                        },
                        stroke: {
                            show: true,
                            curve: stroke.curve,
                            lineCap: stroke.lineCap,
                            width: stroke.width,
                        },
                        markers: {
                            size: markers.size,
                            strokeWidth: markers.strokeWidth,
                            shape: markers.shape,
                        },
                        ...annotationProperty,
                    };
                case 'Horizontal Bar':
                case 'Vertical Bar':
                    clone = cloneDeep(commonProperties);
                    clone.chart = { ...clone.chart, stacked: stacked.enabled };

                    const isHorizontalBar = type === 'Horizontal Bar';
                    return {
                        ...clone,
                        yaxis: {
                            ...clone.yaxis,
                            ...(!isHorizontalBar ? {
                                tickAmount: 6,
                            } : {}),
                        },
                        dataLabels: {
                            ...clone.dataLabels,
                            ...(!isHorizontalBar && !stacked.enabled ? {
                                offsetY: -17,
                                style: {
                                    ...clone.dataLabels.style,
                                    colors: ['#707090'],
                                },
                            } : {}),
                        },
                        plotOptions: {
                            bar: {
                                horizontal: isHorizontalBar,
                                columnWidth: '95%',
                                ...(!isHorizontalBar && !stacked.enabled ? {
                                    dataLabels: {
                                        position: 'top',
                                    },
                                } : {}),
                            },
                        },
                        ...annotationProperty,
                    };
                case 'Pie':
                case 'Donut':
                    clone = cloneDeep(commonProperties);
                    delete clone.grid;
                    delete clone.xaxis;
                    delete clone.yaxis;
                    delete clone.dataLabels.style.fontSize;

                    return {
                        ...clone,
                        plotOptions: {
                            pie: {
                                customScale: 0.9,
                                expandOnClick: true,
                                donut: {
                                    size: '50%',
                                },
                            },
                        },
                    };
                default:
                    break;
                }
            } catch (error) {
                return null;
            }
        }

        return null;
    };

    const generateFilterOptions = (column, dataType, index, input) => {
        const { filters: currentFilters, options: { filters: settingsFilters } } = state;

        let filtered = null;
        if (index > 0) {
            const selectedFilters = [];
            for (let x = 0; x < index; x += 1) {
                const col = settingsFilters[x]?.column;
                const currentFilter = currentFilters.find((el) => el.column === col);
                if (
                    currentFilter
                    && (
                        (dataType === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC && currentFilter?.value?.length > 0)
                        || (
                            (dataType === CHART_COLUMN_DATA_TYPE.DATE || dataType === CHART_COLUMN_DATA_TYPE.NUMERIC)
                            && Object.keys(currentFilter?.value || {}).length === 2
                        )
                    )
                ) {
                    selectedFilters.push(currentFilter);
                }
            }

            filtered = applyFilters(selectedFilters);
        } else {
            filtered = input;
        }

        if (dataType === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC) {
            const opts = [
                ...new Set(
                    filtered
                        .map((row) => StringUtils.toPascalCase((row.find((el) => el.name === column)?.value || '').toLowerCase()))
                        .filter((val) => !StringUtils.isEmpty(val) && val?.toLowerCase() !== 'null')
                        .sort((a, b) => a.localeCompare(b)),
                ),
            ];

            const currentFilter = currentFilters.find((el) => el.column === column);
            if (currentFilter && currentFilter.value) currentFilter.value = currentFilter.value.filter((v) => opts.includes(v));
            return opts;
        }

        if (dataType === CHART_COLUMN_DATA_TYPE.DATE) {
            const dates = filtered
                .map((row) => row.find((el) => el.name === column)?.value)
                .filter((date) => !StringUtils.isEmpty(date) && date !== 'null');

            const defaultDates = (defaultDateValues ?? []).find((item) => item.column === column);
            const minDate = defaultDates ? new Date(defaultDates.defaultStartDate) : DateUtils.getMinDate(dates)?.toDate();
            const maxDate = defaultDates ? new Date(defaultDates.defaultEndDate) : DateUtils.getMaxDate(dates)?.toDate();

            const currentFilter = currentFilters.find((el) => el.column === column);
            if (!currentFilter) {
                updateFilter(column, null, dataType, { properties: [{ name: 'startDate', value: minDate }, { name: 'endDate', value: maxDate }] });
            }

            return {
                minDate,
                maxDate,
            };
        }

        return null;
    };

    const rerunQuery = (filter) => {
        setState((prevState) => ({
            ...prevState,
            runningQuery: true,
        }));

        pullQueryResultsWithFilters(filter);
    };

    const renderFilter = ({ column, dataType }, input, key) => {
        let filter = null;
        let values = null;
        let component = null;

        switch (dataType) {
        case CHART_COLUMN_DATA_TYPE.ALPHANUMERIC:
            filter = state.filters.find((el) => el.column === column);
            values = generateFilterOptions(column, dataType, key, input);

            component = (
                <div key={key}>
                    <div
                        onClick={() => removeFilterOptions(column, dataType)}
                        className={classes.remover}
                    >
                        <HighlightOffOutlinedIcon />
                    </div>
                    <FormControl variant="outlined">
                        <InputLabel>{column}</InputLabel>
                        <Select
                            multiple
                            value={filter?.value || []}
                            onChange={({ target: { value } }) => updateFilter(column, value)}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {values.map((v, index) => (
                                <MenuItem className={classes.filterOption} key={index} value={v}>
                                    <Checkbox checked={filter ? filter.value.includes(v) : false} />
                                    <ListItemText primary={v} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            );

            break;
        case CHART_COLUMN_DATA_TYPE.DATE:
            filter = state.filters.find((el) => el.column === column);
            values = generateFilterOptions(column, dataType, key, input);
            const defaultDates = (defaultDateValues ?? []).find((item) => item.column === column);

            const currentStartDate = filter?.value?.startDate || values.minDate;
            const currentEndDate = filter?.value?.endDate || values.maxDate;
            component = (
                <div key={key} className={classes.dateParent}>
                    <FormControl variant="outlined">
                        <InputLabel className="dateLabel">{`${column}: Start Date`}</InputLabel>
                        <DatePicker
                            allowSameDay
                            selected={currentStartDate}
                            size="sm"
                            className={clsx('form-control form-control-sm')}
                            popperContainer={CalendarContainer}
                            maxDate={filter?.value?.endDate || values.maxDate}
                            {...(!defaultDates ? { minDate: values.minDate } : {})}
                            onChange={(date) => updateFilter(column, date, dataType, { property: 'startDate' })}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel className="dateLabel">{`${column}: End Date`}</InputLabel>
                        <DatePicker
                            allowSameDay
                            selected={currentEndDate}
                            size="sm"
                            className={clsx('form-control form-control-sm')}
                            popperContainer={CalendarContainer}
                            {...(!defaultDates ? { maxDate: values.maxDate } : {})}
                            minDate={filter?.value?.startDate || values.minDate}
                            onChange={(date) => updateFilter(column, date, dataType, { property: 'endDate' })}
                        />
                    </FormControl>
                    {defaultDates && (
                        <Button
                            className={classes.containedSecondaryInfo}
                            size="small"
                            onClick={() => rerunQuery({
                                column,
                                dataType,
                                currentStartDate: DateUtils.getUSFormattedDateFromUTC(currentStartDate, DateFormat.DEFAULT_DATE),
                                currentEndDate: DateUtils.getUSFormattedDateFromUTC(currentEndDate, DateFormat.DEFAULT_DATE),
                            })}
                        >
                            Go
                        </Button>
                    )}
                </div>
            );

            break;
        case CHART_COLUMN_DATA_TYPE.NUMERIC:
            filter = state.filters.find((el) => el.column === column);

            component = (
                <div key={key}>
                    <FormControl variant="outlined">
                        <InputLabel className="numericLabel">{column}</InputLabel>
                        <div className="numericWrapper">
                            <SelectWidget
                                nowrap
                                size="sm"
                                loading={false}
                                name=""
                                className={classes.input}
                                onChange={(_, value) => updateFilter(column, value, dataType, { property: 'conditional' })}
                                value={filter?.value?.conditional || NUMERIC_CONDITIONAL.EQUAL}
                                options={
                                    Object.values(NUMERIC_CONDITIONAL)
                                        .map((f) => ({
                                            value: f,
                                            label: f,
                                        }))
                                }
                            />
                            <InputNumber
                                value={filter?.value?.number}
                                onChange={
                                    (newValue) => (
                                        newValue === 0
                                            ? removeFilterOptions(column, dataType)
                                            : updateFilter(column, newValue, dataType, { property: 'number' })
                                    )
                                }
                                allowNegative
                                decimalScale={2}
                                size="sm"
                            />
                        </div>
                    </FormControl>
                </div>
            );

            break;
        default:
            break;
        }

        return component;
    };

    const renderFilters = () => {
        if (state.options && state.data.length > 0) {
            const { filters } = state.options;

            if (filters.length > 0) {
                return (
                    <div className={classes.filtersParent}>
                        {filters.map((filter, index) => renderFilter(filter, state.data, index))}
                    </div>
                );
            }
        }

        return <div className={classes.filtersParent} />;
    };

    const getFlatTableColumns = () => {
        if (state.options && filteredData.length > 0) {
            const { input } = state.options;
            const property = input.find((el) => el.name === 'Columns');
            if (property?.value) return property.value;
        }

        return [];
    };

    const getPivotColumns = () => [];

    const getColumnValues = (col, tableData) => tableData.reduce((a, b) => {
        const dat = b.find((x) => x.name === col);
        if (dat) a.push(dat);
        return a;
    }, []).map((x) => x.value);

    const isNumericColumn = (col, tableData) => {
        const result = BIHelper.getColumnDataType(col, tableData);
        return result.type === CHART_COLUMN_DATA_TYPE.NUMERIC;
    };

    const formatCalculatedTotal = (values) => NumberUtils.applyThousandsFormat(values.reduce((a, b) => a + Number(b ?? 0), 0), numericFormattingFormat);
    const calculateTableTotals = (tableData, wrapValue = true) => {
        const input = state.options?.input;
        if (!input) return { modifiedData: tableData };

        const style = state.options?.style;
        if (!style) return { modifiedData: tableData };

        const inputProperty = input.find((x) => x.name === 'Columns');
        const columns = inputProperty?.value;
        if (!columns) return { modifiedData: tableData };

        const styleProperty = style.find((x) => x.name === STYLE_COMPONENT.DISPLAY_TOTALS);
        const isTotalCalculated = styleProperty?.value ?? false;
        if (!isTotalCalculated) return { modifiedData: tableData };

        const columnsWithTotal = [];
        const totalsRow = [];
        const { columnsSkipped } = styleProperty;
        columns.forEach((c, index) => {
            const isNumeric = isNumericColumn(c, tableData);
            const isSkipped = (columnsSkipped || []).includes(c) || !isNumeric;

            if (isSkipped) {
                if (index === 0) totalsRow.push({ name: c, value: wrapValue ? (<span className={classes.totalTitle}>Total</span>) : 'Total' });
                if (index > 0) totalsRow.push({ name: c, value: null });
            } else {
                columnsWithTotal.push(c);
                const values = getColumnValues(c, tableData);
                totalsRow.push(
                    {
                        name: c,
                        value: wrapValue ? (
                            <span className={classes.numericColumn}>
                                {formatCalculatedTotal(values)}
                            </span>
                        ) : formatCalculatedTotal(values),
                    },
                );
            }
        });

        return { modifiedData: columnsWithTotal.length > 0 ? [...tableData, totalsRow] : tableData, columnsWithTotal };
    };

    const printChart = async (documentType) => {
        const { isDataDialogOpen, dataPreview } = state;

        if (isTable || isDataDialogOpen) {
            const columns = isDataDialogOpen
                ? dataPreview[0].map((el) => el.name)
                : getFlatTableColumns();

            const filters = [];
            (state.filters ?? []).forEach(({
                column,
                value,
            }) => {
                const dataType = (state.options?.filters ?? []).find((e) => e.column === column)?.dataType ?? CHART_COLUMN_DATA_TYPE.ALPHANUMERIC;
                const isAlphanumeric = dataType === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC;
                const isNumeric = dataType === CHART_COLUMN_DATA_TYPE.NUMERIC;
                const isDate = dataType === CHART_COLUMN_DATA_TYPE.DATE;

                if (
                    (isAlphanumeric && value?.length > 0)
                    || (isNumeric && value?.number)
                    || (isDate && value?.startDate && value?.endDate)
                ) {
                    const currentFilterInOptions = (options?.filters ?? []).find((f) => f.column === column);

                    filters.push({
                        column,
                        dataType,
                        ...(isAlphanumeric ? {
                            alphanumericValue: value,
                        } : {}),
                        ...(isNumeric ? {
                            numericCondition: value.conditional ?? NUMERIC_CONDITIONAL.EQUAL,
                            numericValue: value.number,
                        } : {}),
                        ...(isDate ? {
                            dateValue: {
                                startDate: DateUtils.getUSFormattedDateFromUTC(value.startDate, DateFormat.DEFAULT_DATE),
                                endDate: DateUtils.getUSFormattedDateFromUTC(value.endDate, DateFormat.DEFAULT_DATE),
                                startDateVariable: currentFilterInOptions ? currentFilterInOptions.startDate : null,
                                endDateVariable: currentFilterInOptions ? currentFilterInOptions.endDate : null,
                            },
                        } : {}),
                    });
                }
            });

            printDocument({
                variables: {
                    title: label,
                    source: {
                        content: query,
                        type: PRINTING_DOCUMENT_SOURCE_TYPE.QUERY,
                        availableColumns: columns,
                        queryValidation: true,
                        options: {
                            pageFormat: PAGE_FORMAT.LETTER,
                            paseOrientation: PAGE_ORIENTATION.LANDSCAPE,
                            border: 15,
                        },
                    },
                    type: documentType,
                    filters,
                },
            });

            return;
        }

        const chart = ApexCharts.getChartByID(state.localChartId);
        const { imgURI } = await chart.dataURI();

        BIHelper.printChart(PRINTABLE_TYPE.IMAGE, {
            url: imgURI,
            label: '',
        });
    };

    const updateChartInput = (propertyName, propertyData) => {
        const clone = cloneDeep(optionsInState);

        const property = clone.input.find((p) => p.name === propertyName);
        if (property) {
            const { value, aggregation, replacement } = propertyData;

            if (replacement) {
                property.value = value;
            } else {
                const columns = property.value;
                if (propertyName === 'PivotValues') {
                    const record = columns.find((item) => item.name === propertyData.value);
                    if (!record) {
                        columns.push({
                            name: propertyData.value,
                            dataType: propertyData.dataType,
                            aggregation: propertyData.dataType === CHART_COLUMN_DATA_TYPE.NUMERIC ? AGGREGATE_FUNCTIONS.AVG : AGGREGATE_FUNCTIONS.COUNT,
                        });
                    } else {
                        record.aggregation = aggregation;
                    }
                } else if (!columns.includes(propertyData.value)) columns.push(propertyData.value);
            }
        }

        setState((prevState) => ({
            ...prevState,
            options: clone,
            ...(isPivotTable ? { pivotShapedData: BIHelper.shapePivotTableData(filteredData, clone.input) } : {}),
        }));
    };

    const prepareItem = (event) => {
        setState((prevState) => ({
            ...prevState,
            isReorderingItems: true,
        }));

        const section = event.target.getAttribute('data-item-section');
        const field = JSON.parse(event.target.getAttribute('data-item-property'));
        const oldItemIndex = Number(event.target.getAttribute('data-item-index') || 0);
        event.dataTransfer.setData('text', JSON.stringify({ section, field, oldItemIndex }));
    };

    const reorderItems = (event, newItemIndex) => {
        if (!state.isReorderingItems) return;
        setState((prevState) => ({
            ...prevState,
            isReorderingItems: false,
        }));

        const { section, field, oldItemIndex } = JSON.parse(event.dataTransfer.getData('text'));
        if (section && field && oldItemIndex >= 0) {
            const itemsSorted = arrayMove(
                field.value,
                oldItemIndex,
                newItemIndex,
            );

            updateChartInput(field.name, { value: itemsSorted, replacement: true });
        }
    };

    const removeColumn = (index, propertyName) => {
        const clone = cloneDeep(state.options);
        const property = clone.input.find((p) => p.name === propertyName);
        property.value = property.value.filter((_, idx) => idx !== index);

        setState((prevState) => ({
            ...prevState,
            options: clone,
            ...(isPivotTable ? { pivotShapedData: BIHelper.shapePivotTableData(filteredData, clone.input) } : {}),
        }));
    };

    const pivotTableAvailableColumns = isPivotTable
        ? (inputInState.find((el) => el.name === 'Columns')?.value ?? []).map((item) => ({ name: item.column }))
        : [];
    const handleDrop = (event, field) => {
        event.preventDefault();
        if (state.isReorderingItems) return;

        const { index, type: dataType } = JSON.parse(event.dataTransfer.getData('text'));
        const columnIndex = Number(index);

        if (columnIndex !== Number.NaN) {
            const columnName = pivotTableAvailableColumns[columnIndex]?.name;
            const propertyName = field?.name;

            if (
                isPivotTable
                && (inputInState ?? [])
                    .filter((item) => ['PivotRows', 'PivotColumns', 'PivotValues'].includes(item.name))
                    .reduce((a, b) => [...b.value, ...a], []).includes(columnName)
            ) {
                ModalUtils.errorMessage(null, 'Selected column already added');
                return;
            }

            if (
                isPivotTable
                && ['PivotRows', 'PivotColumns'].includes(propertyName)
                && (dataType === CHART_COLUMN_DATA_TYPE.DATE || dataType === CHART_COLUMN_DATA_TYPE.FLAG)
            ) {
                ModalUtils.errorMessage(null, 'For rows and columns only alphanumeric and numeric data type allowed');
                return;
            }

            updateChartInput(propertyName, { value: columnName, dataType });
        }
    };

    const getCellCustomStyle = (col, currentCellValue) => {
        const style = state.options?.style;
        if (!style) return null;

        const conditionalFormatting = style.find((x) => x.name === STYLE_COMPONENT.CONDITIONAL_FORMATTING)?.value;
        if (!conditionalFormatting) return null;

        const conditions = conditionalFormatting.filter((x) => x.column === col);
        if (!ArrayUtils.isNotEmpty(conditions)) return null;

        let selectedCondition = null;
        for (let index = 0; index < conditions.length; index += 1) {
            const {
                backgroundColor,
                bold,
                condition,
                fontColor,
                fontSize,
                value,
            } = conditions[index];

            const passed = BIHelper.evaluateCondition(condition, currentCellValue, value);
            if (passed) {
                selectedCondition = {
                    ...(fontColor ? { color: fontColor } : {}),
                    ...(backgroundColor ? { backgroundColor } : {}),
                    ...(fontSize ? { fontSize } : {}),
                    ...(bold ? { fontWeight: 'bold' } : {}),
                };

                break;
            }
        }

        return selectedCondition;
    };

    const onSaveFormat = (records) => {
        if (inSettings) updateChartOptions('style', 'Conditional Formatting', { value: records });

        setState((previousState) => ({
            ...previousState,
            isFormattingFormOpen: false,
            formattingData: records,
        }));
    };

    const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const renderTable = (tableData, isDataPreview = false) => {
        let verticalWidth = 64;
        if (isLaptop) verticalWidth = 85;
        if (isMobile) verticalWidth = 70;

        const { modifiedData, columnsWithTotal } = calculateTableTotals(tableData);
        const columnsNames = !isDataPreview
            ? (isPivotTable ? getPivotColumns() : getFlatTableColumns())
            : modifiedData[0].map((el) => StringUtils.toPascalCase(el.name));
        const columns = columnsNames.map((col) => {
            const style = window.getComputedStyle(document.body);
            const fontFamily = style.getPropertyValue('font-family');

            const colWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(col, `bold 12px ${fontFamily}`) || 0) + 40;
            return {
                label: col,
                dataKey: col,
                width: colWidth,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;
                    const value = record.find((e) => e.name?.toLowerCase() === col.toLowerCase())?.value;
                    const customStyle = getCellCustomStyle(col, value);
                    const wasTotalCalculated = ArrayUtils.isNotEmpty(columnsWithTotal) && columnsWithTotal.includes(col) && !isNaN(value);
                    const isHTML = !wasTotalCalculated && StringUtils.isHTML(value);

                    if (isHTML && !isPivotTable) {
                        return (
                            <div
                                className={classes.text}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: value }}

                            />
                        );
                    }

                    return (
                        <div
                            className={classes.text}
                            {...(customStyle ? { style: customStyle } : {})}
                        >
                            {
                                value === 'null'
                                    ? ''
                                    : (wasTotalCalculated ? NumberUtils.applyThousandsFormat(value ?? 0, numericFormattingFormat) : value)
                            }
                        </div>
                    );
                },
            };
        });

        const parentWidth = !isDataPreview
            ? (tableWidth ?? 0)
            : (document.body.clientWidth * verticalWidth) / 100;

        let columnsWidth = columns.reduce((a, b) => a + b.width, 0);
        if (parentWidth > columnsWidth) columnsWidth = parentWidth - 5;

        return (
            <div
                id={state.localChartId}
                {...(!isDataPreview ? { style: { height: state.height } } : {})}
                className={clsx(classes.tableContainer, classes.extraStyle, isDataPreview ? classes.preview : '')}
            >
                {printingDocument && (
                    <div className={classes.printing}>
                        <span>Generating Report...</span>
                    </div>
                )}
                {state.runningQuery && (
                    <div className={classes.printing}>
                        <span>Pulling Data...</span>
                    </div>
                )}
                <VirtualTable
                    loading={loadingMoreData}
                    rowHeight={45}
                    totalRecords={totalRecords && totalRecords > state.data.length ? totalRecords : modifiedData.length}
                    data={modifiedData}
                    columns={columns}
                    width={columnsWidth}
                    loadMore={loadMore}
                />
            </div>
        );
    };

    const isPieDonut = ['Pie', 'Donut'].includes(state.type);
    const chart = chartTypes.find((el) => el.name === state.type);
    const series = isTable ? [] : getSeries();
    const opts = isTable ? {} : getOptions();
    const missingConfiguration = !state.type
        || !series
        || !opts
        || !chart;

    const pivotRows = isPivotTable ? inputInState?.find((item) => item.name === 'PivotRows')?.value : [];
    const pivotColumns = isPivotTable ? inputInState?.find((item) => item.name === 'PivotColumns')?.value : [];
    const pivotValues = isPivotTable ? inputInState?.find((item) => item.name === 'PivotValues')?.value : [];
    const pivotDefaultColumns = isPivotTable ? inputInState?.find((item) => item.name === 'Columns')?.value : [];
    return (
        <div className={clsx(classes.chartWrapper, isTable ? classes.noBorder : '')}>
            {renderFilters()}
            {filteredData.length === 0 && (
                <>
                    <div className={classes.tableLabel}>{state.label}</div>
                    <div className="message">No data to display</div>
                </>
            )}
            {missingConfiguration && filteredData.length > 0 && (
                <div className="message">{!isTable ? 'Check the chart configuration' : 'Check the table configuration'}</div>
            )}
            {!missingConfiguration && filteredData.length > 0 && (
                <div className={clsx('options', isTable ? 'tableStyle' : '')}>
                    {!isPivotTable && (
                        <Button
                            disabled={printingDocument}
                            variant="outlined"
                            startIcon={<PrintOutlinedIcon />}
                            size="small"
                            onClick={() => printChart(PRINTING_DOCUMENT_TYPE.PDF)}
                        />
                    )}
                    {isTable && !isPivotTable && (
                        <Button
                            disabled={printingDocument}
                            variant="outlined"
                            startIcon={<CloudDownloadOutlinedIcon />}
                            size="small"
                            onClick={() => printChart(PRINTING_DOCUMENT_TYPE.SPREADSHEET)}
                        />
                    )}
                    {isTable && (
                        <>
                            <div>
                                {`Total Records: ${loadMore ? (totalRecords ?? filteredData.length) : filteredData.length}`}
                            </div>
                            {loadMore && (
                                <>
                                    <div>
                                        {`Records Displayed in Table: ${filteredData.length}`}
                                    </div>
                                    {(totalRecords ?? 0) > state.data.length && (
                                        <div className={classes.paginationMessage}>
                                            To load all records scroll to the bottom of the report.
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
            {!missingConfiguration && filteredData.length > 0 && !isTable && (
                <ReactApexChart
                    type={chart.libType}
                    series={isPieDonut ? series.values : series}
                    options={isPieDonut ? { ...opts, labels: series.labels } : opts}
                    width="98%"
                    height={state.height}
                />
            )}
            {!missingConfiguration && filteredData.length > 0 && isTable && !isPivotTable && (
                <>
                    <div className={classes.tableLabel}>{state.label}</div>
                    {renderTable(filteredData)}
                </>
            )}
            {!missingConfiguration && isPivotTable && state.runningQuery && (
                <div className={classes.printing}>
                    <span>Pulling Data...</span>
                </div>
            )}
            {!missingConfiguration && isPivotTable && (
                <>
                    <div className={classes.tableLabel}>
                        {state.label}
                        <div className={classes.formatting}>
                            {pivotValues.length > 0 && (
                                <Tooltip title="Numeric Format" placement="top-end">
                                    <Button
                                        size="small"
                                        disableRipple
                                        disableFocusRipple
                                        disableTouchRipple
                                        startIcon={<FormatPaintIcon />}
                                        onClick={() => setState((prevState) => ({ ...prevState, isFormattingFormOpen: true }))}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div className={classes.refreshButton}>
                            <Tooltip title="Refresh Table" placement="top-end">
                                <Button
                                    size="small"
                                    disableRipple
                                    disableFocusRipple
                                    disableTouchRipple
                                    startIcon={<CachedOutlinedIcon />}
                                    onClick={() => setState((prevState) => ({ ...prevState, pivotTableKey: TableUtils.generateUUID() }))}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.pivotLayout}>
                        <div>
                            <LoadedColumnsList
                                columns={pivotTableAvailableColumns}
                                data={state.data}
                            />
                            <PivotTableInputContent
                                PivotRows={pivotRows}
                                PivotColumns={pivotColumns}
                                PivotValues={pivotValues}
                                prepareItem={prepareItem}
                                reorderItems={reorderItems}
                                removeColumn={removeColumn}
                                handleDrop={handleDrop}
                                updateChartOptions={(_, propertyName, propertyData) => updateChartInput(propertyName, propertyData)}
                            />
                        </div>
                        <div id={state.localChartId} className="pivot-table-wrapper">
                            <PivotTableWrapper
                                key={state.pivotTableKey}
                                parentWidth={document.getElementById(state.localChartId)?.clientWidth}
                                input={pivotShapedData}
                                rawData={state.data}
                                pivotDefaultColumns={pivotDefaultColumns}
                                formattingData={formattingData}
                            />
                        </div>
                    </div>
                </>
            )}
            <Dialog
                open={state.isDataDialogOpen}
                fullWidth
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title={`Data Preview | ${state.label}`}
                    onClose={() => toggleDialog()}
                    toolbarSize="md"
                />
                <DialogContent>
                    {state.dataPreview.length > 0 && (
                        <>
                            <div className={classes.actionsWrapper}>
                                <Button
                                    disabled={printingDocument}
                                    variant="outlined"
                                    startIcon={<PrintOutlinedIcon />}
                                    size="small"
                                    onClick={() => printChart(PRINTING_DOCUMENT_TYPE.PDF)}
                                />
                                <Button
                                    disabled={printingDocument}
                                    variant="outlined"
                                    startIcon={<CloudDownloadOutlinedIcon />}
                                    size="small"
                                    onClick={() => printChart(PRINTING_DOCUMENT_TYPE.SPREADSHEET)}
                                />
                            </div>
                            {renderTable(state.dataPreview, true)}
                        </>
                    )}
                </DialogContent>
            </Dialog>
            {state.isFormattingFormOpen && (
                <FormattingForm
                    input={inputInState}
                    rawData={state.data}
                    formattingData={formattingData}
                    saveFormatting={(records) => onSaveFormat(records)}
                />
            )}
        </div>
    );
}, (prevProps, nextProps) => nextProps.cache && prevProps.key === nextProps.key);

Chart.defaultProps = {
    label: '',
    type: '',
    query: '',
    totalRecords: null,
    data: [],
    options: null,
    height: 450,
    tableWidth: null,
    loadMore: null,
    pullQueryResultsWithFilters: () => null,
    loadingMoreData: false,
    defaultDateValues: [],
    inSettings: false,
    updateChartOptions: () => null,
};

Chart.propTypes = {
    type: PropTypes.string,
    totalRecords: PropTypes.number,
    data: PropTypes.array,
    query: PropTypes.string,
    options: PropTypes.object,
    loadMore: PropTypes.func,
    pullQueryResultsWithFilters: PropTypes.func,
    loadingMoreData: PropTypes.bool,
    defaultDateValues: PropTypes.array,
    tableWidth: PropTypes.number,
    height: PropTypes.oneOfType(
        PropTypes.string,
        PropTypes.number,
    ),
    label: PropTypes.string,
    inSettings: PropTypes.bool,
    updateChartOptions: PropTypes.func,
};

export default Chart;
