import * as yup from 'yup';

const ServiceSettingsMappingSchema = yup.object().shape({
    customerPayHourlyRate: yup.number().moreThan(-1),
    customerSubletPriceMarkUp: yup.number().moreThan(-1).max(100),
    hourlyLaborRate: yup.number().moreThan(-1),
    internalSubletPriceMarkUp: yup.number().moreThan(-1).max(100),
    shopSuppliesLimit: yup.number().moreThan(-1),
    shopSuppliesPercent: yup.number().moreThan(-1).max(100),
    minimumGP: yup.number().moreThan(-1),
    consentFormWording: yup.string(),
    invoiceDisclaimer: yup.string(),
    defaultFactoryWarranty: yup.boolean(),
    hideApproveDeclineButtons: yup.boolean(),
    multipleOpCodes: yup.boolean(),
    storageFee: yup.number().moreThan(-1),
    warrantyPartMarkUp: yup.number().moreThan(-1).max(100),
    lotName: yup.string().required(),
    chargeSalesTaxASR: yup.boolean(),
    chargeSalesTaxOnWarrantyDeductable: yup.boolean(),
    shopSuppliesType: yup.string().required(),

});

export default ServiceSettingsMappingSchema;
