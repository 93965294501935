import { useLazyQuery, useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useReducer } from 'react';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import EnumCatalogMap from 'services/mapData/EnumCatalogMap';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import CatalogListReducer, { ACTION_TYPES } from 'components/widgets/catalogs/CatalogListReducer';

const initialState = {
    records: [],
    idToDelete: 0,
    enumValuesId: 0,
    lineId: 0,
    isDirty: false,
};

const useCatalogListActions = ({ catalogEnum }) => {
    const [catalogState, dispatch] = useReducer(CatalogListReducer, initialState);

    const { records, idToDelete, isDirty } = catalogState;

    const [loadData, { loading }] = useLazyQuery(
        CatalogQuery.GET_ENUM_VALUES,
        {
            onCompleted: (res) => {
                let dataList = res.getEnumCatalogValues.map((item) => ({
                    enumValuesId: item.enumValuesId,
                    type: catalogEnum,
                    description: item.description,
                    lotName: item.lotName,
                }));

                dataList = EnumCatalogMap.enumCatalog(dataList);

                dispatch({ type: ACTION_TYPES.SET_RECORDS, value: dataList });
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage(null, errorMessage);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const [addRecords, { data: resultSave, loading: isSaving }] = useMutation(CatalogQuery.SAVE_ENUM, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveEnumCatalog) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [deleteRecord, { loading: isDeleting }] = useMutation(CatalogQuery.DELETE_ENUM, {
        onCompleted: (mutationData) => {
            if (mutationData?.deleteEnumCatalog) {
                ModalUtils.successMessage(null, 'Record deleted!');
                const newRecords = records.filter((item) => item.enumValuesId !== idToDelete);
                dispatch({
                    type: ACTION_TYPES.SET_AFTER_DELETE_RECORDS,
                    value: newRecords,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        let recordsToSave = records.filter((item) => item.dirty === true && !isEmpty(item.description))
            .map((item) => {
                const newItem = { ...item };

                if (newItem.enumValuesId < 0) {
                    newItem.enumValuesId = null;
                    newItem.lotName = 'All';
                }

                newItem.type = catalogEnum;

                return newItem;
            });

        recordsToSave = EnumCatalogMap.enumCatalogToSave(recordsToSave);

        await addRecords({
            variables: {
                enumValues: recordsToSave,
            },
        });
    };

    const getLineErrors = () => {
        const errors = [];

        records.forEach((item) => {
            if (isEmpty(item.description.trim())) errors.push({ message: `Line #${item.lineId} - Description is required before add new line` });
        });

        return errors;
    };

    const onDeleteConfirm = async () => {
        if (idToDelete < 0) {
            const newRecords = records.filter((item) => item.enumValuesId !== idToDelete);
            dispatch({ type: ACTION_TYPES.SET_AFTER_DELETE_RECORDS, value: newRecords });
            return;
        }

        await deleteRecord({
            variables: {
                enumValuesId: [idToDelete],
            },
        });
    };

    const onAddNewLine = () => {
        const errors = getLineErrors();

        if (errors.length > 0) {
            errors.unshift({ message: 'Please check the following before adding a new line: ' });
            ModalUtils.errorMessage(errors);
            return;
        }

        dispatch({ type: ACTION_TYPES.ADD_NEW_LINE });
    };

    const handleEditorChange = (columnId, newValue, cell) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        if (event && (key === 'Tab' || keyCode === 9)) onAddNewLine();
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const setIdToDelete = (value) => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value });
    };

    let list = records?.map((item) => ({
        enumValuesId: item.enumValuesId,
        type: catalogEnum,
        description: item.description,
        lotName: item.lotName,
    })) ?? [];

    list = EnumCatalogMap.enumCatalog(list);

    return {
        handleEditorKeyDown,
        handleEditorChange,
        onAddNewLine,
        onDeleteConfirm,
        getLineErrors,
        onSave,
        deleteRecord,
        addRecords,
        loadData,
        loading,
        isSaving,
        isDeleting,
        list,
        onCloseDeleteConfirm,
        isDirty,
        setIdToDelete,
        idToDelete,
        resultSave: resultSave?.saveEnumCatalog,
    };
};

export default useCatalogListActions;
