/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Components and Ohters
import StringUtils from 'lib/StringUtils';
import DealMap from 'services/mapData/DealMap';
import { CustomerType, ImportCustomer, DealType } from 'utils/enum/DealEnum';
import {
    isString, map, clone,
} from 'lodash';
import numeral from 'numeral';
import update from 'immutability-helper';
import moment from 'moment';
import { modules } from 'utils/enum/modules';
import DealUtils from 'utils/DealUtils';

// GraphQL
import ModalUtils from 'utils/ModalUtils';
import { isValidSchema } from 'utils/schema/utils';
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';

// Utilities
import KeyStore from 'utils/KeyStore';
import LotQuery from 'services/graphQL/query/LotQuery';
import UserQuery from 'services/graphQL/query/UserQuery';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import VehicleQuery from 'services/graphQL/query/VehicleQuery';

const DEFAULT_BUY_RATE = 9;

const DEFAULT_STATE = {
    open: false,
    openCreateCustomer: false,
    openEditCustomer: false,
    customerTag: '',
    isSelectClient: false,
    openDialogVehicle: false,
    isSelectedVehicle: false,
    isValidSalesPrice: false,
    includeTradeIn: false,
    buyer: {},
    coBuyer: {},
    vehicle: {},
    tradeIn: {},
    dealStructure: {
        cashDownPayment: 0,
        dealType: DealType.FINANCE,
        buyRate: DEFAULT_BUY_RATE,
        defaultFinanceCalculation: 'term',
        term: 0,
        paymentAmount: 0,
    },
    openDeferredDownPayment: false,
    deferredDownPayment: [],
    canSave: false,
    saving: false,
    vendorList: [],
    portfolioList: [],
    leadSourceList: [],
    userAvailableLots: [],
    minimumPriceSetting: [],
    financeCalculationsSettings: [],
    termSettings: [],
    recordToEdit: null,
};

const DealCreateContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.keyStore = new KeyStore();

        this.defaultLot = this.keyStore.getUserLots()?.find((i) => i.isDefault)?.lotName;

        this.state = {
            ...update(DEFAULT_STATE, {
                dealStructure: {
                    lotName: { $set: this.defaultLot },
                },
            }),
        };

        this.initBind();
    }

    componentDidMount() {
        this.getLeadSourceList();
        this.getUserAvailableLots();
        this.fetchVendorList();
        this.getPortfolioList();
        this.getLotSettings();
    }

    onChangeDealType(value) {
        const { dealStructure } = this.state;
        let newDealStructure;

        if (value !== DealType.CASH && value !== DealType.WHOLESALE) {
            newDealStructure = update(dealStructure, {
                dealType: { $set: value },
                buyRate: { $set: DEFAULT_BUY_RATE },
            });
        } else {
            newDealStructure = update(dealStructure, {
                dealType: { $set: value },
                buyRate: { $set: 0 },
                financeCompany: { $set: null },
            });
        }

        this.setState({
            dealStructure: newDealStructure,
        });
    }

    onDoubleClick(record) {
        const { state: { customerTag } } = this;

        switch (customerTag) {
        case CustomerType.BUYER:
            this.setBuyerInformation(record);
            break;
        case ImportCustomer.NEO:
            this.setNEOInformation(record);
            break;
        case ImportCustomer.CRM:
            this.setCRMInformation(record);
            break;
        case CustomerType.CO_BUYER:
            this.setCoBuyerInformation(record);
            break;
        case ImportCustomer.MAGILOOP:
            this.setNEOInformation(record);
            break;
        default:
        }
    }

    onDoubleClickVehicle(record) {
        const { state } = this;
        const { dealStructure: { lotName }, minimumPriceSetting } = state;
        const minimumPrice = this.getVehicleMinimumPrice(lotName, record, minimumPriceSetting);
        this.setState((prevState) => ({
            openDialogVehicle: false,
            isSelectedVehicle: true,
            vehicle: { ...record, minimumSalePrice: minimumPrice } || {},
            dealStructure: update(prevState.dealStructure, {
                salesPrice: { $set: record?.stickerPrice },
            }),
        }), () => {
            const { state: { dealStructure } } = this;
            this.validateRequiredFields(dealStructure);
        });
    }

    onSave() {
        const { state: { saving, importBy } } = this;
        if (saving) return;

        this.setState({
            saving: true,
        });

        if (importBy === ImportCustomer.NEO) {
            this.saveDealFromNEO();
        } else {
            this.saveDeals();
        }
    }

    onChangeValueDealStructure(field, value = '') {
        const {
            state: {
                dealStructure, vehicle, minimumPriceSetting, financeCalculationsSettings, termSettings,
            }, validateRequiredFields,
        } = this;
        const backUp = clone(dealStructure);
        let minimumPrice = vehicle.minimumSalePrice;
        backUp[field] = value;

        if (field === 'lotName') {
            minimumPrice = this.getVehicleMinimumPrice(value, vehicle, minimumPriceSetting);
            vehicle.minimumSalePrice = minimumPrice;
            backUp.defaultFinanceCalculation = this.getDefaultFinanceCalculation(value, financeCalculationsSettings);
            backUp.term = this.getDefaultTerm(value, termSettings);
        }

        validateRequiredFields(backUp);

        this.setState({
            dealStructure: backUp,
            vehicle: { ...vehicle, minimumSalePrice: minimumPrice } || {},
        });
    }

    onCloseDeferredDownPayment() {
        this.setState({
            openDeferredDownPayment: false,
        });
    }

    onOpenDeferredDownPayment() {
        this.setState({
            openDeferredDownPayment: true,
        });
    }

    // TODO: The trim and engine defaults to the first value of the array, change this when it can be edited in tradeIn from create deal
    setCRMInformation(record) {
        const dealType = DealUtils.getDealTypeByType(record.dealType);

        this.setState((prevState) => ({
            isSelectClient: true,
            open: false,
            customerTag: '',
            buyer: record.buyer || {},
            coBuyer: record.cobuyer || {},
            vehicle: record.vehicle || {},
            isSelectedVehicle: !!record.vehicle,
            tradeIn: {
                payOff: record.tradePayoff,
                acv: record.tradeACV || 0,
                allowance: record.tradeOffer,
                vehicle: {
                    ...record?.tradeIn,
                    trim: record?.tradeIn?.trim?.[0] || '',
                    engine: record?.tradeIn?.engine?.[0] || '',
                },
            },
            includeTradeIn: !!record.tradeIn,
            dealStructure: update(prevState.dealStructure, {
                salesPrice: { $set: record.vehicle?.stickerPrice },
                leadId: { $set: record.leadCode },
                leadSource: { $set: record.leadSource },
                dealType: { $set: dealType?.value },
                cashDownPayment: { $set: record.availableCash },
                lotName: { $set: record.lotName },
                lotId: { $set: record.lotId },
            }),
        }), () => {
            const { dealStructure, coBuyer } = this.state;
            this.subscribeBuyer();
            if (coBuyer.customerId) this.subscribeCoBuyer();
            this.validateRequiredFields(dealStructure);
        });
    }

    setBuyerInformation(record) {
        this.setState({
            isSelectClient: true,
            open: false,
            customerTag: '',
            buyer: record || {},
        }, () => {
            this.subscribeBuyer();
        });
    }

    setCoBuyerInformation(record) {
        const { buyer } = this.state;

        if (buyer.customerCode === record.customerCode) {
            ModalUtils.errorMessage([], "The Buyer and Co Buyer can't be the same.");
        } else {
            this.setState({
                open: false,
                customerTag: '',
                coBuyer: record || {},
            }, () => {
                this.subscribeCoBuyer();
            });
        }
    }

    async setNEOInformation(record) {
        const currentDeferredDownPayment = [];

        if (record?.deal?.deferredPayments > 0) {
            currentDeferredDownPayment.push({
                paymentAmount: record?.deal?.deferredPayments || 0,
                paymentDate: moment().toDate(),
            });
        }

        this.setState((prevState) => ({
            isSelectClient: true,
            isOpen: false,
            customerTag: '',
            customerApplicationID: record.customerApplicationID,
            modifiedOn: record.modifiedOn,
            importBy: ImportCustomer.NEO,
            buyer: record.buyer || {},
            coBuyer: record.coBuyer || {},
            vehicle: record.vehicle || {},
            isSelectedVehicle: !!record.vehicle,
            dealStructure: update(prevState.dealStructure, {
                salesPrice: { $set: record.deal?.salesPrice || 0 },
                paymentFrequency: { $set: record.deal?.paymentFrequency || '' },
                interestRate: { $set: record.deal?.interestRate || 0 },
                term: { $set: record.deal?.term || 0 },
                cashDownPayment: { $set: record.deal?.downPayment || 0 },
                totalDeferredDownPayment: { $set: record.deal?.deferredPayments || 0 },
            }),
            deferredDownPayment: update(prevState.deferredDownPayment, {
                $push: currentDeferredDownPayment,
            }),
        }));

        await this.createCustomerFromNEO(record, true);
        await this.createCustomerFromNEO(record);
    }

    async getLeadSourceList() {
        const response = await this.dealService.getLeadSources();
        const { data, graphQLErrors } = response;

        if (graphQLErrors) {
            ModalUtils.errorMessage(graphQLErrors);
            return;
        }

        if (data) {
            this.setState({
                leadSourceList: data,
            });
        }
    }

    getUserAvailableLots() {
        this.graphqlClient
            .query(UserQuery.GET_USER_LOTS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getUserLots) {
                    const userAvailableLots = [];
                    data.getUserLots.forEach((item) => {
                        userAvailableLots.push({
                            value: item,
                            label: item,
                        });
                    });
                    this.setState({
                        userAvailableLots,
                    });
                }
            });
    }

    getSumOfDeferredDownPayment(record = []) {
        let total = 0;

        record.forEach((item) => {
            total += item.paymentAmount;
        });

        return total;
    }

    getBasicInformationToSaveDeal() {
        const {
            state: {
                buyer, coBuyer, vehicle, dealStructure, deferredDownPayment,
            },
        } = this;
        const currentDeferredDownPayment = map(deferredDownPayment, (item, index) => {
            const newIndex = index + 1;
            return DealMap.mapDeferredDownPaymentCreateDeal(item, newIndex);
        });

        return {
            buyerCode: buyer.customerCode,
            coBuyerCode: coBuyer.customerCode,
            stockNumber: vehicle.stockNumber,
            lotName: dealStructure.lotName,
            dealStructure: {
                dealType: dealStructure.dealType,
                price: dealStructure.salesPrice,
                cashDownPayment: dealStructure.cashDownPayment,
                deferredDownPayment: currentDeferredDownPayment,
                financeCompany: dealStructure.financeCompany,
                buyRate: dealStructure.buyRate,
                soldDate: new Date().toLocaleDateString('en-US'),
                portfolioId: dealStructure.portfolioId,
                defaultFinanceCalculation: dealStructure.defaultFinanceCalculation,
                term: dealStructure.term,
                paymentAmount: dealStructure.paymentAmount,
            },
        };
    }

    getVehicles(searchTerm, isSearching = false) {
        const { state: { previousVehicleSearch } } = this;

        if (searchTerm === previousVehicleSearch) return;

        if (isSearching) {
            this.setState({ records: [], totalCount: 0 });
        }

        const input = {
            paginate: {
                start: 0,
                limit: 50,
            },
            filter: {
                searchTerm,
            },
        };

        this.setState({ isLoading: true, previousVehicleSearch: searchTerm });
        this.graphqlClient
            .query(VehicleQuery.GET_VEHICLES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVehicles) {
                    if (data.getVehicles.totalCount === 1) this.onDoubleClickVehicle(data.getVehicles.vehicles[0]);
                    else this.toggleModalVehicle({ ...data?.getVehicles, searchTerm });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    setDeferredDownPayment(record) {
        const { state: { dealStructure } } = this;
        const backUp = clone(dealStructure);
        const totalDeferredDownPayment = this.getSumOfDeferredDownPayment(record);
        backUp.totalDeferredDownPayment = totalDeferredDownPayment;

        this.setState({
            deferredDownPayment: record || [],
            dealStructure: backUp,
        });
        this.onCloseDeferredDownPayment();
    }

    getPortfolioList() {
        this.graphqlClient
            .query(DealsQuery.GET_PORTFOLIOS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const portfolioList = [];
                let defaultPortfolioId = null;

                if (data?.listPortfolios) {
                    data.listPortfolios.forEach((item) => {
                        portfolioList.push({
                            value: item.portfolioId,
                            label: item.name,
                        });
                        if (item.isDefault) {
                            defaultPortfolioId = item.portfolioId;
                        }
                    });

                    this.setState((prevState) => ({
                        portfolioList,
                        dealStructure: update(prevState.dealStructure, {
                            portfolioId: { $set: defaultPortfolioId },
                        }),
                    }));
                }
            });
    }

    getLotSettings() {
        const { state } = this;
        const { dealStructure: { lotName } } = state;
        const filter = {
            category: 'Deals',
            key: ['internetPrice', 'stickerPrice', 'minimumPrice', 'defaultFinanceCalculation', 'Default Term'],
        };

        this.graphqlClient
            .query(LotQuery.GET_SETTINGS, filter)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }
                const financeCalculationsSettings = data?.getSettings?.filter((x) => x.key?.toLowerCase() === 'defaultfinancecalculation');
                const termSettings = data?.getSettings?.filter((x) => x.key?.toLowerCase() === 'default term');

                this.setState((prevState) => ({
                    minimumPriceSetting: data?.getSettings?.filter((x) => x.key?.toLowerCase().includes('price')) || [],
                    financeCalculationsSettings,
                    termSettings,
                    dealStructure: update(prevState.dealStructure, {
                        defaultFinanceCalculation: { $set: this.getDefaultFinanceCalculation(lotName, financeCalculationsSettings) },
                        term: { $set: this.getDefaultTerm(lotName, termSettings) },
                    }),
                }));
            });
    }

    getLotSettingRecord(lotName, setting) {
        let lot = setting?.find((x) => x?.lotName?.toUpperCase() === lotName?.toUpperCase());
        if (!lot || lot.length === 0) {
            lot = setting?.find((x) => x?.lotName?.toUpperCase() === 'ALL LOTS');
        }
        return lot;
    }

    getDefaultFinanceCalculation(lotName, setting) {
        const lot = this.getLotSettingRecord(lotName, setting);
        return (lot?.value || 'term').toLowerCase();
    }

    getDefaultTerm(lotName, setting) {
        const lot = this.getLotSettingRecord(lotName, setting);
        return lot?.value ? parseInt(lot?.value, 10) : 0;
    }

    getVehicleMinimumPrice(lotName, vehicle, setting) {
        let lot = setting?.filter((x) => x?.lotName?.toUpperCase() === lotName?.toUpperCase());
        if (!lot) {
            lot = setting?.filter((x) => x?.lotName?.toUpperCase() === 'ALL');
        }
        const minimumPriceValue = lot?.find((x) => x.value === 'true');
        return vehicle[minimumPriceValue?.key || 'internetPrice'];
    }

    async createCustomerFromNEO(record, isBuyer = false) {
        const response = await this.dealService.createCustomerFromNEO(record.customerApplicationID, isBuyer);
        const { data, graphQLErrors } = response;

        if (graphQLErrors) {
            ModalUtils.errorMessage(graphQLErrors);
            return;
        }

        if (data?.createCustomerFromNEO) {
            if (isBuyer) {
                this.setState({
                    buyer: data.createCustomerFromNEO,
                });
            } else {
                this.setState({
                    coBuyer: data.createCustomerFromNEO,
                });
            }
        }
    }

    subscribeBuyer() {
        const { buyer } = this.state;
        const input = {
            customerId: buyer.customerId,
        };

        this.graphqlClient.subscribe(this.responseBuyerSubscription, CustomerSubscription.CUSTOMER_EDITED, input)
            .then((response) => {
                this.buyerSubscription = response;
            });
    }

    subscribeCoBuyer() {
        const { coBuyer } = this.state;
        const input = {
            customerId: coBuyer.customerId,
        };

        this.graphqlClient.subscribe(this.responseCoBuyerSubscription, CustomerSubscription.CUSTOMER_EDITED, input)
            .then((response) => {
                this.coBuyerSubscription = response;
            });
    }

    responseBuyerSubscription(record) {
        const { data } = record;

        if (data?.customerEdited) {
            const { customerEdited } = data;
            this.setState((prevState) => ({
                buyer: update(prevState.buyer, {
                    $set: customerEdited,
                }),
            }));
        }
    }

    responseCoBuyerSubscription(record) {
        const { data } = record;

        if (data?.customerEdited) {
            const { customerEdited } = data;
            this.setState((prevState) => ({
                coBuyer: update(prevState.coBuyer, {
                    $set: customerEdited,
                }),
            }));
        }
    }

    unsubscribeBuyer() {
        if (this.buyerSubscription) {
            this.buyerSubscription.unsubscribe();
        }
    }

    unsubscribeCoBuyer() {
        if (this.coBuyerSubscription) {
            this.coBuyerSubscription.unsubscribe();
        }
    }

    fetchVendorList() {
        const filter = {
            vendorType: ['Financial Institution'],
        };

        this.graphqlClient
            .query(VendorQuery.GET_VENDOR_LIST, { filter })
            .then((response) => {
                const vendorList = [{ label: 'CASH', value: 'CASH' }];
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVendorList) {
                    data.getVendorList.forEach((item) => {
                        vendorList.push({
                            value: item.vendorName,
                            label: item.vendorName,
                        });
                    });

                    this.setState({
                        vendorList,
                    });
                }
            });
    }

    validateRequiredFields(dealStructure) {
        const { state: { isSelectedVehicle, vehicle } } = this;
        let isEnabled = true;
        const isValidSalesPrice = this.isValidSalesPrice(dealStructure, vehicle);

        if (
            !dealStructure.cashDownPayment
            || !dealStructure.salesPrice
            || StringUtils.isEmpty(dealStructure.dealType)
            || !isSelectedVehicle
            || !isValidSalesPrice
        ) {
            isEnabled = false;
        }

        this.setState({
            canSave: isEnabled,
            isValidSalesPrice,
        });
    }

    isValidSalesPrice(dealStructure, vehicle) {
        if (!dealStructure || !dealStructure.salesPrice || !vehicle || vehicle.minimumSalePrice < 0) return false;

        return numeral(dealStructure.salesPrice).value() >= numeral(vehicle.minimumSalePrice).value();
    }

    saveDeals() {
        const {
            state: {
                tradeIn, includeTradeIn,
                dealStructure,
            },
            props,
        } = this;
        const input = this.getBasicInformationToSaveDeal();
        input.dealStructure.leadId = dealStructure.leadId;
        input.dealStructure.leadSource = dealStructure.leadSource;

        if (includeTradeIn) input.tradeIn = tradeIn;

        this.dealService.create(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                this.setState({
                    saving: false,
                });

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.createDeal && data.createDeal.success) {
                    const { createDeal: { accountNumber } } = data;

                    props.history.push(`/${modules.DEALS}/${accountNumber}`);
                }
            });
    }

    saveDealFromNEO() {
        const {
            customerApplicationID,
            dealStructure: {
                interestRate, term, paymentFrequency, downPayment,
            },
        } = this.state;
        const { props } = this;
        const input = this.getBasicInformationToSaveDeal();
        input.customerApplicationID = customerApplicationID;
        input.dealStructure.interestRate = interestRate;
        input.dealStructure.term = term;
        input.dealStructure.paymentFrequency = paymentFrequency;
        input.dealStructure.downPayment = downPayment;

        this.dealService.createDealFromNEO(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                this.setState({
                    saving: false,
                });

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.createDealFromNEO?.success) {
                    const { createDealFromNEO: { accountNumber } } = data;

                    props.history.push(`/${modules.DEALS}/${accountNumber}`);
                }
            });
    }

    toggleModalVehicle(vehicleSearchData) {
        this.setState((prevState) => ({
            openDialogVehicle: !prevState.openDialogVehicle,
            vehicleSearchData,
        }));
    }

    cancelCreateDeal() {
        this.setState((prevState) => ({
            ...DEFAULT_STATE,
            dealStructure: {
                ...DEFAULT_STATE.dealStructure,
                lotName: this.defaultLot,
                defaultFinanceCalculation: this.getDefaultFinanceCalculation(this.defaultLot, prevState.financeCalculationsSettings),
                term: this.getDefaultTerm(this.defaultLot, prevState.termSettings),
            },
            vendorList: prevState.vendorList,
            portfolioList: prevState.portfolioList,
            leadSourceList: prevState.leadSourceList,
            userAvailableLots: prevState.userAvailableLots,
            minimumPriceSetting: prevState.minimumPriceSetting,
            financeCalculationsSettings: prevState.financeCalculationsSettings,
            termSettings: prevState.termSettings,
        }));
        this.unsubscribeBuyer();
        this.unsubscribeCoBuyer();
    }

    toggleModal(value) {
        const currentValue = isString(value) && !StringUtils.isEmpty(value) ? value : '';

        this.setState((prevState) => ({
            open: !prevState.open,
            customerTag: currentValue,
        }));
    }

    toggleCreateCustomer(value) {
        const currentValue = isString(value) && !StringUtils.isEmpty(value) ? value : '';

        this.setState((prevState) => ({
            openCreateCustomer: !prevState.openCreateCustomer,
            customerTag: currentValue,
        }));
    }

    toggleEditCustomer(record) {
        this.setState((prevState) => ({
            recordToEdit: prevState.openEditCustomer ? null : record,
            openEditCustomer: !prevState.openEditCustomer,
        }));
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getVehicles = this.getVehicles.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.subscribeBuyer = this.subscribeBuyer.bind(this);
        this.onChangeDealType = this.onChangeDealType.bind(this);
        this.cancelCreateDeal = this.cancelCreateDeal.bind(this);
        this.subscribeCoBuyer = this.subscribeCoBuyer.bind(this);
        this.toggleEditCustomer = this.toggleEditCustomer.bind(this);
        this.toggleModalVehicle = this.toggleModalVehicle.bind(this);
        this.toggleCreateCustomer = this.toggleCreateCustomer.bind(this);
        this.onDoubleClickVehicle = this.onDoubleClickVehicle.bind(this);
        this.validateRequiredFields = this.validateRequiredFields.bind(this);
        this.setDeferredDownPayment = this.setDeferredDownPayment.bind(this);
        this.responseBuyerSubscription = this.responseBuyerSubscription.bind(this);
        this.onOpenDeferredDownPayment = this.onOpenDeferredDownPayment.bind(this);
        this.onCloseDeferredDownPayment = this.onCloseDeferredDownPayment.bind(this);
        this.onChangeValueDealStructure = this.onChangeValueDealStructure.bind(this);
        this.responseCoBuyerSubscription = this.responseCoBuyerSubscription.bind(this);
    }

    render() {
        const { props, state } = this;
        const { dealStructure, vehicle } = state;
        const { dealType } = dealStructure;

        const formValidations = isValidSchema(DealUtils.getCreateSchemaByDealType(dealType), { ...dealStructure, vehicle });

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                toggleModal={this.toggleModal}
                getVehicles={this.getVehicles}
                onDoubleClick={this.onDoubleClick}
                cancelCreateDeal={this.cancelCreateDeal}
                onChangeDealType={this.onChangeDealType}
                toggleModalVehicle={this.toggleModalVehicle}
                toggleEditCustomer={this.toggleEditCustomer}
                toggleCreateCustomer={this.toggleCreateCustomer}
                onDoubleClickVehicle={this.onDoubleClickVehicle}
                setDeferredDownPayment={this.setDeferredDownPayment}
                onOpenDeferredDownPayment={this.onOpenDeferredDownPayment}
                onCloseDeferredDownPayment={this.onCloseDeferredDownPayment}
                onChangeValueDealStructure={this.onChangeValueDealStructure}
                formValidations={formValidations}
            />
        );
    }
};

export default DealCreateContainer;
