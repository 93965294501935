import React, { useEffect, useState } from 'react';
import {
    makeStyles, Button, Grid, Divider,
    Switch, Tooltip,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useMutation, useQuery } from '@apollo/client';
import AccountingSettingsQuery from 'services/graphQL/query/accounting/AccountingSettings';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountSettingsMutation from 'services/graphQL/mutate/accounting/AccountSettingsMutation';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import ModalUtils from 'utils/ModalUtils';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import AccountingSettingsMappingSchema from 'utils/schema/accounting/settings/AccountingSettingsMappingSchema';
import { FetchPolicy } from 'utils/enum/Core';
import { CheckTypes, AccountingCOAType } from 'utils/enum/AccountingEnum';
import DateUtils from 'lib/DateUtils';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DropdownQuery from 'components/widgets/DropdownQuery';
import Select from 'components/widgets/Select';
import CheckPrintingSettings from 'components/modules/settings/accounting/CheckPrintingSettings';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
    },
    wrapperDate: {
        display: 'flex',
        flex: 1,
    },
    postingVehicle: {
        '& > label': {
            width: '100%',
        },
    },
}));

const checkTypeList = [
    { label: CheckTypes.CUSTOMER, value: CheckTypes.CUSTOMER },
    { label: CheckTypes.VENDOR, value: CheckTypes.VENDOR },
    { label: CheckTypes.EMPLOYEE, value: CheckTypes.EMPLOYEE },
];

const optionTrueFalseList = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const AccountDefaultSettings = () => {
    const classes = useStyles();

    const [record, setRecord] = useState(
        {
            accountsPayable: 0,
            defaultBankChecks: 0,
            defaultCheckType: '',
            accountingLockDate: null,
            editableCheckNumbers: false,
            postVehicleAccounting: false,
            postROsToAccounting: false,
        },
    );

    const handleChange = (columnId, newValue) => {
        setRecord((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    const {
        data, loading, error,
    } = useQuery(AccountingSettingsQuery.GET_ACCOUNTING_DEFAULT_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getAccountingDefaultSettings } = data;
            const recordSettings = { ...getAccountingDefaultSettings };
            const accountingLockDate = !recordSettings.accountingLockDate
                ? null
                : new Date(DateUtils.getOnlyDate(new Date(recordSettings.accountingLockDate)));
            recordSettings.accountingLockDate = accountingLockDate;
            setRecord(recordSettings);
        }
    }, [data, loading, error]);

    const [saveData, { loading: updating }] = useMutation(AccountSettingsMutation.SAVE_DEFAULT_ACCOUNTING_SETTINGS, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveDefaultAccountingSettings) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        saveData({ variables: { input: record } });
    };

    const isValidData = isValidSchema(AccountingSettingsMappingSchema, record);
    const { isValid, errors } = isValidData;

    return (
        <Grid container>
            <Grid item sm={12} md={8} className={classes.box}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Settings</Form.Label>
                            <Divider />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Date to lock all entries prior or equal to:</Form.Label>
                            <DatePicker
                                selected={record.accountingLockDate}
                                name="accountingLockDate"
                                size="sm"
                                className={isValidField(errors, 'accountingLockDate')
                                    ? 'invalid-field form-control form-control-sm' : 'form-control form-control-sm'}
                                wrapperClassName={classes.wrapperDate}
                                placeholderText="mm/dd/yyyy"
                                popperContainer={CalendarContainer}
                                onChange={(date) => handleChange('accountingLockDate', date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Operating Bank</Form.Label>
                            <DropdownQuery
                                name="defaultBankChecks"
                                value={record.defaultBankChecks}
                                placeholder="select an account"
                                onChange={handleChange}
                                className={isValidField(errors, 'defaultBankChecks') ? 'invalid-field' : ''}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['fullDescription'],
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Accounts Payable</Form.Label>
                            <DropdownQuery
                                name="accountsPayable"
                                value={record.accountsPayable}
                                placeholder="Select an account"
                                onChange={handleChange}
                                className={isValidField(errors, 'accountsPayable') ? 'invalid-field' : ''}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypes: [AccountingCOAType.ACCOUNTS_PAYABLE],
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['fullDescription'],
                                }}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Check Settings</Form.Label>
                            <Divider />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Check Type</Form.Label>
                            <DropdownQuery
                                name="defaultCheckType"
                                value={record.defaultCheckType}
                                placeholder="Select a type"
                                onChange={handleChange}
                                className={isValidField(errors, 'defaultCheckType') ? 'invalid-field' : ''}
                                dataSource={{
                                    localData: checkTypeList,
                                    idField: 'value',
                                    descriptionField: 'label',
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Editable Check Numbers</Form.Label>
                            <Select
                                name="editableCheckNumbers"
                                value={record.editableCheckNumbers || false}
                                options={optionTrueFalseList}
                                size="sm"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className={classes.postingVehicle}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Post Vehicles to Accounting automatically</Form.Label>
                                    <Tooltip title="Post Vehicles to Accounting automatically when they are added to Inventory">
                                        <Switch
                                            checked={record.postVehicleAccounting ?? false}
                                            onChange={(event) => handleChange('postVehicleAccounting', event.target.checked)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Tooltip>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Post ROs to Accounting automatically</Form.Label>
                                    <Tooltip title="Post ROs to Accounting automatically when they are closed">
                                        <Switch
                                            checked={record.postROsToAccounting ?? false}
                                            onChange={(event) => handleChange('postROsToAccounting', event.target.checked)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Tooltip>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Form.Group as={Col}>
                            <Button
                                variant="outlined"
                                startIcon={<SaveOutlinedIcon />}
                                size="small"
                                disabled={updating || !isValid}
                                onClick={onSave}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.box}>
                <CheckPrintingSettings />
            </Grid>
        </Grid>
    );
};
export default AccountDefaultSettings;
