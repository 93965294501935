import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Components
import NavMenu from 'components/layout/NavMenu';

// Material UI
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import LayoutStyles from 'styles/layout';
import MenuIcon from '@material-ui/icons/Menu';
import { useSubscription } from '@apollo/client';
import { TYPE } from 'components/layout/NotificationBadge';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';
import SMSNotification from 'components/widgets/sms/SMSNotification';
import {
    Toolbar, IconButton,
} from '@material-ui/core';
import ToastNotification from 'components/layout/ToastNotification';
import EmailNotification from 'components/widgets/sms/EmailNotification';
import SMSSubscription from 'services/graphQL/subscription/crm/SMSSubscription';
import MailSubscription from 'services/graphQL/subscription/crm/MailSubscription';
import NotificationSubscription from 'services/graphQL/subscription/core/NotificationSubscription';

const styles = (theme) => LayoutStyles.baseLayout(theme);
const options = {
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: false,
    position: 'top-right',
    className: 'sms-notification',
};
const BaseLayout = (props) => {
    const [open, setOpen] = useState(false);
    const { classes, children } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const rootClass = isMobile ? classes.rootMobile : classes.root;
    const { data: subscriptionData } = useSubscription(SMSSubscription.RECEIVED_SMS_NOTIFICATION);
    const { data: subscriptionEmailData } = useSubscription(MailSubscription.RECEIVED_EMAIL_NOTIFICATION);
    const { data } = useSubscription(NotificationSubscription.RECEIVED_NOTIFICATION, { variables: { type: TYPE.NOTIFICATION } });

    useEffect(() => {
        if (!isEmpty(subscriptionData?.receivedSMSNotification)) {
            toast(() => <SMSNotification record={subscriptionData.receivedSMSNotification} />, options);
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (!isEmpty(subscriptionEmailData?.receivedEmailNotification)) {
            toast(() => <EmailNotification record={subscriptionEmailData.receivedEmailNotification} />, options);
        }
    }, [subscriptionEmailData]);

    // General notification
    useEffect(() => {
        if (!isEmpty(data?.receivedNotification)) {
            toast(() => <ToastNotification record={data.receivedNotification} />, options);
        }
    }, [data]);

    return (
        <div className={rootClass}>
            <NavMenu
                externalOpen={open}
                setToogleMobile={(val) => setOpen(val)}
            />
            <main
                className={classes.content}
            >
                {isMobile && (
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => {
                                setOpen(!open);
                            }}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon
                                className={classes.menuIcon}
                            />
                        </IconButton>
                    </Toolbar>
                )}
                {children}
            </main>
        </div>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(BaseLayout);
