import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
    Checkbox, FormControlLabel,
} from '@material-ui/core';
import { Form, Col } from 'react-bootstrap';
import {
    useQuery,
    useLazyQuery,
    useMutation,
    useApolloClient,
} from '@apollo/client';
import { SUPPORTED_FILES_EXT } from 'utils/enum/OpenAIEnum';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import FileEditor from 'components/modules/settings/ai/FileEditor';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';

// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { cloneDeep } from 'lodash';
import NumberUtils from 'lib/NumberUtils';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
            fontSize: '15px',
        },
    },
    content: {
        padding: '20px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            fontSize: '13px',
            '& > div': {
                width: '50%',
            },
        },
        '& > div:nth-child(2)': {
            fontSize: '13px',
            marginTop: '15px',
            '& textarea': {
                resize: 'none',
                height: '250px',
            },
        },
        '& > div:nth-child(3)': {
            marginTop: '15px',
            fontSize: '13px',
            '& > div > div': {
                display: 'flex',
                marginBottom: '2px',
                justifyContent: 'space-between',
                '& > div:nth-child(1)': {
                    width: '50%',
                    display: 'flex',
                    '& > input': {
                        width: '250px',
                        marginRight: '5px',
                    },
                },
                '& > div:nth-child(2)': {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    '& > div': {
                        width: '250px',
                        marginRight: '5px',
                    },
                },
            },
        },
        '& > div:nth-child(4)': {
            marginTop: '15px',
            fontSize: '13px',
            '& > div > div:nth-child(2)': {
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2px',
                '& > div': {
                    width: '250px',
                    marginRight: '5px',
                    '& > div': {
                        height: '34px',
                    },
                },
                '& > input': {
                    width: '250px',
                    marginRight: '5px',
                },
            },
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
    tableContainer: {
        height: '300px',
        overflow: 'hidden',
        minWidth: '800px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    uploader: {
        display: 'none',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(2), & > button:nth-child(3)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
}));

const AssistantForm = ({ assistant, toggleDialog }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const client = useApolloClient();
    const resourceUploader = useRef(null);
    const replacementUploader = useRef(null);

    const {
        aiAssistantId,
        companyCode,
        instructions,
        isLive: isAssistantLive,
        notifyAIWhilePaused: isAINotificationOn,
        maxResponseTokens,
        model,
        name: assistantName,
        temperature,
        files,
        functions: assistantFunctions,
    } = assistant ?? {};

    const [state, setState] = useState({
        companies: [],
        models: [],
        globalFiles: [],
        functions: [],
        isLive: isAssistantLive ?? false,
        notifyAIWhilePaused: isAINotificationOn ?? false,
        selectedName: assistantName,
        selectedTemperature: temperature ?? 1,
        selectedMaxTokens: maxResponseTokens ?? 100,
        selectedCompany: companyCode,
        selectedModel: model ?? 'gpt-3.5-turbo',
        selectedInstructions: instructions,
        selectedFile: null,
        selectedFunction: null,
        selectedFunctionDescription: null,
        selectedFiles: files?.length > 0 ? files.map((f) => ({
            name: f.name,
            size: f.size,
            path: f.url,
            fileId: f.aiAssistantExternalFileId,
        })) : [],
        selectedFunctions: assistantFunctions?.length > 0 ? assistantFunctions.map((f) => ({
            description: f.description,
            functionName: f.function,
        })) : [],
        selectedLocalFile: null,
        documentName: null,
        isUploadingResource: false,
        isFileEditorOpen: false,
    });

    const {
        companies,
        models,
        globalFiles,
        functions,
        isLive,
        notifyAIWhilePaused,
        selectedName,
        selectedTemperature,
        selectedCompany,
        selectedMaxTokens,
        selectedModel,
        selectedInstructions,
        selectedFile,
        selectedFunction,
        selectedFunctionDescription,
        selectedFiles,
        selectedFunctions,
        selectedLocalFile,
        documentName,
        isUploadingResource,
        isFileEditorOpen,
    } = state;

    const {
        data: enabledCompaniesData,
        loading: loadingEnabledCompanies,
        error: errorLoadingEnabledCompanies,
    } = useQuery(AIQuery.PULL_COMPANIES_AI_KEY_ADDED, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: filesData,
        loading: loadingFiles,
        error: errorLoadingFiles,
    } = useQuery(AIQuery.LIST_GLOBAL_FILES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: functionsData,
        loading: loadingFunctions,
        error: errorLoadingFunctions,
    } = useQuery(AIQuery.GET_CUSTOM_FUNCTIONS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [loadModels, { loading: loadingModels }] = useLazyQuery(AIQuery.GET_MODELS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.getModels;
                setState((prevState) => ({
                    ...prevState,
                    models: data,
                }));
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [createAssistant, { loading: creatingAssistant }] = useMutation(AIMutation.CREATE_ASSISTANT, {
        onCompleted: () => {
            toggleDialog();
            ModalUtils.successMessage(null, 'Assistant created successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [editAssistant, { loading: editingAssistant }] = useMutation(AIMutation.MODIFY_ASSISTANT, {
        onCompleted: () => {
            toggleDialog();
            ModalUtils.successMessage(null, 'Assistant updated successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (selectedCompany != null) loadModels({ variables: { companyCode: selectedCompany } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    useEffect(() => {
        if (errorLoadingFunctions) {
            ModalUtils.errorMessage(errorLoadingFunctions?.graphQLErrors);
            return;
        }

        if (!loadingFunctions) {
            const data = functionsData?.getCustomFunctions;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    functions: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFunctions, errorLoadingFunctions]);

    useEffect(() => {
        if (errorLoadingEnabledCompanies) {
            ModalUtils.errorMessage(errorLoadingEnabledCompanies?.graphQLErrors);
            return;
        }

        if (!loadingEnabledCompanies) {
            const data = enabledCompaniesData?.pullCompaniesAIKeyAdded;
            if (companies) {
                setState((prevState) => ({
                    ...prevState,
                    companies: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEnabledCompanies, errorLoadingEnabledCompanies]);

    useEffect(() => {
        if (errorLoadingFiles) {
            ModalUtils.errorMessage(errorLoadingFiles?.graphQLErrors);
            return;
        }

        if (!loadingFiles) {
            const data = filesData?.listGlobalFiles;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    globalFiles: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFiles, errorLoadingFiles]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const replaceFile = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        if (!selectedLocalFile.path.endsWith(ext)) {
            ModalUtils.errorMessage(null, 'The same file type is required to update.');
            return;
        }

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = NumberUtils.round(size / 1024 / 1024);
            const { data } = await client.mutate({
                mutation: AIMutation.EDIT_FILE_ON_BUCKET,
                variables: {
                    path: selectedLocalFile.path,
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.editFileOnBucket;
            if (response) {
                const clone = cloneDeep(selectedFiles);
                const current = clone.find((f) => f.name === selectedLocalFile.name && f.path === selectedLocalFile.path);
                if (current) current.size = sizeInMB;

                setState((prevState) => ({
                    ...prevState,
                    selectedLocalFile: null,
                    selectedFiles: clone,
                    isUploadingResource: false,
                }));

                ModalUtils.successMessage(null, 'File updated successfully');
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const uploadResource = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        if (!Object.values(SUPPORTED_FILES_EXT).includes(ext)) {
            ModalUtils.errorMessage(null, `Only ${Object.values(SUPPORTED_FILES_EXT).map((ex) => ex.replace('.', '')).join(', ')} documents are supported`);
            return;
        }

        const clone = cloneDeep(selectedFiles);
        if (clone.some((f) => f.name === documentName)) {
            ModalUtils.errorMessage(null, 'There is a file with that name already added');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = NumberUtils.round(size / 1024 / 1024);
            const { data } = await client.mutate({
                mutation: AIMutation.UPLOAD_FILE_ON_BUCKET,
                variables: {
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const path = data?.uploadFileOnBucket;
            if (path) {
                clone.push({
                    path,
                    name: documentName ?? StringUtils.toPascalCase(name.replace(ext, '')),
                    size: sizeInMB,
                });

                setState((prevState) => ({
                    ...prevState,
                    documentName: null,
                    selectedFiles: clone,
                    isUploadingResource: false,
                }));

                ModalUtils.successMessage(null, 'File uploaded successfully');
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const deleteFile = (record) => {
        const clone = cloneDeep(selectedFiles);
        setState((prevState) => ({
            ...prevState,
            selectedFiles: clone.filter((f) => f.name !== record.name),
        }));
    };

    const deleteFunction = (record) => {
        const clone = cloneDeep(selectedFunctions);
        setState((prevState) => ({
            ...prevState,
            selectedFunctions: clone.filter((f) => f.functionName !== record.functionName),
        }));
    };

    const addFunction = () => {
        const clone = cloneDeep(selectedFunctions);
        if (clone.some((f) => f.functionName === selectedFunction)) {
            ModalUtils.errorMessage(null, 'Function already added');
            return;
        }

        clone.push({
            description: selectedFunctionDescription,
            functionName: selectedFunction,
        });

        setState((prevState) => ({
            ...prevState,
            selectedFunction: null,
            selectedFunctionDescription: null,
            selectedFunctions: clone,
        }));
    };

    const addGlobalFile = () => {
        const clone = cloneDeep(selectedFiles);
        const current = globalFiles.find((f) => f.aiGlobalFileId === selectedFile);
        if (clone.some((f) => f.name === current.name)) {
            ModalUtils.errorMessage(null, 'File already added');
            return;
        }

        clone.push({
            path: current.url,
            name: current.name,
            size: current.size,
        });

        setState((prevState) => ({
            ...prevState,
            selectedFile: null,
            selectedFiles: clone,
        }));
    };

    const toggleFileEditor = (record, _, filedEditedSize) => {
        const clone = cloneDeep(selectedFiles);
        if (filedEditedSize) {
            const current = clone.find((f) => f.name === selectedLocalFile.name && f.path === selectedLocalFile.path);
            if (current) current.size = filedEditedSize;
        }

        setState((prevState) => ({
            ...prevState,
            selectedLocalFile: record ? { ...record, url: record.path } : null,
            selectedFiles: clone,
            isFileEditorOpen: !isFileEditorOpen,
        }));
    };

    const openFileDialog = (isReplacement = false, record) => {
        if (isReplacement) {
            setState((prevState) => ({
                ...prevState,
                selectedLocalFile: record,
            }));

            const { current } = replacementUploader;
            if (current) current.click();

            return;
        }

        const { current } = resourceUploader;
        if (current) current.click();
    };

    const saveAssistant = () => {
        if (aiAssistantId) {
            const payload = {
                assistantId: aiAssistantId,
                name: selectedName,
                instructions: selectedInstructions,
                model: selectedModel,
                temperature: selectedTemperature,
                maxResponseTokens: selectedMaxTokens,
                isLive,
                notifyAIWhilePaused,
                ...(selectedFiles.length > 0 ? {
                    files: selectedFiles,
                } : {}),
                ...(selectedFunctions.length > 0 ? {
                    functions: selectedFunctions,
                } : {}),
            };

            editAssistant({
                variables: payload,
            });
            return;
        }

        const companyName = companies.find((c) => c.companyCode === selectedCompany)?.companyName;
        const payload = {
            companyCode: selectedCompany,
            companyName,
            name: selectedName,
            instructions: selectedInstructions,
            model: selectedModel,
            temperature: selectedTemperature,
            maxResponseTokens: selectedMaxTokens,
            isLive,
            notifyAIWhilePaused,
            ...(selectedFiles.length > 0 ? {
                files: selectedFiles,
            } : {}),
            ...(selectedFunctions.length > 0 ? {
                functions: selectedFunctions,
            } : {}),
        };

        createAssistant({
            variables: payload,
        });
    };

    const actionInProgress = isUploadingResource
    || creatingAssistant
    || editingAssistant;
    const filesColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.name}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Size',
            dataKey: 'size',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{`${record.size} MB`}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'URL',
            dataKey: 'url',
            width: 350,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.path}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 130,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { path } = record;
                const isTxt = path?.endsWith('.txt');
                const isGlobal = path?.includes('global');

                return (
                    <div className={classes.actionsContainer}>
                        {!isGlobal && (
                            <>
                                <input
                                    ref={replacementUploader}
                                    className={classes.uploader}
                                    type="file"
                                    onChange={replaceFile}
                                />
                                <Button
                                    disabled={actionInProgress}
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    startIcon={<CloudUploadOutlinedIcon />}
                                    onClick={() => openFileDialog(true, record)}
                                />
                                {isTxt && (
                                    <Button
                                        disabled={actionInProgress}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        startIcon={<EditOutlinedIcon />}
                                        onClick={() => toggleFileEditor(record)}
                                    />
                                )}
                            </>
                        )}
                        <Button
                            disabled={actionInProgress}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => deleteFile(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const functionsColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Description',
            dataKey: 'description',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.description}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Function',
            dataKey: 'function',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.functionName}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 130,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            disabled={actionInProgress}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => deleteFunction(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('main-container')?.clientWidth;
    let filesTableWidth = filesColumns.reduce((a, b) => a + b.width, 0);
    let functionsTableWidth = functionsColumns.reduce((a, b) => a + b.width, 0);

    if (parentWidth > filesTableWidth) filesTableWidth = parentWidth - 50;
    if (parentWidth > functionsTableWidth) functionsTableWidth = parentWidth - 50;
    const isSaveDisabled = isUploadingResource
    || creatingAssistant
    || StringUtils.isEmpty(selectedName)
    || StringUtils.isEmpty(selectedCompany)
    || StringUtils.isEmpty(selectedInstructions);
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Assistant"
                onClose={() => toggleDialog()}
                toolbarSize="md"
            />
            <DialogContent>
                <div id="main-container" className={classes.content}>
                    <div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Name</Form.Label>
                                <Form.Control
                                    maxLength="255"
                                    className={clsx(classes.input, StringUtils.isEmpty(selectedName) ? 'invalid-field' : '')}
                                    type="text"
                                    name="selectedName"
                                    value={selectedName ?? ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Temperature</Form.Label>
                                <InputNumber
                                    value={selectedTemperature}
                                    onChange={(newValue) => onChange('selectedTemperature', newValue)}
                                    min={0}
                                    max={2}
                                    decimalScale={1}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Max Response Tokens</Form.Label>
                                <InputNumber
                                    value={selectedMaxTokens}
                                    onChange={(newValue) => onChange('selectedMaxTokens', newValue)}
                                    min={10}
                                    max={500}
                                    decimalScale={0}
                                    size="sm"
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Company</Form.Label>
                                <Select
                                    size="sm"
                                    loading={loadingEnabledCompanies}
                                    className={clsx(classes.input, StringUtils.isEmpty(selectedCompany) ? 'invalid-field' : '')}
                                    name="selectedCompany"
                                    onChange={(name, value) => onChange(name, value)}
                                    value={selectedCompany ?? ''}
                                    options={companies.map((c) => ({
                                        value: c.companyCode,
                                        label: `${c.companyCode} - ${c.companyName}`,
                                    }))}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Model</Form.Label>
                                <Select
                                    size="sm"
                                    loading={loadingModels}
                                    className={classes.input}
                                    name="selectedModel"
                                    onChange={(name, value) => onChange(name, value)}
                                    value={selectedModel ?? ''}
                                    options={models.map((m) => ({
                                        value: m,
                                        label: m,
                                    }))}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Instructions</Form.Label>
                            <Form.Control
                                className={StringUtils.isEmpty(selectedInstructions) ? 'invalid-field' : ''}
                                as="textarea"
                                size="sm"
                                value={selectedInstructions ?? ''}
                                onChange={(e) => onChange('selectedInstructions', e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Files</Form.Label>
                            <div>
                                <div>
                                    <Form.Control
                                        placeholder="Name the document (optional)"
                                        maxLength={255}
                                        className={classes.input}
                                        type="text"
                                        name="documentName"
                                        value={documentName || ''}
                                        onChange={({ target: { name, value } }) => onChange(name, value)}
                                    />
                                    <input
                                        ref={resourceUploader}
                                        className={classes.uploader}
                                        type="file"
                                        onChange={uploadResource}
                                    />
                                    <Button
                                        disabled={actionInProgress}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        onClick={() => openFileDialog()}
                                    >
                                        Add
                                    </Button>
                                </div>
                                <div>
                                    <Select
                                        placeholder="Global File"
                                        size="sm"
                                        loading={loadingFiles}
                                        className={classes.input}
                                        name="selectedFile"
                                        onChange={(name, value) => onChange(name, value)}
                                        value={selectedFile ?? ''}
                                        options={globalFiles.map((gf) => ({
                                            value: gf.aiGlobalFileId,
                                            label: gf.name,
                                        }))}
                                    />
                                    <Button
                                        disabled={StringUtils.isEmpty(selectedFile) || actionInProgress}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        onClick={addGlobalFile}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.tableContainer}>
                                {parentWidth > 0 && (
                                    <VirtualTable
                                        loading={loadingFiles}
                                        rowHeight={45}
                                        totalRecords={selectedFiles.length}
                                        data={selectedFiles}
                                        columns={filesColumns}
                                        width={filesTableWidth}
                                    />
                                )}
                            </div>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Functions</Form.Label>
                            <div>
                                <Select
                                    placeholder="Function"
                                    size="sm"
                                    loading={loadingFunctions}
                                    className={classes.input}
                                    name="selectedFunction"
                                    onChange={(name, value) => onChange(name, value)}
                                    value={selectedFunction ?? ''}
                                    options={functions.map((f) => ({
                                        value: f,
                                        label: f,
                                    }))}
                                />
                                <Form.Control
                                    placeholder="Description"
                                    className={classes.input}
                                    type="text"
                                    name="selectedFunctionDescription"
                                    value={selectedFunctionDescription || ''}
                                    onChange={({ target: { name, value } }) => onChange(name, value)}
                                />
                                <Button
                                    disabled={
                                        StringUtils.isEmpty(selectedFunction)
                                        || StringUtils.isEmpty(selectedFunctionDescription)
                                        || actionInProgress
                                    }
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    onClick={addFunction}
                                >
                                    Add
                                </Button>
                            </div>
                            <div className={classes.tableContainer}>
                                {parentWidth > 0 && (
                                    <VirtualTable
                                        loading={loadingFunctions}
                                        rowHeight={45}
                                        totalRecords={selectedFunctions.length}
                                        data={selectedFunctions}
                                        columns={functionsColumns}
                                        width={functionsTableWidth}
                                    />
                                )}
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Form.Group as={Col}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={isLive}
                                onClick={(e) => onChange('isLive', e.target.checked)}
                            />
                        )}
                        label="Live"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={notifyAIWhilePaused}
                                onClick={(e) => onChange('notifyAIWhilePaused', e.target.checked)}
                            />
                        )}
                        label="Let AI be aware of conversation while paused (collections)"
                    />
                </Form.Group>
                <Button
                    disabled={isSaveDisabled}
                    className={classes.containedSecondaryInfo}
                    size="small"
                    onClick={saveAssistant}
                >
                    {creatingAssistant || editingAssistant ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
            {isFileEditorOpen && (
                <FileEditor file={selectedLocalFile} toggleFileEditor={toggleFileEditor} />
            )}
        </Dialog>
    );
};

AssistantForm.defaultProps = {
    assistant: null,
};

AssistantForm.propTypes = {
    toggleDialog: PropTypes.func.isRequired,
    assistant: PropTypes.object,
};

export default AssistantForm;
