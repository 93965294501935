import { gql } from '@apollo/client';

export default class AIQuery {
    static PULL_ACTIVE_COMPANIES = gql`
        {
            pullActiveCompanies {
                companyCode
                companyName
            }
        }
    `;

    static PULL_COMPANIES_AI_KEY_ADDED = gql`
        {
            pullCompaniesAIKeyAdded {
                companyCode
                companyName
            }
        }
    `;

    static PULL_ASSISTANTS = gql`
        {
            pullAsistants {
                aiAssistantId
                name
            }
        }
    `;

    static GET_FILE_ON_BUCKET_CONTENT = gql`
        query getFileOnBucketContent($path: String!, $toString: Boolean!) {
            getFileOnBucketContent(path: $path, toString: $toString)
        }
    `;

    static PREVIEW_FILE_CONTENT = gql`
        query previewFileContent($companyCode: Int, $content: String!) {
            previewFileContent(companyCode: $companyCode, content: $content)
        }
    `;

    static GET_DYNAMIC_SECTIONS_ON_FILE = gql`
        {
            getDynamicSectionsOnFile
        }
    `;

    static GET_CUSTOM_FUNCTIONS = gql`
        {
            getCustomFunctions
        }
    `;

    static GET_MODELS = gql`
        query getModels($companyCode: Int!) {
            getModels(companyCode: $companyCode)
        }
    `;

    static LIST_MESSAGES_IN_THREAD = gql`
        query listMessagesInThread($companyCode: Int!, $threadId: String!, $runId: String, $limit: Int, $order: String!, $after: String) {
            listMessagesInThread(companyCode: $companyCode, threadId: $threadId, runId: $runId, limit: $limit, order: $order, after: $after) {
                messages {
                    id
                    role
                    createdOn
                    content {
                        type
                        value
                    }
                }
                hasMore
            }
        }
    `;

    static LIST_GLOBAL_FILES = gql`
        {
            listGlobalFiles {
                aiGlobalFileId
                name
                size
                url
            }
        }
    `;

    static PULL_ACTIVE_ASSISTANTS = gql`
        {
            pullActiveAssistants {
                aiAssistantId
                companyCode
                companyName
                assistantExternalId
                name
                instructions
                isLive
                notifyAIWhilePaused
                model
                temperature
                maxResponseTokens
                files {
                    aiAssistantFileId
                    aiAssistantExternalFileId
                    name
                    size
                    url
                }
                functions {
                    aiAssistantFunctionId
                    description
                    function
                }
            }
        }
    `;
}
